import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  Avatar,
  Grid,
  IconButton,
  useMediaQuery,
  FormHelperText,
} from '@mui/material';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import { Icon, Loader } from '../../../../components';
import { SHIFT_DATE_FORMAT, SHIFT_TIME_FORMAT } from '../../../../constants/datetime';
import { useCancelShift, useShiftDetail } from '../../../../services/useShifts';
import formatCurrency from '../../../../utils/formatCurrency';
import { formatAddress, getGoogleMapLink } from '../../../../utils/formatAddress';
import { SHIFTS } from '../../../../constants/routes';
import DepartmentIcon from '../../../../pages/CreateShift/components/DepartmentIcon';
import styles from './ShiftDetailModal.module.scss';
import { useSnackbar } from 'notistack';

export interface ShiftDetailModalProps {
  open: boolean;
  onClose: () => void;
  jobId: string;
  usNursingJobId: string;
  shiftId?: string;
  onOpenProfessionalModal: (
    jobId: string,
    usNursingJobId: string,
    shiftId: string,
    supplyId: string,
  ) => void;
}

const ShiftDetailModal = (props: ShiftDetailModalProps) => {
  const { open, onClose, jobId, usNursingJobId, shiftId, onOpenProfessionalModal } = props;
  const organizationId = localStorage.getItem('_o') || '';
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, data: shiftDetail, error } = useShiftDetail(organizationId, jobId, shiftId);
  const [mutate] = useCancelShift({
    onSuccess() {
      console.log('on success');
      onClose();
      enqueueSnackbar(<div>Success, Your shift has been cancelled!,</div>, {
        variant: 'success',
      });
    },
    onFailure({ error }) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const { primary_nursing_department, facility, start_time, end_time, shift_cost, supply_profile } =
    shiftDetail?.data || {};
  const supplyName = `${supply_profile?.first_name ?? ''} ${supply_profile?.last_name ?? ''}`;

  const handleViewArrivalInformation = (event: React.MouseEvent) => {
    event.preventDefault();
    history.push(`${SHIFTS}/${shiftId}?jobId=${jobId}&organizationId=${organizationId}`);
    onClose();
  };

  const handleProfessionalClick = () => {
    if (shiftId && supply_profile) {
      onOpenProfessionalModal(jobId, usNursingJobId, shiftId, supply_profile?.supply_id);
    }
  };

  const onCancelShift = useCallback(() => {
    console.log('On cancel', jobId, shiftId);
    if (jobId && shiftId) {
      mutate({ shift_id: shiftId, job_id: jobId });
    }
  }, [mutate, jobId, shiftId]);

  return (
    <Dialog
      classes={{
        paper: styles.container,
      }}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div className={styles.header}>
        <h4 className={styles.title}>Shift Detail</h4>
        <IconButton onClick={onClose} className={styles.icon} size="large">
          <Icon name="close" size={10} />
        </IconButton>
      </div>
      {isLoading && (
        <div className={styles.centerBlock}>
          <Loader />
        </div>
      )}
      {!isLoading && error && (
        <div className={styles.centerBlock}>
          <FormHelperText error>
            {error.message ??
              'Oops! An error occurred while processing your request. Please try again.'}
          </FormHelperText>
        </div>
      )}
      {!isLoading && !error && !!shiftDetail && (
        <DialogContent>
          <Typography
            sx={{ fontWeight: '500', fontSize: '17px', lineHeight: '22px' }}
            variant="subtitle1"
            color="primary"
            gutterBottom
          >
            INFORMATION
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} className={styles.section}>
              <div className={styles.sectionSubtitle}>DEPARTMENT</div>
              {primary_nursing_department && (
                <div className={styles.department}>
                  <div style={{ width: '40px', height: '40px' }}>
                    <DepartmentIcon department={primary_nursing_department} />
                  </div>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {primary_nursing_department?.nursing_department}
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={6} className={styles.section}>
              <div className={styles.sectionSubtitle}>Registered nurse</div>
              <div className={styles.registeredNurse} onClick={handleProfessionalClick}>
                <Avatar alt={supplyName} src={supply_profile?.profile_image_url}></Avatar>
                <div className={styles.registeredNurseDetail}>
                  <div className={styles.nurseName}>{supplyName}</div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} className={styles.section}>
              <div className={styles.sectionSubtitle}>LOCATION</div>
              <Typography variant="body1">{facility?.facility_name}</Typography>
              <div className={styles.locationAddress}>
                <Icon name="location" size={16} />
                <a target="_blank" rel="noopener noreferrer" href={getGoogleMapLink(facility)}>
                  {formatAddress(facility)}
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className={styles.section}>
              <div className={styles.sectionSubtitle}>TIME AND DURATION</div>
              <div className={styles.shiftDate}>{dayjs(start_time).format(SHIFT_DATE_FORMAT)}</div>
              <div className={styles.shiftTime}>
                <Icon name="clock" size={16} />
                <span>
                  {dayjs(start_time).format(SHIFT_TIME_FORMAT)} -{' '}
                  {dayjs(end_time).format(SHIFT_TIME_FORMAT)}
                </span>
              </div>
            </Grid>
          </Grid>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            SERVICE FEE
          </Typography>
          {shift_cost?.total?.shift_duration && (
            <div className={styles.serviceFeeRow}>
              <div>Healthcare Professional Services</div>
              <div>{formatCurrency(shift_cost?.total?.shift_duration?.pay?.string)}</div>
            </div>
          )}
          {shift_cost?.service_fees?.map((fee) => (
            <div
              key={fee.shift_service_fee_type?.shift_service_fee_type_id}
              className={styles.serviceFeeRow}
            >
              <div>{fee.shift_service_fee_type?.shift_service_fee_type}</div>
              <div>{formatCurrency(fee?.string_format)}</div>
            </div>
          ))}
          <div className={styles.divider} />
          <div className={styles.serviceFeeRow}>
            <div className={styles.total}>Total</div>
            <div className={styles.totalValue}>
              {formatCurrency(shift_cost?.total?.grand_total?.string)}
            </div>
          </div>
          <div className={styles.note}>
            Total Fees will be charged after the completion of Healthcare Professional Services and
            expiration of the Timesheet review period. Total Fees are subject to change depending on
            the duration of Healthcare Professional Services rendered.
          </div>
          <div className={styles.actions}>
            <a onClick={handleViewArrivalInformation} className={styles.LinkButton} href="/">
              View arrival information
            </a>
            <Button onClick={onCancelShift} className={styles.btnCancel} variant="text">
              CANCEL SHIFT
            </Button>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ShiftDetailModal;
