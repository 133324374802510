import React from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import { TableRow as MuiTableRow, TableCell, Avatar, Typography, Box } from '@mui/material';
import { Column } from '../ShiftsTable';
import { MedicalProfessionalCell } from '../MedicalProfessionalCell';
import { JobShift, NursingDepartment, ShiftStatusState } from '../../../../../types/schema';
import { Icon } from '../../../../../components';
import { SHIFT_DATE_FORMAT, SHIFT_TIME_FORMAT } from '../../../../../constants/datetime';
import { getShiftStateStatusDetail } from '../../../../../utils';
import DepartmentIcon from '../../../../../pages/CreateShift/components/DepartmentIcon';
import styles from './TableRow.module.scss';

export interface TableRowProps {
  shift: JobShift;
  columns: Column[];
  onItemClick: (shift: JobShift) => void;
  isCompleted?: boolean;
  onOpenProfessionalModal: (
    jobId: string,
    usNursingJobId: string,
    shiftId: string,
    supplyId: string,
  ) => void;
  onOpenReviewTimesheetModal: (shift: JobShift) => void;
}

export const TableRow = (props: TableRowProps) => {
  const {
    shift,
    columns,
    onItemClick,
    onOpenProfessionalModal,
    onOpenReviewTimesheetModal,
    isCompleted = false,
  } = props;

  const handleClick = () => {
    onItemClick(shift);
  };

  const handleProfessionalCellClick = () => {
    if (shift.jobs_shift_info.jobs_shift.shift_assignment) {
      onOpenProfessionalModal(
        shift.job_id,
        shift.us_nursing_job_id,
        shift.jobs_shift_info.jobs_shift.shift_id,
        shift.jobs_shift_info.jobs_shift.shift_assignment.supply_id,
      );
    }
  };

  const handleTimesheetCellClick = () => {
    onOpenReviewTimesheetModal(shift);
  };

  return (
    <MuiTableRow
      onClick={handleClick}
      className={styles.container}
      hover
      role="checkbox"
      tabIndex={-1}
      key={shift.job_id}
    >
      <ShiftCell
        id={columns[0].id}
        title={shift.job_title}
        date={dayjs(shift.jobs_shift_info.jobs_shift?.start_time).format(SHIFT_DATE_FORMAT)}
        time={`${dayjs(shift.jobs_shift_info.jobs_shift?.start_time).format(
          SHIFT_TIME_FORMAT,
        )} - ${dayjs(shift.jobs_shift_info.jobs_shift?.end_time).format(SHIFT_TIME_FORMAT)}`}
      />
      {isCompleted ? (
        <CompleteShiftLocationCell
          id={columns[1].id}
          department={shift.primary_nursing_department}
          location={shift.facility.facility_name}
        />
      ) : (
        <LocationCell id={columns[1].id} location={shift.facility.facility_name} />
      )}
      {isCompleted ? (
        <MedicalProfessionalCell
          onClick={handleProfessionalCellClick}
          shift={shift}
          id={columns[2].id}
        />
      ) : (
        <DepartmentCell id={columns[2].id} department={shift.primary_nursing_department} />
      )}
      {isCompleted ? (
        <TimesheetCell id={columns[3].id} shift={shift} onClick={handleTimesheetCellClick} />
      ) : (
        <MatchedCell id={columns[3].id} onClick={handleProfessionalCellClick} shift={shift} />
      )}
      <StatusCell
        id={columns[4].id}
        status={
          shift.jobs_shift_info.jobs_shift?.shift_states[
            shift.jobs_shift_info.jobs_shift?.shift_states.length - 1
          ]
        }
      />
    </MuiTableRow>
  );
};

export interface ShiftCellProps {
  id: string;
  date?: string;
  time?: string;
  title?: string;
}

export const ShiftCell = ({ id, date, time, title }: ShiftCellProps) => {
  return (
    <TableCell width={'20%'} key={id} align="left">
      <p className={styles.shiftTitle}>{title}</p>
      <span className={styles.shiftDate}>{date}</span>
      <div className={styles.shiftTimeWrap}>
        <Icon name="clock" size={14} className={styles.shiftTimeIcon} />
        <span className={styles.shiftTime}>{time}</span>
      </div>
    </TableCell>
  );
};

export interface LocationCellProps {
  id: string;
  location?: string;
}

export const LocationCell = ({ id, location }: LocationCellProps) => {
  return (
    <TableCell width={'20%'} className={styles.location} key={id} align="left">
      {location}
    </TableCell>
  );
};

export interface CompleteShiftLocationCellProps {
  id: string;
  location?: string;
  department?: NursingDepartment;
}

export const CompleteShiftLocationCell = ({
  id,
  location,
  department,
}: CompleteShiftLocationCellProps) => {
  return (
    <TableCell width={'20%'} className={styles.location} key={id} align="left">
      <div>{location}</div>
      {department && (
        <div className={styles.department}>
          <div style={{ width: '40px', height: '40px' }}>
            <DepartmentIcon department={department} />
          </div>
          {department?.nursing_department}
        </div>
      )}
    </TableCell>
  );
};

export interface DepartmentCellProps {
  id: string;
  department?: NursingDepartment;
}

export const DepartmentCell = ({ id, department }: DepartmentCellProps) => {
  return (
    <TableCell width={'20%'} key={id} align="left">
      {department && (
        <div className={styles.department}>
          <div
            style={{
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <DepartmentIcon department={department} />
          </div>
          {department?.nursing_department}
        </div>
      )}
    </TableCell>
  );
};

export interface MatchedCellProps {
  id: string;
  shift: JobShift;
  onClick: (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => void;
}

export const MatchedCell = ({ shift, id, onClick }: MatchedCellProps) => {
  const {
    jobs_shift_info: { shift_assignment, supply_profile },
  } = shift;
  const fullName = `${supply_profile?.first_name ?? ''} ${supply_profile?.last_name ?? ''}`;
  return (
    <TableCell width={'20%'} key={id} align="left">
      {shift_assignment?.supply_id ? (
        <div onClick={onClick} className={styles.matchedWrap}>
          <Avatar
            className={styles.matchedAvatar}
            alt={fullName}
            src={supply_profile?.profile_image_url}
          ></Avatar>
          {fullName}
        </div>
      ) : (
        <div style={{ marginLeft: '1rem' }}>--</div>
      )}
    </TableCell>
  );
};

export interface StatusCellProps {
  id: string;
  status: ShiftStatusState;
}

export const StatusCell = ({ id, status }: StatusCellProps) => {
  const { name, color } = getShiftStateStatusDetail(status);
  return (
    <TableCell width={'20%'} key={id} align="left">
      <div className={cn(styles.statusWrap)}>
        <div className={styles.status} style={{ color }}>
          {name}
        </div>
        <Icon className={styles.arrow} size={8} name="arrowRight" />
      </div>
    </TableCell>
  );
};

export interface TimesheetCellProps {
  id: string;
  shift: JobShift;
  onClick: (shift: JobShift) => void;
}

export const TimesheetCell = ({ id, shift, onClick }: TimesheetCellProps) => {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClick(shift);
  };
  return (
    <TableCell key={id} width={'20%'} onClick={handleClick}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Icon name="timesheet" size={14} className={styles.shiftTimeIcon} />
        <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
          {dayjs(shift.jobs_shift_info.jobs_shift.start_time).format('HH:mm')} -{' '}
          {dayjs(shift.jobs_shift_info.jobs_shift.end_time).format('HH:mm')}
        </Typography>
      </Box>
    </TableCell>
  );
};
