import dayjs from 'dayjs';
import { BACKEND_DATE_TIME_FORMAT, DAYS_OF_THE_WEEK } from '../../../constants/datetime';

export const START_DATE_EXTRA = 48; // extra 48 hours
export const END_DATE_EXTRA = 7; // extra 7 days
export const MAX_SHIFT_TIME_RANGE = 12; // 12 hours

export const getNursingDepartments = (
  primaryDepartment: string,
  otherDepartments?: string[],
  experience?: number,
) => {
  const formatedOtherDepartments = (otherDepartments ?? []).map((department) => ({
    nursing_department_id: department,
    work_experience_minimum_length: experience,
    is_primary_department: false,
  }));

  const departments = [
    ...formatedOtherDepartments,
    ...[
      {
        nursing_department_id: primaryDepartment,
        is_primary_department: true,
        work_experience_minimum_length: experience,
      },
    ],
  ];

  return departments;
};

/**
 * loop through startDate to endDate, if this day is a day in dayStr then calculate the times.
 * start_time = startDate + time part of startTime
 * end_time = startDate + time part of endTime
 * if endTime is before startTime, it's a overnight shift so
 * end_time = startDate + 1 day + time part of endTime
 * Result:
 * [
 *    {
 *      start_time: '',
 *      end_time: '',
 *    }
 * ]
 *
 * @param startDate shift start date
 * @param endDate shifr end date
 * @param startTime shift start time
 * @param endTime shift end time
 * @param days days of the week, list of numbers index DAYS_OF_THE_WEEK
 */
export const getShiftTimeSlots = (
  startDate: string,
  endDate: string,
  startTime: string,
  endTime: string,
  days: number[],
) => {
  console.log('get shift time slots', startDate, endDate, startTime, endTime, days);
  const isOvernightShift = dayjs(endTime, 'hh:mm').isBefore(dayjs(startTime, 'hh:mm'));
  const shifts = [];
  let mStartDate = dayjs(startDate);
  while (mStartDate.isSameOrBefore(dayjs(endDate))) {
    if (days.includes(mStartDate.day())) {
      const shiftStartTime = dayjs(mStartDate).set({
        hour: dayjs(startTime, 'hh:mm').get('hour'),
        minute: dayjs(startTime, 'hh:mm').get('minute'),
      });

      let shiftEndTime = dayjs(mStartDate).set({
        hour: dayjs(endTime, 'hh:mm').get('hour'),
        minute: dayjs(endTime, 'hh:mm').get('minute'),
        second: 0,
        millisecond: 0,
      });

      if (isOvernightShift) {
        shiftEndTime = shiftEndTime.add(1, 'd');
      }

      shifts.push({
        start_time: shiftStartTime.format(BACKEND_DATE_TIME_FORMAT),
        end_time: shiftEndTime.format(BACKEND_DATE_TIME_FORMAT),
      });
    }

    mStartDate = mStartDate.add(1, 'd');
  }
  console.log('Shifts are', shifts);
  return shifts;
};

export const getDaysOfWeekLabel = (days: number[]): string => {
  return DAYS_OF_THE_WEEK.filter((day) => days.includes(day.index))
    .map((day) => day.label)
    .join(', ');
};

/**
 * start from tomorrow and plus START_DATE_EXTRA
 * @param date start_date
 */
export const getMinStartDate = (date: Date = new Date()) => {
  return dayjs(date).add(1, 'd').startOf('day').add(START_DATE_EXTRA, 'hours').toDate();
};

/**
 * start_date plus END_DATE_EXTRA
 * @param startDate
 */
export const getMinEndDate = (startDate: Date = new Date()) => {
  return dayjs(startDate).add(END_DATE_EXTRA, 'd').toDate();
};

/**
 * if start_time is before noon, disable all end times aren't between start_time and start_time plus MAX_SHIFT_TIME_RANGE
 * if start_time isn't before noon, the end_time can be go to next day.
 * @param startDate
 */
export const filterEndTime = (startTime: Date | undefined) => (time: string) => {
  if (!startTime) return true;
  const mCurrentTime = dayjs(time);
  const mStartTime = dayjs(startTime);
  const noon = dayjs().set({ hour: 12, minute: 0, second: 0 });
  if (mStartTime.isBefore(noon, 'h')) {
    return mCurrentTime.isBetween(mStartTime, dayjs(startTime).add(MAX_SHIFT_TIME_RANGE, 'hours'));
  }
  return !mCurrentTime.isBetween(
    dayjs(startTime).subtract(1, 'd').add(MAX_SHIFT_TIME_RANGE, 'hours'),
    mStartTime,
  );
};
