import React from 'react';
// import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { Button } from '@mui/material';
import { Form, FormActions, Input } from '../../../../components';

const ProfileCompanyForm = () => {
  // const handleCompanyRequest = async (values, successCallback, verify) => {
  //   const {
  //     companyInfo: { address: { administrative_area_level_1: state, postal_code } = {} },
  //     showError,
  //     createOrganization,
  //   } = this.props;

  //   if (this.currentForm.verified) {
  //     showError(
  //       'Company is already created for this account! You can change details in your profile settings later.',
  //     );
  //     return successCallback();
  //   }

  //   return await createOrganization(values).then(() => {
  //     if (skipToServiceLater(state, postal_code)) {
  //       successCallback();
  //     }
  //     verify();
  //     successCallback();
  //   });
  // };

  // const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      name: '',
      location: '',
      phone: '',
      npiNumber: '',
      address: '',
    },
    validate: (values) => {
      const errors = {} as object;

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      // setSubmitting(true);
      // try {
      //   setSubmitting(false);
      //   enqueueSnackbar('Information saved successfully', {
      //     variant: 'success',
      //   });
      // } catch (e: any) {
      //   setSubmitting(false);
      //   enqueueSnackbar(e.message ? e.message : e.code ? e.code : e.status, {
      //     variant: 'error',
      //   });
      // }
    },
  });

  const { handleSubmit, isSubmitting } = formik;

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        required
        name="name"
        label="Company"
        // validate={required}
      />
      <Input
        required
        name="location"
        label="Location"
        // validate={required}
      />
      <Input
        required
        name="phone"
        label="Phone"
        // validate={validator.phone}
      />
      <Input
        required
        name="npi_number"
        label="NPI Number"
        placeholder="e.g. 1234"
        // validate={required}
      />
      <Input
        required
        label="City"
        name="address.locality"
        placeholder="Text"
        // validate={required}
      />

      <Input
        required
        label="State"
        name="address.administrative_area_level_1"
        placeholder="e.g. Massachusetts"
        // validate={required}
      />

      <Input
        required
        label="Zip Code"
        name="address.postal_code"
        placeholder="e.g. 02114"
        // validate={required}
      />

      <FormActions>
        <Button type="submit" disabled={isSubmitting} variant="contained">
          Save
        </Button>
      </FormActions>
    </Form>
  );
};

export default ProfileCompanyForm;
