import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { Button, Typography } from '@mui/material';
import { Input, Form, FormActions, Alert } from '../../../../../components';
import { getErrorMessage, validateEmail } from '../../../../../utils';
import { LOGIN } from '../../../../../constants/routes';
import { requestSignupToken } from '../../../../../services/authentication.service';
import styles from './SignUpForm.module.scss';

const SignUpForm = () => {
  const history = useHistory();
  const [error, setError] = useState('');

  const formik = useFormik({
    initialValues: {
      email_address: '',
      first_name: '',
      last_name: '',
      organization_name: '',
    },
    validate: (values) => {
      const errors = {} as {
        email_address: string;
        first_name: string;
        last_name: string;
        organization_name: string;
      };
      if (!values.email_address) {
        errors.email_address = 'Required';
      } else if (!validateEmail(values.email_address)) {
        errors.email_address = 'Invalid email address';
      }
      if (!values.first_name) {
        errors.first_name = 'Required';
      }
      if (!values.last_name) {
        errors.last_name = 'Required';
      }
      if (!values.organization_name) {
        errors.organization_name = 'Required';
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setError('');
      try {
        await requestSignupToken(
          values.email_address,
          values.first_name,
          values.last_name,
          values.organization_name,
        );
        setSubmitting(false);
        history.push(LOGIN);
      } catch (error) {
        setSubmitting(false);
        setError(getErrorMessage(error));
      }
    },
  });

  const { handleSubmit, values, handleChange, touched, errors, isSubmitting } = formik;

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        id="email_address"
        name="email_address"
        type="email"
        label="Email"
        value={values.email_address}
        onChange={handleChange}
        error={touched.email_address && Boolean(errors.email_address)}
        helperText={touched.email_address && errors.email_address}
      />
      <Input
        id="first_name"
        name="first_name"
        type="text"
        label="First name"
        value={values.first_name}
        onChange={handleChange}
        error={touched.first_name && Boolean(errors.first_name)}
        helperText={touched.first_name && errors.first_name}
      />
      <Input
        id="last_name"
        name="last_name"
        type="text"
        label="Last name"
        value={values.last_name}
        onChange={handleChange}
        error={touched.last_name && Boolean(errors.last_name)}
        helperText={touched.last_name && errors.last_name}
      />

      <Input
        id="organization_name"
        name="organization_name"
        type="text"
        label="Organization name"
        value={values.organization_name}
        onChange={handleChange}
        error={touched.organization_name && Boolean(errors.organization_name)}
        helperText={touched.organization_name && errors.organization_name}
      />

      <FormActions>
        <Button type="submit" variant="contained" disabled={isSubmitting}>
          Sign up
        </Button>
      </FormActions>
      <div className={styles.formActionsVariant}>
        <Typography variant="body2">Already have an account?</Typography>
        <Button variant="text" onClick={() => history.push(LOGIN)} size="small">
          Sign in
        </Button>
      </div>
      {error && <Alert type="error">{error}</Alert>}
    </Form>
  );
};

export default SignUpForm;
