import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { orderBy } from 'lodash-es';
import {
  Dialog,
  DialogContent,
  Avatar,
  Grid,
  IconButton,
  useMediaQuery,
  FormHelperText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Icon, Loader } from '../../../../components';
import { LabelStatus } from '../../../../components/ShiftStatus';
import {
  useConfirmMatchedProfessional,
  useMatchedProfessional,
} from '../../../../services/useShifts';
import { CertificateType } from '../../../../types/schema';
import styles from './MatchedProfessionalModal.module.scss';

const CertificationsMapping: { [key: string]: string } = {
  usbls_certificates: 'Basic Life Support (BLS)',
  usacls_certificates: 'Advanced Cardiovascular Life Support (ACLS)',
  uspals_certificates: 'Pediatric Advanced Life Support (PALS)',
  usnrp_certificates: 'Neonatal Resuscitation Program (NPR)',
  usawhonn_certificates: 'Advance Fetal Monitoring Certifications (AWHONN)',
  usenpc_certificates: 'Emergency Nursing Pediatric Course (ENPC)',
  ustncc_certificates: 'Trauma Nursing Core Course (TNCC)',
  usnihss_certificates: 'NIH Stroke Scale certification',
};

export interface MatchedProfessionalModalProps {
  open: boolean;
  onClose: () => void;
  jobId: string;
  usNursingJobId: string;
  shiftId: string;
  supplyId: string;
}

const getCertificateRenewalDate = (certs: CertificateType[]) => {
  const cert = orderBy(certs, 'renewal_date').find(
    (element) => element.current_credential_status === 'valid_credential',
  );
  return cert;
};

const displayCertificateRenewalDate = (cert?: CertificateType) => {
  return cert ? dayjs(cert?.renewal_date).format('MMM YYYY') : '';
};

export const MatchedProfessionalModal = (props: MatchedProfessionalModalProps) => {
  const { open, onClose, jobId, usNursingJobId, shiftId, supplyId } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    data: { data: professionalInfo } = {},
    isLoading,
    error,
  } = useMatchedProfessional(jobId, usNursingJobId, shiftId, supplyId);
  const {
    demographic_info,
    work_experiences = [],
    educations = [],
    certificates = {},
    profile_viewed,
  } = professionalInfo ?? {};
  const fullName = `${demographic_info?.first_name ?? ''} ${demographic_info?.middle_name ?? ''} ${
    demographic_info?.last_name ?? ''
  }`;

  const [mutate] = useConfirmMatchedProfessional({
    onSuccess() {
      console.log('on success');
    },
    onFailure({ error }) {
      console.error('Error while confirmation of matched professional', error);
    },
  });

  useEffect(() => {
    if (profile_viewed === false) {
      mutate({ shift_id: shiftId, job_id: jobId, supply_id: supplyId });
    }
  }, [profile_viewed, mutate, shiftId, jobId, supplyId]);

  return (
    <Dialog
      classes={{
        paper: styles.container,
      }}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div className={styles.header}>
        <h4 className={styles.title}>Matched Professional</h4>
        {profile_viewed === false ? (
          <LabelStatus name={'Need Review'} value={'NEEDS_REVIEW'} />
        ) : null}
        <IconButton onClick={onClose} className={styles.icon} size="large">
          <Icon name="close" size={10} />
        </IconButton>
      </div>
      {isLoading && (
        <div className={styles.centerBlock}>
          <Loader />
        </div>
      )}
      {!isLoading && error && (
        <div className={styles.centerBlock}>
          <FormHelperText error>
            {error.message ??
              'Oops! An error occurred while processing your request. Please try again.'}
          </FormHelperText>
        </div>
      )}
      {!isLoading && !error && (
        <DialogContent className={styles.content}>
          <div className={styles.basicInfoWrap}>
            <Avatar alt={fullName} className={styles.avatar} src="">
              {demographic_info?.first_name?.charAt(0) ?? ''}
            </Avatar>
            <div className={styles.name}>{fullName}</div>
            <div className={styles.jobTitle}></div>
          </div>
          <Grid className={styles.section} container spacing={2}>
            <Grid className={styles.sectionTitle} item xs={12}>
              WORK EXPERIENCE
            </Grid>
            {work_experiences?.map((workExp) => (
              <Grid key={workExp.account_id} className={styles.workExpWrap} item xs={12} sm={6}>
                <div className={styles.workExpThumbnailWrap}>
                  <img
                    alt={workExp?.nursing_department?.nursing_department}
                    className={styles.workExpThumbnail}
                    src="/images/placeholder.png"
                  />
                </div>
                <div>
                  <div className={styles.primaryTitle}>
                    {workExp?.nursing_department?.nursing_department}
                  </div>
                  <div className={styles.secondaryTitle}>{workExp?.organization_name}</div>
                  <div className={styles.additionalInfo}>
                    {dayjs(workExp.start_date).format('MMM YYYY')} -{' '}
                    {dayjs(workExp.end_date).format('MMM YYYY')} &#176;
                    {` ${workExp.location?.locality ?? ''}, ${
                      workExp.location?.administrative_area_level_1 ?? ''
                    }`}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
          <Grid className={styles.section} container spacing={2}>
            <Grid className={styles.sectionTitle} item xs={12}>
              EDUCATION
            </Grid>
            {educations.map((education) => (
              <Grid key={education.credential_id} item>
                <div className={styles.primaryTitle}>{education.school_name}</div>
                <div className={styles.secondaryTitle}>
                  {education?.nursing_education_type?.nursing_education_type}
                </div>
              </Grid>
            ))}
          </Grid>

          <Grid className={styles.section} container spacing={2}>
            <Grid className={styles.sectionTitle} item xs={12}>
              CERTIFICATIONS AND VACCINATIONS
            </Grid>
            {(Object.entries(certificates) as [string, CertificateType[]][]).map(
              ([key, values]) => (
                <Grid item xs={12} sm={6} key={key}>
                  <div className={styles.attachmentRow}>
                    <div className={styles.primaryTitle}>{CertificationsMapping[key]}</div>
                    <a href="/">
                      <Icon className={styles.attachment} size={16} name="imageAttachment" />
                    </a>
                  </div>
                  <div className={styles.additionalInfo}>
                    {displayCertificateRenewalDate(getCertificateRenewalDate(values))}
                  </div>
                </Grid>
              ),
            )}
          </Grid>

          <Grid className={styles.section} container spacing={2}>
            <Grid className={styles.sectionTitle} item xs={12}>
              ADDITIONAL SCREENINGS
            </Grid>
            <Grid item>
              <div className={styles.attachmentRow}>
                <div className={styles.primaryTitle}>Background Screening</div>
                <a href="/">
                  <Icon className={styles.attachment} size={16} name="linkAttachment" />
                </a>
              </div>
              <div className={styles.attachmentRow}>
                <div className={styles.primaryTitle}>Degree</div>
                <a href="/">
                  <Icon className={styles.attachment} size={16} name="linkAttachment" />
                </a>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};
