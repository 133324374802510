import React, { useState, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import { Loader, Checkbox } from '../../../components';
import Header from '../../../layout/Header';
import Footer from '../../../layout/Footer';
// import { showToast } from '@/store/actions';
import {
  useIpAddress,
  useAcceptAgreements,
  useDemandAgreements,
} from '../../../services/useAgreements';
import { formatDemandAgreements, getDemandAgreementIds } from '../../../utils/agreements';
import { PERSISTENCE_KEYS } from '../../../constants';
import { AGREEMENTS, COMPANY, LOGIN, SHIFTS } from '../../../constants/routes';
import styles from './Agreements.module.scss';

const AgreementsPage = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [selected, setSelected] = useState<string[]>([]);

  const accountId = localStorage.getItem('_i') || '';

  const { data: { data: agreements } = { data: [] }, isLoading: isDemandAgreementsLoading } =
    useDemandAgreements();
  const { data: { ip } = {}, isLoading: ipLoading } = useIpAddress();

  const handleAcceptSuccess = () => {
    enqueueSnackbar('post success', {
      variant: 'success',
    });
    console.log('Accept success');
    window.localStorage.setItem(PERSISTENCE_KEYS.ACCEPT_TERM_AND_CONDITION, 'true');
    const isFirstTimeLoggin = window.localStorage.getItem(PERSISTENCE_KEYS.FIRST_TIME_LOGIN);
    if (isFirstTimeLoggin) {
      history.push(COMPANY);
      window.localStorage.removeItem(PERSISTENCE_KEYS.FIRST_TIME_LOGIN);
    } else {
      history.push(SHIFTS);
    }
  };

  const [mutate, { status }] = useAcceptAgreements({
    onSuccess: handleAcceptSuccess,
    onFailure({ error }) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const formatedAgreements = useMemo(() => formatDemandAgreements(agreements ?? []), [agreements]);
  const agreementIds = useMemo(
    () => getDemandAgreementIds(formatedAgreements),
    [formatedAgreements],
  );

  const handleAgree = () => {
    if (selected.length >= agreementIds.length) {
      const params = {
        document_ids: agreementIds.flat(),
        accepted_by_id: accountId,
        accepted_at_ip_address: ip,
      };
      mutate(params);
    } else {
      enqueueSnackbar(
        'In order to continue to register and sign up with Para you must agree to all',
        {
          variant: 'warning',
        },
      );
    }
  };

  const handleCheck = (documentId: string, checked: boolean) => {
    if (documentId === 'all') {
      // select or de-select All
      setSelected(checked ? [...agreementIds, 'all'] : []);
    } else {
      setSelected((current) => {
        if (!checked) {
          current = current.filter((doc) => doc !== 'all');
        }
        return current.includes(documentId)
          ? current.filter((doc) => doc !== documentId)
          : [...current, documentId];
      });
    }
  };

  console.log('formatedAgreements', formatedAgreements, isDemandAgreementsLoading, ipLoading);
  if (isDemandAgreementsLoading || ipLoading) return <Loader />;

  return (
    <div>
      <div className={styles.container}>
        <Header className={styles.header} />
        <div className={styles.centerBlock}>
          <h2 className={styles.welcomeText}>Welcome!</h2>
          <h2 className={styles.tacText}>Agree to the Terms and Conditions.</h2>
          {formatedAgreements.map((doc) => (
            <Checkbox
              name="agreement"
              key={doc.label}
              defaultChecked={selected.includes(doc.documentId)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleCheck(doc.documentId, event.target.checked)
              }
              label={
                <span>
                  I have read and agree to
                  <span className={styles.lineBreak} />
                  {doc.url ? (
                    <Link to={`${AGREEMENTS}/${doc.documentId}`}>{doc.label}</Link>
                  ) : (
                    doc.label
                  )}
                  .
                </span>
              }
            />
          ))}
        </div>
      </div>
      <Footer>
        <div className={styles.footer}>
          <Button sx={{ mr: '10px' }} variant="outlined" onClick={() => history.push(LOGIN)}>
            Back
          </Button>
          <Button disabled={status === 'running'} onClick={handleAgree} variant="contained">
            I agree
          </Button>
        </div>
      </Footer>
    </div>
  );
};

export default AgreementsPage;
