import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { round } from 'lodash-es';
import { Loader } from '../../../../components';
import { useDepartments } from '../../../../services/useShifts';
import { useFacilities } from '../../../../services/useOrganization';
import { getDaysOfWeekLabel } from '../utils';
import DepartmentIcon from '../DepartmentIcon';

type ScreeningType = {
  fasic?: boolean;
  panel?: boolean;
};

export interface PreviewFormProps {
  departmentId?: string;
  facilityId: string;
  jobTitle?: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  days?: number[];
  screening?: ScreeningType;
  payrate: string;
}

const getDaysBetween = (start: Date, end: Date) => {
  let now = dayjs(start);
  const dates = [];
  while (now.isBefore(dayjs(end)) || now.isSame(dayjs(end))) {
    dates.push(now.format('MM/DD/YYYY'));
    now = now.add(1, 'days');
  }
  return {
    days: dates.map((d) => dayjs(d).format('d')),
  };
};

const calcCostAndFees = (
  date: {
    start_time: string;
    end_time: string;
    start_date: Date;
    end_date: Date;
    days: number[];
  },
  payrate: string,
  screening?: ScreeningType,
) => {
  const { start_time, end_time, start_date, end_date, days } = date;
  const allDays = getDaysBetween(start_date, end_date).days.filter((day) =>
    days.some((d: number) => d + '' === day),
  );

  const screeningCount = (() => {
    let cost = 0;
    if (screening && screening.fasic) {
      cost += 28;
    }

    if (screening && screening.panel) {
      cost += 35;
    }
    return cost;
  })();
  const totalHours = dayjs(end_time, 'hh:mm').diff(dayjs(start_time, 'hh:mm'), 'hour', true);
  const serviceRate = totalHours * allDays.length;
  const baseServiceCost = serviceRate * (parseFloat(payrate) || 1);
  return {
    base: round(baseServiceCost, 2),
    companyFee: round(baseServiceCost * 0.2, 2),
    screening: screeningCount,
    total: round(baseServiceCost + baseServiceCost * 0.2 + screeningCount, 2),
    dollarPerHour: serviceRate
      ? round((baseServiceCost + baseServiceCost * 0.2 + screeningCount) / serviceRate, 2)
      : 0,
  };
};

export const PreviewForm = ({
  departmentId,
  facilityId,
  jobTitle,
  startDate,
  endDate,
  startTime,
  endTime,
  days = [],
  screening,
  payrate,
}: PreviewFormProps) => {
  const organizationId = localStorage.getItem('_o') || '';
  const { data: { data: departments } = { data: [] }, isLoading: isLoadingDepartments } =
    useDepartments();

  const { data: { data: facilities } = { data: [] }, isLoading: isLoadingFacilities } =
    useFacilities(organizationId);

  const department = useMemo(
    () => departments?.find((item) => item.nursing_department_id === departmentId),
    [departments, departmentId],
  );
  const facility = useMemo(
    () => facilities?.find((item) => item.facility_id === facilityId),
    [facilities, facilityId],
  );

  const calculationValues = useMemo(
    () =>
      calcCostAndFees(
        {
          start_time: startTime,
          end_time: endTime,
          start_date: new Date(startDate),
          end_date: new Date(endDate),
          days,
        },
        payrate,
        screening,
      ),

    [startTime, endTime, startDate, endDate, days, screening, payrate],
  );

  if (isLoadingDepartments || isLoadingFacilities) return <Loader />;

  const renderScreeningFee = () => {
    return (
      screening &&
      (screening.fasic || screening.panel) && (
        <div>
          {screening.fasic && (
            <div className="flex flex-middle">
              <div className="pr-5 previewPrice">Background Screening (FACIS 3):</div>
              <div>$28.00</div>
            </div>
          )}
          {screening.panel && (
            <div className="flex flex-middle">
              <div className="pr-5 previewPrice">Drug Screening (10-Panel):</div>
              <div>$35.00</div>
            </div>
          )}
        </div>
      )
    );
  };

  return (
    <div className="fade-in">
      <div className="flex flex-center pb-10 bb">
        {department && (
          <h2 className="flex flex-middle flex-center mb-5 full-width">
            <div style={{ width: '40px', height: '40px' }}>
              <DepartmentIcon department={department} />
            </div>
            <div className="color-dark-gray">{department.nursing_department}</div>
          </h2>
        )}
        <h3 className="flex flex-middle mb-10">{jobTitle}</h3>
      </div>

      <div className="pt-15 pb-15">
        <div className="mb-10">
          <div className="color-primary mb-5">WHEN:</div>
          <div>
            <ul>
              <li>
                {`${dayjs(startDate).format('MM/DD/YYYY')} - ${dayjs(endDate).format(
                  'MM/DD/YYYY',
                )}`}
              </li>
              <li>{getDaysOfWeekLabel(days)}</li>
              <li>{`${dayjs(startTime, 'HH:mm').format('HH:mm')} - ${dayjs(endTime, 'HH:mm').format(
                'HH:mm',
              )}`}</li>
            </ul>
          </div>
        </div>
        <div className="mb-10">
          <div className="color-primary mb-5">WHERE:</div>
          <div>{facility?.facility_name}</div>
        </div>
        <div className="mb-10">
          <div className="color-primary mb-5">SERVICE FEE:</div>
          <div className="flex flex-middle">
            <div className="pr-5 previewPrice">Healthcare Professional Services:</div>
            <div>${calculationValues?.base}</div>
          </div>
          <div className="flex flex-middle">
            <div className="pr-5 previewPrice">Para Service Fee 20%:</div>
            <div>${calculationValues.companyFee}</div>
          </div>
          {renderScreeningFee()}
          <div className="flex flex-middle">
            <div className="pr-5 previewPrice">Total:</div>
            <div>
              ${calculationValues.total}
              {`${'  '}(${'$'}${calculationValues.dollarPerHour}/hr)`}
            </div>
          </div>
        </div>
        <small className="color-dark-gray">
          Fees will be charged after the completion of Healthcare Professional Services and
          expiration of the Timesheet review period. Fees are subject to change depending on the
          duration of Healthcare Professional Services rendered
        </small>
      </div>
    </div>
  );
};

export default PreviewForm;
