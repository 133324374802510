import React, { ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useCreateFacility, useRegions } from '../../../../../services/useOrganization';
import { Input, AddressAutoComplete, Form, FormActions } from '../../../../../components';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { FACILITIES } from '../../../../../constants/routes';
import { Address, Facility, FacilityDeskNumber, FacilityPolicy } from '../../../../../types/schema';
import NPINumbersInput from '../NPINumbersInput';
import styles from './FacilityForm.module.scss';

interface FacilityFormProps {
  facility?: Facility;
}

const FacilityForm = (props: FacilityFormProps) => {
  const { facility } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: { ...facility },
    enableReinitialize: true,
    validate: (values) => {
      const errors = {} as { [key: string]: string };
      const requiredError = 'Field is required';
      if (!values.address) {
        errors.address = requiredError;
      }
      if (!values.crib_sheet?.logistic_information?.parking) {
        errors.parking = requiredError;
      }
      if (!values.crib_sheet?.logistic_information?.entrance) {
        errors.entrance = requiredError;
      }
      if (
        !values.crib_sheet?.facility_desk_numbers ||
        values.crib_sheet?.facility_desk_numbers.length === 0
      ) {
        errors.facilityDeskNumbers = requiredError;
      }
      if (
        !values.crib_sheet?.facility_policies ||
        values.crib_sheet?.facility_policies.length === 0
      ) {
        errors.facilityPolicies = requiredError;
      }
      if (!values.npi_numbers) {
        errors.npi_numbers = requiredError;
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      if (facility) {
        console.log('Save facility');
      } else {
        const organization_id = localStorage.getItem('_o') || '';
        mutate({ ...values, organization_id });
      }
    },
  });

  const [mutate] = useCreateFacility({
    onSuccess({ input }) {
      enqueueSnackbar('Successfully created', {
        variant: 'success',
      });
      history.push(FACILITIES);
    },
    onFailure({ error }: { error: { message: string } }) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const { handleSubmit, handleChange, values, setFieldValue, errors, isSubmitting } = formik;

  const handleAutocompleteChange = async (address: Address | null) => {
    setFieldValue('address', address);
    console.log('Address', address);
    if (address) {
      // TODO decide if we want to use address as facility name
      // setFieldValue(
      //   'facility_name',
      //   address.route +
      //     ', ' +
      //     address?.locality +
      //     ', ' +
      //     address.administrative_area_level_1 +
      //     ', ' +
      //     address.country,
      // );
    }
  };

  const handleChangeNPIs = (npi: string) => {
    console.log('NPIs changed:', npi);
    setFieldValue('npi_numbers', [npi]);
  };

  const handleAddFacilityDeskNumber = () => {
    setFieldValue(
      'crib_sheet.facility_desk_numbers',
      values?.crib_sheet?.facility_desk_numbers
        ? [
            ...(values?.crib_sheet?.facility_desk_numbers ?? []),
            {
              description: '',
              contact_info: {
                phone_number: {
                  phone_number: undefined,
                  country_code: undefined,
                },
              },
            },
          ]
        : [
            {
              description: '',
              contact_info: {
                phone_number: {
                  phone_number: undefined,
                  country_code: undefined,
                },
              },
            },
          ],
    );
  };

  const handleRemoveFacilityDeskNumber = (index: number) => {
    setFieldValue(
      'crib_sheet.facility_desk_numbers',
      values?.crib_sheet?.facility_desk_numbers.splice(index, 1),
    );
  };

  const handleAddFacilityPolicy = () => {
    setFieldValue(
      'crib_sheet.facility_policies',
      values?.crib_sheet?.facility_policies
        ? [
            ...(values?.crib_sheet?.facility_policies ?? []),
            {
              policy_type: '',
              policy_description: '',
            },
          ]
        : [
            {
              policy_type: '',
              policy_description: '',
            },
          ],
    );
  };

  const handleRemoveFacilityPolicy = (index: number) => {
    setFieldValue(
      'crib_sheet.facility_policies',
      values?.crib_sheet?.facility_policies.splice(index, 1),
    );
  };

  const handleChangeRegion = (event: SelectChangeEvent) => {
    setFieldValue('address.region_id', event.target.value);
  };

  const handleChangePhoneNumber = (
    phone_number: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const parsedPhone = parsePhoneNumberFromString(phone_number + '' ?? '');
    const countryCode = parsedPhone ? parsedPhone.countryCallingCode.toString() : '1';
    const parsedPhoneNumber = parsedPhone
      ? parsedPhone.nationalNumber.toString()
      : phone_number + '';
    setFieldValue(
      `crib_sheet.facility_desk_numbers[${index}].contact_info.phone_number.country_code`,
      countryCode,
    );
    setFieldValue(
      `crib_sheet.facility_desk_numbers[${index}].contact_info.phone_number.phone_number`,
      parsedPhoneNumber,
    );
  };

  const { data: { data: regions } = { data: [] } } = useRegions();
  const formikErrors = errors as { [key: string]: string };
  return (
    <Form onSubmit={handleSubmit}>
      <Input
        name="facility_name"
        label="Name"
        value={values.facility_name}
        onChange={handleChange}
        // validate={countryCodeRequired}
        // maxLength={4}
      />
      <AddressAutoComplete
        label="Address"
        placeholder="Search places..."
        value={values.address}
        onChange={handleAutocompleteChange}
        error={Boolean(errors.address)}
        helperText={errors.address}
      />

      {regions && (
        <FormControl sx={{ mb: 2 }} fullWidth>
          <InputLabel>Region</InputLabel>
          <Select
            name="address.region_id"
            label="Region"
            value={values.address?.region_id || (regions.length > 0 ? regions[0].region_id : '')}
            variant="standard"
            onChange={handleChangeRegion}
          >
            {regions?.map((region) => (
              <MenuItem key={region.region_id} value={region.region_id}>
                {region.region_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <NPINumbersInput
        value={values.npi_numbers?.map((n) => n.npi_number)}
        onChange={handleChangeNPIs}
        error={Boolean(errors.npi_numbers)}
        helperText={errors.npi_numbers}
        // name="npis"
        // validate={required}
      />

      <div className={styles.formArrayLabel}>Facility Desk Numbers</div>
      {values.crib_sheet?.facility_desk_numbers?.map(
        (facilityDeskNumber: FacilityDeskNumber, index: number) => {
          return (
            <div className={styles.arrayForm} key={index}>
              <Input
                name={`crib_sheet.facility_desk_numbers[${index}].description`}
                label="Description"
                value={facilityDeskNumber.description}
                onChange={handleChange}
                // validate={required}
              />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MuiPhoneNumber
                    disableDropdown={true}
                    defaultCountry={'us'}
                    onChange={(
                      phone_number: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                    ) => handleChangePhoneNumber(phone_number, index)}
                    label="Phone number"
                    value={`+ ${facilityDeskNumber.contact_info?.phone_number?.country_code} ${facilityDeskNumber.contact_info?.phone_number?.phone_number}`}
                  />
                </Grid>
                {index > 0 && (
                  <Grid item alignItems="center">
                    <Button
                      variant="text"
                      size="small"
                      onClick={() => handleRemoveFacilityDeskNumber(index)}
                    >
                      Remove
                    </Button>
                  </Grid>
                )}
              </Grid>
            </div>
          );
        },
      )}
      {Boolean(formikErrors.facilityDeskNumbers) && (
        <Alert color="warning">{formikErrors.facilityDeskNumbers}</Alert>
      )}
      <Box sx={{ mt: 2 }} className={styles.formArrayActions}>
        <Button size="small" onClick={handleAddFacilityDeskNumber} variant="contained">
          Add More
        </Button>
      </Box>

      <div className={styles.formArrayLabel}>Facility Policies</div>
      {values?.crib_sheet?.facility_policies?.map(
        (facilityPolicy: FacilityPolicy, index: number) => (
          <div key={index} className={styles.arrayForm}>
            <Input
              name={`crib_sheet.facility_policies[${index}].policy_type`}
              label="Policy Type"
              value={facilityPolicy.policy_type}
              onChange={handleChange}
              // validate={required}
            />
            <Grid container spacing={2}>
              <Grid item xs>
                <Input
                  name={`crib_sheet.facility_policies[${index}].policy_description`}
                  label="Policy Description"
                  value={facilityPolicy.policy_description}
                  onChange={handleChange}
                  // validate={required}
                />
              </Grid>
              {index > 0 && (
                <Grid item alignItems="center">
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => handleRemoveFacilityPolicy(index)}
                  >
                    Remove
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        ),
      )}
      {Boolean(formikErrors.facilityPolicies) && (
        <Alert sx={{ my: 2 }} color="warning">
          {formikErrors.facilityPolicies}
        </Alert>
      )}
      <Box sx={{ mt: 2 }} className={styles.formArrayActions}>
        <Button size="small" onClick={handleAddFacilityPolicy} variant="contained">
          Add More
        </Button>
      </Box>

      <Input
        name="crib_sheet.logistic_information.parking"
        label="Parking"
        value={values.crib_sheet?.logistic_information?.parking}
        onChange={handleChange}
        error={Boolean(formikErrors.parking)}
        helperText={Boolean(formikErrors.parking) && formikErrors.parking}
      />
      <Input
        name="crib_sheet.logistic_information.entrance"
        label="Entrance"
        value={values.crib_sheet?.logistic_information?.entrance}
        onChange={handleChange}
        error={Boolean(formikErrors.entrance)}
        helperText={Boolean(formikErrors.entrance) && formikErrors.entrance}
        sx={{ mb: 4 }}
      />
      <FormActions>
        <Button onClick={() => history.push(FACILITIES)}>Cancel</Button>
        <Button variant="contained" disabled={isSubmitting} type="submit">
          {facility && facility.facility_id ? 'Save' : 'Create'}
        </Button>
      </FormActions>
    </Form>
  );
};

export default FacilityForm;
