import * as React from 'react';
import dayjs from 'dayjs';
import { IconButton } from '@mui/material';
import { KeyboardBackspace } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { SHIFT_TIME_FORMAT } from '../../../constants/datetime';
import styles from './Header.module.scss';
import { SHIFTS } from '../../../constants/routes';

export interface HeaderProps {
  startTime?: string;
  endTime?: string;
  address?: string;
}

export default function Header({ address, startTime, endTime }: HeaderProps) {
  const history = useHistory();

  const handleBack = () => {
    history.push(SHIFTS);
  };

  return (
    <div className={styles.container}>
      <IconButton onClick={handleBack} className={styles.btnBack} aria-label="back" size="large">
        <KeyboardBackspace />
      </IconButton>
      <div className={styles.info}>
        <div>
          {dayjs(startTime).format('MMM DD, ddd')} {dayjs(startTime).format(SHIFT_TIME_FORMAT)} -{' '}
          {dayjs(endTime).format(SHIFT_TIME_FORMAT)}
        </div>
        <div>{address}</div>
        <div>Registered Nurse </div>
      </div>
    </div>
  );
}
