import { ThemeOptions } from '@mui/material/styles';

export const colors = {
  primary: '#1963b0',
  primaryLight: 'rgba(25, 99, 176, 0.31)',
  secondary: '#CED4DB',
  alert: '#E33A4F',
  alertLight: 'rgba(227, 58, 79, 0.3)',
  success: '#17C122',
  successLight: 'rgba(23, 193, 34, 0.3)',
  warning: '#FFB843',
  warningLight: 'rgba(255, 184, 68, 0.3)',
  white: '#fff',
  textGray: '#4a4a4a',
  lightGray: '#e8ecf1',
  darkGray: '#a9b3be',
};

export const theme: ThemeOptions = {
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.alert,
    },
    success: {
      main: colors.success,
    },
    contrastThreshold: 3,
    tonalOffset: 0.08,
  },
  typography: {
    body1: {
      color: colors.textGray,
      fontSize: '1.0625rem',
    },
    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'Helvetica Neue', 'Roboto', 'Arial', 'sans-serif'].join(','),
  },
  shape: {
    borderRadius: 3,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
          height: 45,
          color: colors.white,
          boxShadow: 'none',
          textTransform: 'initial',
          fontFamily: "-apple-system, 'Helvetica Neue', 'Roboto', 'Arial', sans-serif",
          fontWeight: 500,
          whiteSpace: 'nowrap',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        text: {
          color: colors.primary,
          '&:hover': {
            background: 'initial',
          },
        },
        outlinedPrimary: {
          color: colors.primary,
        },
        containedSecondary: {
          color: colors.white,
        },
        textSecondary: {
          color: colors.secondary,
        },
        sizeSmall: {
          height: 32,
          fontSize: '0.7rem',
        },
        sizeMedium: {
          height: 45,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:after': {
            borderBottom: '1px',
          },
          '&:before': {
            borderWidth: '1px',
            borderColor: colors.lightGray,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.darkGray,
          fontSize: 18,
        },
      },
    },
  },
};
