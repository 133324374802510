import React, { useState } from 'react';
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, useHistory } from 'react-router-dom';
import { logout } from '../../utils/auth';
import { LOGIN, CREATE_SHIFT } from '../../constants/routes';
import { FACILITIES, PROFILE, SHIFTS } from '../../constants/routes';
import { ReactComponent as IconAdd } from '../../assets/icons/icon-add.svg';
import { ReactComponent as IconShifts } from '../../assets/icons/icon-shifts.svg';
import { ReactComponent as IconLocations } from '../../assets/icons/icon-locations.svg';
import styles from './navigation.module.scss';

const NAV_ITEMS = [
  {
    label: 'Create a shift',
    url: CREATE_SHIFT,
    Icon: IconAdd,
  },
  {
    label: 'My shifts',
    url: SHIFTS,
    Icon: IconShifts,
  },
  {
    label: 'Facilities',
    url: FACILITIES,
    Icon: IconLocations,
  },
];

const SidebarNavigation = () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sideNavigationOpened, setSideNavigationOpened] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setSideNavigationOpened(open);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    history.push(LOGIN);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Drawer anchor="left" open={sideNavigationOpened} onClose={toggleDrawer(false)}>
        <nav
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          className={styles.sidebarNavigation}
        >
          <div className={styles.navigationHeader}>
            <IconButton
              edge="start"
              onClick={toggleDrawer(false)}
              className={styles.navigationHeaderBack}
              color="primary"
              aria-label="back"
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>

            <NavLink className={styles.logo} to={SHIFTS}>
              <img src="/images/logo/logo-main.svg" alt="logo" />
            </NavLink>
          </div>
          <div className={styles.navigationMenuItems}>
            {NAV_ITEMS.map((item) => (
              <div key={item.label}>
                <NavLink
                  className={styles.navigationMenuItem}
                  activeClassName={styles.navigationMenuItemActive}
                  to={item.url}
                >
                  <item.Icon className={styles.navigationMenuItemIcon} />
                  <Typography
                    className={styles.navigationMenuItemText}
                    variant="body1"
                    color="primary"
                  >
                    {item.label}
                  </Typography>
                </NavLink>
              </div>
            ))}
          </div>
        </nav>
      </Drawer>

      <AppBar
        position="static"
        sx={{
          backgroundColor: '#fff',
          boxShadow: '0px 2px 10px #E8ECF1',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton
            edge="start"
            onClick={toggleDrawer(true)}
            sx={{
              marginRight: 2,
              color: '#1963B0',
            }}
            color="inherit"
            aria-label="menu"
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6"></Typography>
          <div>
            <IconButton
              sx={{
                color: '#1963B0',
              }}
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              size="large"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  history.push(PROFILE);
                  handleClose();
                }}
              >
                Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleLogout();
                }}
              >
                Log out
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default SidebarNavigation;
