import React, { ReactNode } from 'react';
import { FieldHookConfig, useField } from 'formik';
import { Select, SelectProps, MenuItem, FormControl, SelectChangeEvent } from '@mui/material';
import Label from '../Label';

type DropdownProps = {
  options?: { label: string | ReactNode; value: string }[];
  onValueChange?: (value: string | number | readonly string[]) => void;
};

const Dropdown = (props: DropdownProps & SelectProps & FieldHookConfig<string>) => {
  const { options = [], label, onValueChange, ...rest } = props;
  const [field, meta] = useField(props);
  const isError = meta.touched && meta.error && true;

  const handleFieldChange = (
    event: SelectChangeEvent<string | number | readonly string[]>,
    child: ReactNode,
  ) => {
    field.onChange(event);
    onValueChange?.(event.target.value);
  };

  return (
    <FormControl sx={{ mb: 2 }}>
      {label && <Label error={Boolean(isError)}>{label}</Label>}
      <Select
        {...rest}
        fullWidth
        // helperText={meta.touched ? (meta.error ? meta.error : '') : ''}
        {...field}
        onChange={handleFieldChange}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
