import React, { useState, useEffect } from 'react';
import { OptionItemProps, Loader, Alert } from '../../../../components';
import { SelectableList } from '../../../../components/SelectableList';
import { ListFilter } from '../../components/ListFilter';
import { useDepartments } from '../../../../services/useShifts';
import { useShiftsFilter } from '../../context/shiftContext';

export interface DepartmentFilterProps {
  value?: string;
  onItemClick?: (item: Pick<OptionItemProps, 'label' | 'value'>) => void;
}

export const DepartmentFilter = ({ value, onItemClick }: DepartmentFilterProps) => {
  const { data, isLoading, error } = useDepartments();

  if (isLoading) return <Loader />;
  if (!isLoading && error)
    return (
      <Alert type="error">
        <p>{error.message}</p>
      </Alert>
    );

  const options = data?.data?.map((department) => ({
    label: department.nursing_department,
    value: department.nursing_department_id,
  }));
  options?.sort((pre, next) => pre?.label?.localeCompare(next?.label ?? '') ?? 0);

  return <SelectableList onItemClick={onItemClick} options={options} multiple value={value} />;
};

export interface DepartmentFilterPopupProps {
  open: boolean;
  anchorEl?: null | Element | ((element: Element) => Element);
  onClose: () => void;
}

export const DepartmentFilterPopup = ({ open, anchorEl, onClose }: DepartmentFilterPopupProps) => {
  const { filters, dispatch } = useShiftsFilter();
  const [value, setValue] = useState(filters.departmentId);

  const handleApplyClick = () => {
    dispatch({ type: 'department', payload: value });
    onClose();
  };

  const handleReset = () => {
    dispatch({ type: 'department', payload: '' });
  };

  const handleItemClick = (item: Pick<OptionItemProps, 'label' | 'value'>) => {
    setValue(item.value);
  };

  useEffect(() => {
    setValue(filters.departmentId);
  }, [filters.departmentId]);

  return (
    <ListFilter
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      onApplyFilter={handleApplyClick}
      onResetClick={handleReset}
    >
      <DepartmentFilter onItemClick={handleItemClick} value={value} />
    </ListFilter>
  );
};
