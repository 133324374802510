import React from 'react';
import { throttle } from 'lodash-es';
import { Autocomplete, Box, MenuItem, Typography, TextField } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { geocodeByPlaceId } from '../../utils';
import { Address, Place } from '../../types/schema';

const addressColumns = [
  'street_number',
  'route',
  'locality',
  'administrative_area_level_1',
  'country',
  'postal_code',
  'latitude',
  'longitude',
  'unit_number',
];

export interface IAddressAutoCompleteProps {
  label?: string;
  placeholder?: string;
  value?: Address;
  onChange: (value: Address | null) => void;
  error?: boolean;
  helperText?: string;
}

// const autocompleteService = { current: null };

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = 'AIzaSyDLvqriQ8zguFoTaOTUt4zxoUBYSIHblaA';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

export function AddressAutoComplete({
  label,
  placeholder,
  value,
  onChange,
  error,
  helperText,
}: IAddressAutoCompleteProps) {
  const [internalValue, setInternalValue] = React.useState<Place | null>();
  const [options, setOptions] = React.useState<Place[]>([]);
  const [inputValue, setInputValue] = React.useState('');

  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const autocompleteService = React.useRef<google.maps.places.AutocompleteService | null>(null);

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results: google.maps.places.AutocompletePrediction[] | null) => void,
        ) => {
          autocompleteService.current?.getPlacePredictions(request, callback);
        },
        200,
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(internalValue ? [internalValue] : []);
      return undefined;
    }

    try {
      fetch(
        { input: inputValue },
        (results: google.maps.places.AutocompletePrediction[] | null) => {
          if (active) {
            let newOptions = [] as Place[];

            if (internalValue) {
              newOptions = [internalValue];
            }

            if (results) {
              newOptions = [...newOptions, ...results];
            }
            setOptions(newOptions);
          }
        },
      );
    } catch (error) {
      console.error('Error while fetching Google Places Autocomplete options');
    }

    return () => {
      active = false;
    };
  }, [internalValue, inputValue, fetch]);

  const handlePreprocessAddress = async (place: Place) => {
    const geoCodes = (await geocodeByPlaceId(
      place?.place_id ?? '',
    )) as google.maps.GeocoderResult[];
    if (!geoCodes || !Array.isArray(geoCodes) || geoCodes.length === 0) return;
    const code = geoCodes[0];
    const formattedAddress = {} as Address;
    code.address_components.forEach((obj) => {
      obj.types.forEach((type) => {
        if (addressColumns.includes(type)) {
          formattedAddress[type as keyof Address] = obj.long_name;
        }
        if (type === 'sublocality') {
          formattedAddress['locality'] = obj.long_name;
        }
      });
    });
    if (code.geometry) {
      formattedAddress['latitude'] = code.geometry.location.lat() + '';
      formattedAddress['longitude'] = code.geometry.location.lng() + '';
    }
    // formattedAddress['place'] = place;
    onChange(formattedAddress);
  };

  return (
    <Autocomplete
      sx={{ mt: 2, mb: 2 }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(value) => value}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={internalValue}
      onChange={(event: React.SyntheticEvent, newValue: Place | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setInternalValue(newValue);
        if (newValue) {
          handlePreprocessAddress(newValue);
        }
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        return (
          <MenuItem {...props} sx={{ display: 'flex', alignItems: 'center' }} key={option.place_id}>
            <LocationOnIcon />
            <Box sx={{ ml: 2 }}>
              <Typography variant="body1">{option.structured_formatting.main_text}</Typography>
              <Typography variant="subtitle2">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Box>
          </MenuItem>
        );
      }}
    />
  );
}
