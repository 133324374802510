export { default as AvailableZipCodes } from './zipFelds';
export * from './theme';
export * from './permissions';
export * from './serverErrors';
export * from './shiftFilterValues';

export const urls = {
  userIcon: '/images/icons/user-icon-placeholder.jpg',
};

export const CERTS_VACCS_MAP_VALUES = {
  basic: 'Basic Life Support (BLS)',
  advanced: 'Advanced Cardiovascular Life Support (ACLS)',
  hepatit: 'Hepatitis B',
  tdap: 'TDAP',
  flu: 'Flu',
};

export const DOC_NAMES = {
  terms_service: 'TermsOfService',
  membershipDemand: 'MembershipAgreementDemand',
  membershipSupply: 'MembershipAgreementSupply',
  policy: 'PrivacyPolicy',
};

export const DOC_TYPES = [
  DOC_NAMES.terms_service,
  DOC_NAMES.membershipDemand,
  DOC_NAMES.membershipSupply,
  DOC_NAMES.policy,
];

// keys for storage
export const PERSISTENCE_KEYS = {
  ACCEPT_TERM_AND_CONDITION: '_t&c',
  FIRST_TIME_LOGIN: '_ftl',
};
