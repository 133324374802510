import React from 'react';
import AuthenticationLayout from '../../../layout/AuthenticationLayout';
import SignupVerificationForm from './components/SignupVerificationForm';

const SignupVerification = () => {
  return (
    <AuthenticationLayout
      title="Welcome to Para!"
      subtitle="Please set your account password and phone number"
      form={<SignupVerificationForm />}
    />
  );
};

export default SignupVerification;
