import React from 'react';
import { Grid } from '@mui/material';
import { Title, Text } from '../helper/Text';
import { ShiftDetail } from '../../../types/schema';
import formatCurrency from '../../..//utils/formatCurrency';
import styles from './Footer.module.scss';

export interface FooterProps {
  shift: ShiftDetail;
}

export default function Footer({ shift }: FooterProps) {
  const { shift_cost } = shift;

  return (
    <div className={styles.container}>
      <Title>SERVICE FEE</Title>

      {shift_cost?.total?.shift_duration && (
        <Grid container>
          <Grid xs={8}>
            <Text>Healthcare Professional Services (Estimate)</Text>
          </Grid>
          <Grid xs={4}>
            <Text>{formatCurrency(shift_cost?.total?.shift_duration?.pay?.string)}</Text>
          </Grid>
        </Grid>
      )}

      {shift_cost?.service_fees?.map((fee) => (
        <Grid container key={fee.shift_service_fee_type?.shift_service_fee_type_id}>
          <Grid xs={8}>
            <Text>{fee.shift_service_fee_type?.shift_service_fee_type}</Text>
          </Grid>
          <Grid xs={4}>
            <Text>{formatCurrency(fee?.string_format)}</Text>
          </Grid>
        </Grid>
      ))}

      <div className={styles.divider} />
      <Grid container>
        <Grid xs={8}>
          <Title>Total</Title>
        </Grid>
        <Grid xs={4}>
          <Title>{formatCurrency(shift_cost?.total?.grand_total?.string as number)}</Title>
        </Grid>
      </Grid>

      <div className={styles.note}>
        Total Fees will be charged after the completion of Healthcare Professional Services and
        expiration of the Timesheet review period. Total Fees are subject to change depending on the
        duration of Healthcare Professional Services rendered.
      </div>
    </div>
  );
}
