import React from 'react';
import { FieldHookConfig, useField } from 'formik';
import { TextFieldProps } from '@mui/material';
import { Input } from '../../../../components';

const InputField = (props: TextFieldProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props.name);
  const isError = meta.touched && meta.error && true;
  return (
    <Input
      {...props}
      {...field}
      error={Boolean(isError)}
      helperText={meta.touched ? (meta.error ? meta.error : '') : ''}
    />
  );
};

export default InputField;
