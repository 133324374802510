export interface FormatCurrencyOptions {
  currency?: string;
  maximumSignificantDigits?: number;
}

export default function formatCurrency(
  num = 0,
  { currency = 'USD', maximumSignificantDigits = 3 }: FormatCurrencyOptions = {},
) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    maximumSignificantDigits,
  });
  console.log('Formatter', formatter);
  return formatter.format(num);
}
