import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { Form, FormActions, Input } from '../../../../../components';
import { LOGIN } from '../../../../../constants/routes';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { getErrorMessage, validateEmail } from '../../../../../utils';
import { requestResetPassword } from '../../../../../services/authentication.service';

const ForgotPasswordForm = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate: (values) => {
      const errors = {} as {
        email: string;
      };
      if (!values.email) {
        errors.email = 'Required';
      } else if (!validateEmail(values.email)) {
        errors.email = 'Invalid email address';
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await requestResetPassword(values.email);
        setSubmitting(false);
        enqueueSnackbar(
          'An email is on its way to you, follow the instructions to reset your password',
          {
            variant: 'success',
          },
        );
        history.push('/');
      } catch (error) {
        setSubmitting(false);
        enqueueSnackbar(getErrorMessage(error) || 'Authentication error', {
          variant: 'error',
        });
      }
    },
  });

  const { handleSubmit, values, handleChange, touched, errors, isSubmitting } = formik;
  return (
    <Form onSubmit={handleSubmit}>
      <Input
        id="email"
        name="email"
        type="email"
        label="Email"
        value={values.email}
        onChange={handleChange}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
      />
      <FormActions>
        <Button type="submit" variant="contained" disabled={isSubmitting}>
          Reset my password
        </Button>
        <Button variant="outlined" disabled={isSubmitting} onClick={() => history.push(LOGIN)}>
          Back
        </Button>
      </FormActions>
    </Form>
  );
};

export default ForgotPasswordForm;
