import decode from 'jwt-decode';
import { POST, buildEndpointURL } from './services';

interface AuthenticationResponse {
  credentials: {
    jwt: string;
  };
  organization_id: string;
  primary_personnel: string;
}

export interface AuthenticationToken {
  account_data: {
    metadata: {
      organization_id: string;
      primary_personnel: string;
      demand: {
        is_primary_personnel: string;
        organization_id: string;
      };
    };
    account_id: string;
  };
}

const login = (email_address: string, password: string) => {
  const url = buildEndpointURL('podnik', '/authentications/authenticate');
  return POST(url, { data: { email_address, password } }).then(
    ({ data }: { data: AuthenticationResponse }) => {
      const accountData = parseLoginResponse(data);
      return accountData;
    },
  );
};

const requestSignupToken = (
  email_address: string,
  first_name: string,
  last_name: string,
  organization_name: string,
) => {
  const url = buildEndpointURL('podnik', '/signup/request-token');
  return POST(url, { data: { email_address, first_name, last_name, organization_name } });
};

const signup = (
  email_address: string,
  token: string,
  password: string,
  country_code: string,
  phone_number: string,
  first_name: string,
  last_name: string,
  organization_name: string,
) => {
  const url = buildEndpointURL('podnik', '/signup/verify');
  return POST(url, {
    data: {
      email_address,
      token,
      password,
      phone_number: {
        country_code,
        phone_number,
      },
      first_name,
      last_name,
      organization_name,
    },
  });
};

const requestPhoneVerificationCode = (country_code: string, phone_number: string) => {
  const url = buildEndpointURL('podnik', '/contact-info/phone-number/verification/request-code');
  return POST(url, {
    data: {
      phone_number: {
        country_code,
        phone_number,
      },
    },
  });
};

const verifyPhoneNumber = (
  country_code: string,
  phone_number: string,
  verification_code: string,
) => {
  const url = buildEndpointURL('podnik', '/contact-info/phone-number/verification/verify-code');
  return POST(url, {
    data: {
      verification_code,
      phone_number: {
        country_code,
        phone_number,
      },
    },
  });
};

const requestResetPassword = (email_address: string) => {
  const url = buildEndpointURL('podnik', '/password/request-token');
  return POST(url, {
    data: {
      email_address,
    },
  });
};

const resetPassword = (email_address: string, token: string, password: string) => {
  const url = buildEndpointURL('podnik', '/password/reset');
  return POST(url, {
    data: {
      email_address,
      token,
      password,
    },
  });
};

const parseLoginResponse = (data: AuthenticationResponse) => {
  const decodedToken = decode<AuthenticationToken>(data.credentials.jwt);
  const accountData = decodedToken.account_data;
  const organisationId = accountData.metadata
    ? accountData.metadata.demand
      ? accountData.metadata.demand.organization_id
      : accountData.metadata.organization_id
    : data.organization_id;
  const primaryUser = accountData.metadata
    ? accountData.metadata.demand
      ? accountData.metadata.demand.is_primary_personnel
      : accountData.metadata.primary_personnel
    : data.primary_personnel;
  localStorage.setItem('_t', data.credentials.jwt);
  localStorage.setItem('_i', accountData.account_id);
  localStorage.setItem('_p', primaryUser);
  localStorage.setItem('_o', organisationId);
  // loginSuccessAction(dispatch, {
  //   id: accountData.account_id,
  //   organizationId: organisationId,
  //   primaryUser: primaryUser,
  // });
  return accountData;
};

export {
  login,
  requestSignupToken,
  signup,
  requestPhoneVerificationCode,
  verifyPhoneNumber,
  requestResetPassword,
  resetPassword,
};
