import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { Loader } from '..';

type InputProps = {
  loading?: boolean;
  min?: string | number;
  max?: string | number;
} & TextFieldProps;

const Input: React.FunctionComponent<InputProps> = (props) => {
  return (
    <TextField
      variant="standard"
      {...props}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: props.loading ? <Loader size={10} /> : props.InputProps?.endAdornment,
        ...props.InputProps,
        inputProps: {
          min: props.min || null,
          max: props.max || null,
        },
      }}
      margin="dense"
      fullWidth={props.fullWidth ?? true}
    />
  );
};

export default Input;
