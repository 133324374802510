import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ApplicationLayout from '../../layout/ApplicationLayout';
import { Loader } from '../../components';
import { useFacilities, useOrganizationDetails } from '../../services/useOrganization';
import { Facility } from '../../types/schema';
import { FACILITIES } from '../../constants/routes';
import FacilityListItem from './FacilityListItem';
import styles from './Facilities.module.scss';

export const FacilitiesPage = () => {
  const history = useHistory();

  const accountId = localStorage.getItem('_i') || '';
  const organizationId = localStorage.getItem('_o') || '';
  const { data: { data: organization } = { data: null } } = useOrganizationDetails(accountId);
  const { data = { data: [] }, isLoading } = useFacilities(organizationId);
  const { data: facilities } = data;

  return (
    <ApplicationLayout>
      <Container maxWidth="lg" sx={{ p: 4 }}>
        {organization && (
          <Typography variant="h4" color="primary">
            {organization.organization_name}
          </Typography>
        )}
        <div className={styles.header}>
          <Typography variant="h6">Locations</Typography>
          <Button variant="outlined" size="small" onClick={() => history.push(FACILITIES + '/new')}>
            Add New Facility
            <AddIcon sx={{ ml: 1 }} />
          </Button>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          (facilities ?? []).map((facility: Facility) => (
            <FacilityListItem
              // onClick={() => history.push(FACILITIES + '/' + facility.facility_id)}
              key={facility.facility_id}
              {...facility}
            />
          ))
        )}
      </Container>
    </ApplicationLayout>
  );
};

export default FacilitiesPage;
