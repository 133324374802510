import { round } from 'lodash-es';
import { BackendError } from '../services/services';

export const getErrorMessage = (error: unknown) => {
  return (error as BackendError).message;
};

export const remCalc = (px: number, base = 16, asString = true) => {
  const rem = round(px / base, 3);
  if (asString) return `${rem}rem`;
  return rem;
};

export const geocodeByPlaceId = (placeId: string | null | undefined) => {
  const geocoder = new window.google.maps.Geocoder();
  const OK = window.google.maps.GeocoderStatus.OK;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId }, (results, status) => {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};
