import React from 'react';
import { Container, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import ApplicationLayout from '../../../layout/ApplicationLayout';
import { useFacility } from '../../../services/useOrganization';
import FacilityForm from './components/FacilityForm';

const FacilityPage = () => {
  const { facilityId } = useParams<{ facilityId: string }>();
  const organizationId = localStorage.getItem('_o') || '';

  const { data = { data: undefined } } = useFacility(
    organizationId,
    facilityId !== 'new' ? facilityId : '',
  );
  const { data: facility } = data;

  return (
    <ApplicationLayout>
      <Container maxWidth="md" sx={{ p: 4, mb: 4 }}>
        <Typography variant="h4" color="primary">
          {facilityId !== 'new' ? 'Edit location' : 'Add new location'}
        </Typography>
        <FacilityForm facility={facility} />
      </Container>
    </ApplicationLayout>
  );
};

export default FacilityPage;
