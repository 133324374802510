import React from 'react';

const MembershipPage = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1 className="text-center mb-30">Para’s Membership Agreement</h1>

      <p>
        THIS MASTER MEMBERSHIP AGREEMENT ("<strong>Agreement</strong>") is by and between Para
        Services, Inc. ("<strong>Para</strong>") and you ("Customer"). Para and Customer each is a "
        <strong>Party</strong>" and together are the "Parties" to this Agreement.
      </p>

      <p>
        Please read all the terms and conditions of this Agreement carefully and, if you agree,
        indicate your acceptance by selecting "I have read and agree to Para's Membership Agreement"
        or "I have read and accept to All" on the agreement homepage of the Para Platform (as
        defined below). By selecting "I have read and agree to Para's Membership Agreement" or "I
        have read and accept to All" Customer is signing this Agreement electronically (Customer’s
        "E-Signature"), and such selection constitutes Customer’s legal signature, acceptance and
        agreement as if this Agreement were actually signed by Customer in writing and the Agreement
        will be effective as of the date of said selection ("Effective Date"). Customer also agrees
        that no certification, authority, or other third party verification is necessary to validate
        Customer’s E-Signature and that lack of such certification or third party verification will
        not in any way affect the enforceability of Customer’s E-Signature or any resulting contract
        between Customer and Para.
      </p>

      <p>
        IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU
        REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS
        AND CONDITIONS, IN WHICH CASE THE TERM "CUSTOMER" SHALL REFER TO SUCH ENTITY AND ITS
        AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS AND
        CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE SERVICES (AS DEFINED
        BELOW)
      </p>

      <p>
        For contractual purposes, Customer: (a) consents to receive communications from Para in
        electronic form, and (b) agrees that all terms and conditions, agreements, notices,
        disclosures and any other communications that Para provides to Customer electronically
        satisfy any legal requirement that such communications would satisfy if they were in a
        print-on-paper writing.
      </p>

      <h5>Standard Terms & Conditions</h5>

      <h4>1. Definitions.</h4>
      <p>
        "Affiliate" means, with respect to a Party, a legal entity that is controlled by, controls,
        or is under common control with that Party. "Control" means ownership of more than 50% of
        the voting power or ownership interests of the entity or the right to direct or control the
        management of the entity.
      </p>

      <p>
        "Additional Third Party Terms" mean any additional third party terms and conditions
        applicable to the Service for which Customer has a Membership. The Additional Third Party
        Terms are part of the Standard Terms.
      </p>

      <p>
        "Authorized User" means Customer’s employees and individual contractors whose duties require
        use and access to the Service provided that each individual contractor agrees in writing to
        comply with the terms of this Agreement.
      </p>

      <p>
        "Confidential Information" means proprietary or confidential information of a Party, its
        Affiliates, Representatives or customers, including, but not limited to, the Services, Site,
        Documentation, end-user materials, work product, Intellectual Property, proposals, designs,
        concepts, methodologies, inventions, source or object code, developments, research,
        programs, databases, referral sources, customers, prospective customers, inventions,
        developments, "know-how," procedures, financial information or licensing policies,
        technical, financial, marketing, staffing, business plans and information, strategic
        information, proposals, specifications, drawings, documentation, manuals, know-how, pricing
        information, customer information, procedures, processes, techniques, software code and
        software programs, that are marked as "confidential" or "proprietary", or is of such a
        nature and character that a reasonable person under the circumstances would understand it to
        be confidential, and all non-public information provided by the disclosing Party to the
        receiving Party in the course of the performance of the Services. Notwithstanding the
        foregoing, Confidential Information shall not include any information that: (a) was in the
        public domain prior to disclosure by the disclosing Party; (b) comes into the public domain
        through no fault of the receiving Party; (c) is authorized for disclosure in writing by the
        disclosing Party; (d) is disclosed to a Party without restriction by a third party who has a
        legal right to make such disclosure; and/or (e) the receiving Party independently develops
        without use of or reference to the Confidential Information of the disclosing Party.
        "Representatives" means a Party’s affiliates, directors, officers, employees, agents,
        consultants, advisors, and other representatives (including legal counsel and accountants).
      </p>

      <p>
        Customer Data" means all data provided by Customer to Para in conjunction with Customer’s
        use of the Services.
      </p>

      <p>
        "Documentation" means the instructional manuals and functional specifications that accompany
        the Services as delivered by Para related to the features, functions, and use of the
        Services.
      </p>

      <p>
        "Fees" means the fees payable by Customer to Para for the Services, including the Healthcare
        Professional Services, as set forth on the Para Platform at the time Customer posts a Shift,
        including any applicable Screening Fee, Cancellation Fee, or Surcharge Fee.
      </p>

      <p>
        "Healthcare Professional Services" mean the type of healthcare services specific to a
        particular Shift as stated on the Para Platform for each Independent Contractor to perform
        for the duration of the Shift.
      </p>

      <p>
        "Intellectual Property" means intellectual property rights (including, but not limited to,
        patents, patent applications, trade secrets, trademarks, trade names, service marks, logos,
        moral rights, or any other copyrights inherent thereunder, along with any registrations and
        applications to register, as applicable) in tangible and intangible materials or information
        which may be continually under development, modification or improvement, including, but not
        limited to, software, source code, object code, routines, tools, documentation,
        methodologies, techniques, algorithms, inventions, ideas, databases, and know-how.
      </p>

      <p>
        "Membership" means Customer’s right to access and use the Services, as applicable, during
        the Membership Term.
      </p>

      <p>
        "Membership Term" means the period of time during which Customer has the right to a
        Membership for the Service.
      </p>

      <p>
        "Para Tools" means any and all processes, materials, software, and products, including
        Intellectual Property in the foregoing, and any modifications, enhancements, or derivative
        works thereof, developed or used by or on behalf of Para in its delivery of Services that do
        not contain any of Customer’s Confidential Information, whether or not in existence prior to
        the Agreement Effective Date.
      </p>

      <p>
        "Para Pre-existing Technology" means Para Tools and any other Intellectual Property (a)
        owned by Para prior to the Effective Date and any enhancement, modification, alteration,
        change, variation, revision, adaptation, improvement, update, or upgrade thereto, or any
        derivative work thereof, (b) developed or acquired by Para outside the performance of the
        Services, and any enhancement, modification, alteration, change, variation, revision,
        adaptation, improvement, update, or upgrade thereto, or any derivative work thereof, or (c)
        which, subsequent to the Effective Date, is developed or acquired by Para in the performance
        of the Services and any enhancement, modification, alteration, change, variation, revision,
        adaptation, improvement, update or upgrade thereto or any derivative work thereof.
      </p>

      <p>
        "Para Platform" means the system consisting of hardware and proprietary software
        applications and services, including those designed to allow healthcare facilities to fill
        healthcare professional shift needs with healthcare professionals available in their
        immediate geographic area hosted on a software-as-a-service basis, and any other services or
        software provided by Para.
      </p>

      <p>
        "Personal Data" means any "personally identifiable information," as the term is defined by
        the National Institute of Standards and Technology ("NIST") pursuant to its
        <span className="line-breake-inline" />
        <a href="https://csrc.nist.gov/publications/detail/sp/800-122/final">
          Guide to Protecting Confidentiality of Personally Identifiable Information.
        </a>
      </p>

      <p>
        "Screening Fee" means the fee for completing the background check and drug screening of an
        individual healthcare professional if selected by Customer.
      </p>

      <p>
        "Services" means the services provided by Para to Customer to which Customer has access via
        the Para Platform.
      </p>

      <p>
        "Shift" means any posted work opening intended to be performed by one individual healthcare
        professional, the duration of which will be for as many days as the Customer selects on the
        Para Platform; provided, however, the hours to be completed by an individual healthcare
        professional shall not exceed 13 hours per 24 hour period.
      </p>

      <p>
        "Site" means the website located at www.joinpara.com on which the policies, Additional Third
        Party Terms, and Services may be accessed.
      </p>

      <p>
        "Terminals" means Customer’s computer hardware capable of running an internet browser and
        shall specifically include Customer’s desktop computers, laptops, or mobile devices.
      </p>

      <h5>2. Services and Right to Use.</h5>
      <p>
        Access and Rights of Use. Subject to the terms and conditions of the Agreement, Para grants
        to Customer a limited, non-exclusive, non-transferable, and non-sublicensable license for
        Customer and its Authorized Users to access and use the Services solely for Customer’s
        internal business purposes during the Term (defined below). Customer shall not allow any
        third party or anyone who is not an Authorized User to use or access the Services without
        Para’s prior written consent. This Agreement does not permit access to the Services through
        devices other than the Terminals of Customer or Customer’s Authorized Users.
      </p>

      <p>
        Service Delivery. Customer understands and agrees that the Services and Site may be hosted
        by Para’s trusted third party hosting service provider(s) and made accessible by Para to
        Customer via internet connections pursuant to this Agreement.
      </p>

      <p>
        Documentation. Subject to the terms and conditions of the Agreement, Para grants to Customer
        a limited, non-exclusive, non-transferable, and non-sublicensable right and license to use
        and make copies of the Documentation for internal use, archival purposes, and for training
        and educating Customer’s Authorized Users, provided that all proprietary notices of Para and
        its licensors, if any, are reproduced on such copies.
      </p>

      <p>
        Additional Third Party Terms. Customer acknowledges and agrees that Customer’s use of the
        Services may be subject to Additional Third Party Terms as follows:
      </p>

      <p>
        2.1.1 If the Services included with Customer’s Membership include proprietary third party
        software, such software may be subject to additional or different license terms that are
        incorporated into these Standard Terms by reference, or may require Customer to agree to the
        terms of a separate end user license agreement with the publisher of such third party
        software.
      </p>

      <p>
        2.1.2 If the Services or software included with Customer’s Membership include software that
        is redistributed by Para under free or open source software ("FOSS") license terms, the
        applicable FOSS terms which govern Customer’s rights to use the specific items of FOSS shall
        be incorporated.
      </p>

      <p>
        Membership. Customer will enroll and have access to use the Services through a Membership
        for the Membership Term.
      </p>

      <p>
        Ownership. The Services and any software are licensed and not sold to Customer. Customer
        shall not acquire any rights whatsoever in the Services, Para Pre-existing Technology, Site,
        Software, or Para Platform aside from the limited licenses granted under this Agreement and
        Customer expressly disclaims any other rights. All rights not specifically granted herein
        are reserved by Para and its licensors. Nothing herein shall prevent Para or its licensors,
        if any, from promoting, providing, licensing or sublicensing the Para Platform, Para
        Pre-existing Technology, Services, Site, or providing any services to other parties.
      </p>

      <p>
        Acceptable Use. Customer may use the Services only in accordance with the Agreement, the
        Standard Terms, and any applicable terms of service or policies posted on the Site. Customer
        assumes sole and complete responsibility for ensuring that the Services are used only by
        Customer and its Authorized Users. Unauthorized use includes, without limitation: (a) use of
        the Services to access websites other than the Site; (b) attempts to gain access to data
        about individuals other than for legitimate business purposes; (c) alteration or
        modification of individual data or of any software or Services configuration values from the
        originally delivered values; (d) accessing the Services through any technology or means
        other than through the user account information provided to Customer by Para; (e) if Para
        and Customer have agreed that Customer will use the Services from dedicated computers or
        Terminals, installation or use of the Services on computers other than the dedicated
        computers or Terminals; and (f) any use of the Services or the information contained therein
        in violation of any applicable law or regulation. Additionally, Customer will use best
        efforts to ensure that Authorized Users comply with the following requirements, and will be
        responsible for any noncompliance by Authorized Users: (i) Authorized Users must not enter
        or transmit any information using the Services that is unlawful, false, offensive,
        defamatory, or infringes the rights of any person; (ii) Authorized Users must not use the
        Services in a way that disrupts use of the Services by others; and (iii) Authorized Users
        must not use any information obtained through the Services except for the purpose for which
        the information was provided, and must not attempt to use the Services to gain unauthorized
        access to information.
      </p>

      <p>
        Prohibitions. Neither Customer nor any of its Authorized Users may modify, decompile,
        reverse compile, disassemble, reverse engineer, decrypt, or otherwise seek to recreate the
        source code of the Services, adapt the Services in any way, use the Services to create a
        derivate work, or grant any other person or entity the right or access to do so, without the
        advance written consent of Para. Customer (or its Authorized Users) may not duplicate, copy,
        unbundle, sublicense, hypothecate, sell, assign, transfer, display, distribute, lend, rent,
        lease or host the Services or any portion thereof to or for a third party, except as
        expressly permitted in the Agreement.
      </p>

      <p>
        Account. Customer is responsible for maintaining the confidentiality of any credentials,
        including any usernames and passwords, associated with Customer’s use of the Services.
        Should Customer become aware of or suspect any unauthorized use(s) or access to Customer’s
        Services, or any security incident related to Customer’s Membership, Customer shall promptly
        notify Para of such unauthorized access or other security event.
      </p>

      <p>
        Healthcare Professional Services. During the Membership Term, through use of the Para
        Platform pursuant to Section 2.1, Customer may publish Shifts in accordance with Customer’s
        hiring needs. The Para Platform will notify the assigned Independent Contractor (defined
        below) of the available Shift and provide Customer with notification that such Independent
        Contractor meets the parameters that Customer set forth on the Para Platform for that
        particular Shift. All healthcare professionals that have agreed to provide Healthcare
        Professional Services for Customer’s Shifts are retained on an independent contractor basis
        (each an "<strong>Independent Contractor</strong>").
      </p>

      <p>
        Para Professional Services. During the Membership Term, Para shall provide to Customer
        access to the Para Platform. Para will review the license status of each Independent
        Contractor that signs up for the Para Platform as reported by the appropriate regulatory
        and/or issuing authority. In order to have access to the Para Platform, each Independent
        Contractor shall maintain a license status that is unencumbered as reported by the
        appropriate regulatory and/or issuing authority. If requested by Customer through a posted
        Shift on the Para Platform, Para will facilitate a criminal background screening including a
        Level 3 search in The Fraud and Control Information System (FACIS) database and/or a
        10-panel drug screening through the use of a third-party service, as indicated on the Para
        Platform, at Customer’s sole cost. This request is subject to a Screening Fee as set forth
        on the Para Platform at the time Customer posts a Shift.
      </p>

      <h5>3. Customer Obligations.</h5>

      <h6>Primary Obligations.</h6>

      <p>
        3.1.1 Para Platform. Customer shall have the sole responsibility for acquiring, installing
        and maintaining its own technology environment and equipment necessary to properly access,
        operate and utilize the Services, including, without limitation: servers, internet access,
        LANs and WANs; for any communications or other costs incurred in operating, accessing and
        using the Services; and for any other expenses relating to the foregoing. Customer shall be
        responsible for selecting and training adequate Authorized Users with the requisite
        experience necessary to operate Customer’s systems and use the Services. Customer shall
        establish adequate operational backup systems and procedures to ensure recovery and
        continuity of its systems and operations in the event of a failure. Customer shall be solely
        responsible for the security and confidentiality of any usernames or passwords granted to
        Customer or its Authorized Users to access the Services, and shall limit disclosure of such
        usernames and passwords to its Authorized Users. Customer shall be solely responsible for
        any authorized or unauthorized access to the Services using such usernames and passwords,
        and any actions taken thereunder. Customer shall limit use of access to the Services solely
        to those of its Authorized Users whose duties require such use and access and shall
        undertake best efforts to ensure that Para’s Confidential Information and Para Intellectual
        Property are kept secure. Customer shall use its best efforts to ensure that all Authorized
        Users afforded access to the Services and any other information and materials produced or
        disclosed in connection therewith protect the same against unauthorized use, dissemination
        or disclosure. Customer shall have the sole responsibility for maintaining the privacy and
        security of all Customer Data. Customer agrees to cooperate with Para as necessary to allow
        Para to provide the Services and perform its obligations pursuant to this Agreement, and to
        provide, in a timely manner, such information, including the Customer Data, that is complete
        and accurate in all respects, as Para may require in order to provide the Services to
        Customer.
      </p>

      <p>
        3.1.2 Healthcare Professional Services. Customer shall provide each Independent Contractor
        with the policies, procedures, and rules specific to Customer’s operations and facilities,
        and those applicable to the Shift that the Independent Contractor is fulfilling. Customer
        shall maintain responsibility for orientation, training, performance reviews, and necessary
        disciplinary reporting of all Independent Contractors, per Customer’s standard operating
        procedures. Upon arrival of each Independent Contractor for any Shift, Customer shall verify
        the identity of the Independent Contractor assigned by Para.
      </p>

      <p>
        3.1.3 Customer hereby acknowledges and agrees that it has the sole responsibility to
        maintain all necessary certifications, licenses, and permits to provide healthcare services
        and hire Independent Contractors to perform such healthcare services at Customer’s place of
        operations. Should Customer fail to maintain any requisite certification, license, or
        permit, Customer will promptly notify Para in writing and take action to obtain the
        necessary certification, license, or permit. Should Customer fail to maintain and obtain any
        necessary certification, license, or permit for the Parties to perform under this Agreement,
        Para shall have the right to immediately terminate the Agreement.
      </p>

      <p>
        Customer Data. Customer is responsible for securing and maintaining all rights in Customer
        Data to allow Para to provide the Services to Customer without violating any third party’s
        rights, including any Intellectual Property rights.
      </p>

      <p>
        Security and Data Protection. Para has established and shall use commercially reasonably
        adequate security programs and procedures relating to the Services and Customer Data. Each
        of Customer and Para agrees to use its reasonable efforts to prevent unauthorized persons
        from having access to the Services or Site, or any equipment providing the Services. Each
        Party agrees to notify the other Party promptly upon becoming aware of any unauthorized
        access or use of the Services, Site, or Customer Data, by any third party. Customer shall be
        responsible for protection of its data against loss, damage or destruction, including, but
        not limited to, daily backups, anti-virus protection and data security measures. Customer
        shall backup individual computers before any Services are rendered in order to protect
        against any loss, damage or destruction of data. Para will have no responsibility or
        liability for lost, damaged or destroyed data, except in the event of Para’s gross
        negligence or willful misconduct, in which case Customer’s sole and exclusive remedy shall
        be to require Para to reload the lost, damage or destroyed data from the backup tapes
        provided by Customer. Notwithstanding the foregoing, each Party shall have generally
        accepted industry standard internet security software or tools installed on its
        infrastructure. Such internet security software shall be up to date within one patch or
        release. Customer will take reasonable precautions to ensure that (a) no Personal Data is
        unintentionally transmitted or delivered to Para, and (b) to the extent any delivery or
        transmission of Personal Data is required in connection with Services by Para, all such
        Personal Data is encrypted (in accordance with generally accepted industry best practice)
        prior to delivery or transmission to Para. Customer hereby agrees to indemnify and hold
        harmless Para and its directors, officers, agents, employees, members, subsidiaries, and
        successors in interest from and against any and all damages, losses, liabilities,
        settlements and expenses (including, without limitation, reasonable costs and attorneys’
        fees) in connection with any claim or action that arises from an alleged violation of this
        Section by Customer.
      </p>

      <p>
        Regulatory Compliance. Customer assumes sole and complete responsibility, at its expense,
        for ensuring that its Authorized Users comply with and use the Services and perform all
        obligations in compliance with all applicable federal, state and local laws and regulations,
        including, without limitation, laws relating to confidentiality of consumer information, and
        any other federal, state or local privacy or information security laws or regulations.
      </p>

      <p>
        Suitability. Customer is responsible for determining the suitability of the Services for its
        operations and whether the Services will achieve the results that the Customer desires. The
        Services are provided as a tool for Customer to use as Customer sees fit in its business.
        Customer is responsible for ensuring the accuracy of any Customer Data or other data input
        in to the Services and ensuring and confirming the accuracy of any output and results
        created by the Services prior to use or dissemination.
      </p>

      <h6>Confidentiality.</h6>
      <small>
        <p>
          Confidentiality. During the Term of this Agreement, each Party may have access or have
          disclosed to it to certain Confidential Information of the other Party and/or its
          Affiliates. Each Party agrees to (a) use the disclosing Party’s Confidential Information
          solely in connection with its performance under this Agreement; (b) disclose the
          disclosing Party’s Confidential Information only to its Representatives who have a need to
          know, provided that such Representatives are bound by non-disclosure obligations
          substantially similar to this Section 4; (c) protect the disclosing Party’s Confidential
          Information from disclosure in the same manner and to the same duty of care that the
          receiving Party uses to protect its own Confidential Information of like importance, but
          in no event less than a reasonable standard of care; and (d) return or destroy the
          disclosing Party’s Confidential Information promptly upon request of the disclosing Party
          at the completion of the Services, except any portion that is required to be retained by
          law.
        </p>

        <p>
          Disclosure of Confidential Information. In the event that the receiving Party is required
          to disclose any of the disclosing Party’s Confidential Information pursuant to an order,
          subpoena or Laws, the receiving Party shall if permitted by the applicable order, subpoena
          or law, (a) promptly notify the disclosing Party of the terms and the circumstances of any
          such request; (b) consult with the disclosing Party and cooperate with the disclosing
          Party’s reasonable requests to resist or narrow any such request; (c) furnish only
          Confidential Information that the receiving Party is legally compelled to disclose; and
          (d) use reasonable efforts to obtain an order or other reliable assurances that
          confidential treatment will be accorded the Confidential Information disclosed.
        </p>

        <p>
          Ownership of Confidential Information. As between the Parties, the Confidential
          Information, including permitted copies, will be deemed the property of the disclosing
          Party. No Intellectual Property right is licensed, granted, or otherwise transferred by
          the Agreement, except for the right to use or disclose such information in accordance with
          the Agreement. Receiving Party shall reproduce in full all confidentiality notices set
          forth on the Confidential Information.
        </p>

        <p>
          Equitable Relief. The Parties acknowledge that monetary damages may be inadequate to
          compensate the disclosing Party in the event that the receiving Party (or a Representative
          of the receiving Party) breaches any provision of this Section 4 and agree that, in
          addition to any remedies at law, the disclosing Party shall be entitled to seek equitable
          relief, including injunction and specific performance, without the need to prove actual
          damages or provide a bond.
        </p>

        <p>
          De-Identified Data Use. Para and its licensors may use certain content and transaction
          information to create information that is de-identified, such that it cannot be identified
          to the Customer, and which is sometime referred to as "big data" (hereinafter "Content").
          Content may be used and disclosed consistent with applicable law. Customer grants to Para
          and its licensors a non-exclusive right and license to use Content including the right of
          aggregation with data received from other customers, suppliers and users of Para services
          for the general provision of data aggregation and comparison services, statistical
          analysis, the creation and maintenance of comparative databases, product support and
          development, system analytics, and benchmarking analysis, so long as Para does not
          identify Customer or any individual person associated with Customer and the data remains
          in an aggregated form. To the extent that the Content has been de-identified, and
          notwithstanding anything set forth herein to the contrary in this Agreement, Para may
          retain such Content, provided that such Content shall be used or disclosed solely for such
          purposes provided in this Section.
        </p>
      </small>

      <h5>Term and Termination.</h5>
      <p>
        Term. The term of the Agreement will begin on the Effective Date and continue until the
        Agreement is otherwise earlier terminated pursuant these Standard Terms ("Term").
      </p>

      <p>
        Membership Term and Termination. Each Membership will commence as of the date Customer
        establishes an account on the Para Platform and continue until otherwise terminated pursuant
        to these Standard Terms (the "Membership Term").
      </p>

      <p>
        Termination. Either Party may terminate this Agreement upon written notice, effective
        immediately, if the other Party is in material breach of the terms of the Agreement which
        (a) is incapable of being cured or (b) if capable of being cured remains uncured for thirty
        (30) days after written notice of the breach. Para shall have the right to terminate the
        Agreement for any reason, or no reason, upon thirty (30) days prior written notice to
        Customer.
      </p>

      <p>
        Termination for Insolvency. Either Party may terminate this Agreement, immediately upon
        written notice to the other Party, if the other Party: (a) becomes insolvent or admits
        inability to pay its debts generally as they become due; (b) becomes subject, voluntarily or
        involuntarily, to any proceeding under any domestic or foreign bankruptcy or insolvency law,
        which is not fully stayed within seven (7) days or is not dismissed or vacated within
        forty-five (45) days after filing; (c) is dissolved or liquidated or takes any action for
        such purpose; (d) makes a general assignment for the benefit of creditors; or (e) has a
        receiver, trustee, custodian, or similar agent appointed by order of any court of competent
        jurisdiction.
      </p>

      <p>
        5.1. Effect of Termination. Upon termination of any Membership, for any reason other than an
        uncured breach by Para, (a) Customer shall promptly pay all outstanding Fees incurred for
        the Services; (b) Customer’s and its Authorized Users’ licenses to use the Services will
        immediately and automatically terminate; (c) Customer and its Authorized Users shall cease
        all access and use of the Para Platform and Services; and (d) Customer shall return to Para
        any Para Confidential Information, Intellectual Property or Documentation in Customer’s
        possession.
      </p>

      <h5>6. Fees and Payment.</h5>
      <p>
        Fees. Customer shall pay Para any Fees in the amount set forth on the Para Platform at the
        time Customer posts a Shift. Such Fees do not include any applicable taxes or duties,
        including without limitation, state and local use, sales and property taxes and duties.
        Customer is responsible for all taxes and duties incurred as a result of its Membership. The
        Fees that are posted on the Para Platform at the time the Customer posts a Shift are subject
        to change if the total hours of Healthcare Professional Services performed by an Independent
        Contractor is different than the total hours Customer has selected for a posted shift and/or
        additional third party services are required (e.g. drug screening or criminal background
        screening). Upon completion of a Shift and receipt of Customer’s payment, Para shall remit
        payment to each Independent Contractor for a completed Shift from the applicable Fees.
      </p>

      <h5>Time of Payment.</h5>

      <p>
        6.1.1. Customer shall have five (5) business days to accept a submitted electronic
        time-sheet reflecting the Healthcare Professional Services rendered for each Shift. At the
        expiration of the five (5) business day period, Customer authorizes Para to charge Customer
        the applicable Fees for Services rendered on Customer’s preferred payment method. "Business
        Day" means Monday through Friday, excluding weekends and any nationally recognized holiday.
      </p>

      <p>
        6.1.2. If Customer fails to pay any Fees when due, Para (a) will be entitled to charge
        interest on the amount unpaid from the due date up to the actual date of payment (both
        before and after judgment) at one and one-half percent (1.5%) per month or the maximum
        interest rate permitted by law, whichever is lower, (b) may suspend Customer’s access to and
        use of the Services, Para Platform, or Site, and (c) recover any costs of collection,
        including reasonable attorney’s fees.
      </p>

      <h5>7. Representations and Warranties.</h5>
      <p>
        Mutual Representations and Warranties. Each Party represents and warrants that it is duly
        organized and existing and is in good standing and qualified to do business under the laws
        of any jurisdiction where the ownership of assets or conduct of its business require it to
        be so qualified; and that it has full authority to enter into, and perform the obligations
        under this Agreement. Each Party further represents and warrants (a) that it has not been:
        (i) excluded, debarred, suspended or otherwise deemed ineligible to participate in federal
        health care programs or in federal procurement or nonprocurement programs; (ii) subject to
        or threatened with proceedings to be debarred; or (iii) indicted or convicted of a felony,
        or otherwise engaged in conduct for which a person or entity can be debarred under 21 U.S.C.
        §335a; and (b) it has and will maintain, to the extent required by applicable laws or
        regulations, all licenses, permits, and accreditations to perform the services contemplated
        under this Agreement. Each Party agrees to immediately notify the other Party if, following
        the Effective Date, any of the above statements is no longer true, in which case the other
        Party shall have the right to terminate this Agreement immediately upon written notice to
        the notifying Party.
      </p>

      <p>
        Limited Warranty. Para warrants that the Para Platform Services will in all material
        respects conform to, and will function in substantial conformity with, the applicable
        Documentation provided by Para to Customer; the Services and Documentation as provided by
        Para, do not infringe upon any United States copyright, patent, trademark, or trade secret.
      </p>

      <p>
        Pass-Through Warranties. To the extent Para is contractually permitted to do so, Para shall
        pass-through to Customer any warranties and other obligations of the original manufacturer
        or vendor of third party materials, accessories, supplies, parts, services and products or
        any software licensed by a third party, including the right to indemnification from claims
        related to those products or services.
      </p>

      <p>
        Disclaimer of Warranties. EXCEPT AS EXPRESSLY PROVIDED HEREIN, THE PARA PLATFORM, SERVICES
        AND SOFTWARE PROVIDED UNDER THIS AGREEMENT ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY
        KIND. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, PARA EXPRESSLY DISCLAIMS ALL OTHER
        WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR OTHERWISE, INCLUDING WITHOUT LIMITATION
        WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, TITLE, AND FITNESS FOR A PARTICULAR
        PURPOSE. PARA MAKES NO WARRANTY AND ASSUMES NO RESPONSIBILITY OR LIABILITY REGARDING ANY
        RELATIONAL DATABASE SERVICE OR SERVERS, OR ANY THIRD PARTY HARDWARE, EQUIPMENT OR SERVICE
        WITH WHICH SERVICES MAY BE REQUIRED TO COMMUNICATE OR OPERATE, AND DOES NOT WARRANT AND
        SPECIFICALLY DISCLAIMS THAT THE SERVICES WILL BE UNINTERRUPTED, ERROR-FREE, SUITABLE FOR
        CUSTOMER’S INTENDED USE, OR FREE OF MALICIOUS ELEMENTS OR THAT RESULTS WILL BE TIMELY,
        ACCURATE, OR COMPLETE. THE SERVICES AND SITE ARE NOT A BACKUP SERVICE FOR STORING CONTENT OR
        OTHER DATA, AND PARA SHALL HAVE NO LIABILITY REGARDING ANY LOSS OF DATA.
      </p>

      <h5>8. Indemnification and Limitations of Liability.</h5>
      <p>
        Limitation of Liability. TO THE EXTENT PERMITTED BY LAW, IN NO EVENT SHALL THE TOTAL
        LIABILITY OF EACH PARTY, TOGETHER WITH ALL OF ITS AFFILIATES, ARISING OUT OF OR RELATED TO
        THIS AGREEMENT, EXCEED IN THE AGGREGATE THE FEES PAID by Customer FOR the services IN THE
        TWELVE (12) MONTH PERIOD IMMEDIATELY prior to the month IN WHICH THE CLAIM AROSe.
        NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT, SERVICES SPECIFIC TERMS, OR ANY
        OTHER APPLICABLE TERMS, IN NO EVENT SHALL EITHER PARTY BE RESPONSIBLE TO THE OTHER FOR
        INCIDENTAL, INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING, BUT
        NOT LIMITED TO, LOST PROFITS, LOST REVENUE, OR DIMINUTION IN GOODWILL, of the other party,
        even IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </p>

      <p>
        Indemnification. Each Party shall indemnify, defend, and hold the other Party, its
        Affiliates, and respective successors, assigns, officers, directors, employees,
        sub-licensees, customers, and agents harmless and pay resulting costs (including damages and
        reasonable attorneys’ fees finally awarded), from and against any liability, loss, expense,
        or claim asserted by third parties (collectively, the "Claims" and individually each a
        "Claim") for (a) damage to, or destruction of, real or tangible personal property, or for
        bodily injury (including death) to persons, or both, to the extent such damage or injury is
        attributable to the gross negligence or willful misconduct of such indemnifying Party, or
        (b) its breach of the confidentiality obligations in Section 4. Customer shall indemnify,
        defend, and hold Para harmless its Affiliates, and respective successors, assigns, officers,
        directors, employees, sub-licensees, customers, and agents harmless and pay resulting costs
        (including damages and reasonable attorneys’ fees finally awarded), from and against any
        Claim arising out of any action or proceeding brought by a third party against Para (x) that
        Customer Data infringes, violates, trespasses, or constitutes the unauthorized use or
        misappropriation of any intellectual property of any third party or (y) alleging injury,
        damage, or loss resulting from Customer’s use of the Services.
      </p>

      <p>
        Notice and Participation. The Party requesting indemnification shall give the indemnifying
        Party prompt written notice of any such Claim and all necessary information and assistance
        so that such indemnifying Party, at its option, may defend or settle such claim; such
        indemnifying Party shall not take any adverse position in connection with such Claim. In the
        event that any such Claim is the result of, arises out of, or is in connection with the
        joint or concurrent negligence of both Parties as determined by a court of competent
        jurisdiction, the liability, loss, expense, or damage of such Claim shall be borne by each
        Party in proportion to its respective negligence or fault.
      </p>

      <h5>9. Insurance.</h5>
      <p>
        During the Term of this Agreement, Customer shall maintain, at its sole cost and expense,
        commercial general liability insurance (including coverages for contractual liability and
        personal injury liability) and professional liability insurance to insure against loss and
        damages arising out of activities or services, including Healthcare Professional Services,
        performed under this Agreement or any negligent acts or omissions by Customer or its
        employees or agents. Notwithstanding the foregoing, during an applicable Membership Term,
        Para shall obtain and maintain sufficient commercial general liability and professional
        liability insurance.
      </p>

      <h5>10. Miscellaneous.</h5>
      <p>
        Notices. All notices, requests, authorizations, consents, waivers, and other communications
        under this Agreement must be in writing and will be deemed to have been duly given on the
        date (a) personally delivered; (b) delivered, if sent by a nationally recognized overnight
        delivery service; or (c) sent via email, upon receipt of proper confirmation of sending,
        provided a copy is also sent by U.S. mail, in each case to the appropriate addresses set
        forth in the first paragraph of this Agreement, or to such other addresses as a Party may
        designate by notice to the other Party.
      </p>

      <p>
        Governing Law and Venue. This Agreement and all disputes directly or indirectly related
        thereto, shall be construed and interpreted in accordance with, and the rights of the
        Parties will be governed by, the Laws of the State of Texas, excluding its conflicts of laws
        principles. The Parties hereby irrevocably submit to the exclusive jurisdiction of the
        federal and state courts located in Dallas, Texas, U.S.A. and hereby agree that any such
        court shall be a proper forum for the determination of any controversy or dispute arising
        hereunder.
      </p>

      <p>
        No Agency. The relationship between the Parties created by this Agreement is that of
        independent contractors, and not joint-venturers, agents, or employees. Except as expressly
        provided herein, the relationship established between the Parties by this Agreement shall be
        non-exclusive.
      </p>

      <p>
        Non-Competition and Non-Solicitation. Customer shall not contract or engage any Independent
        Contractor that performed Healthcare Professional Services on an independent contractor
        basis without the use of the Para Platform for a period of 180 days from the last day of the
        Healthcare Professional Services rendered by the Independent Contractor.
      </p>

      <p>
        Assignment. Customer may not assign, in whole or in part, this Agreement or any of
        Customer’s obligations specified herein, nor delegate its duties hereunder to any other
        person without the prior written consent of Para. Any assignment by Customer in violation of
        this Section 9.5 shall be void. Para may assign its rights and delegate its duties under
        this Agreement at any time.
      </p>

      <p>
        Severability. If any provision of this Agreement, or the application of any such provision
        to any person, entity, or circumstance is declared judicially to be invalid, unenforceable,
        or void, such decision will not have the effect of invalidating, rendering unenforceable or
        voiding any portion of the remainder of this Agreement, it being the intent and agreement of
        the Parties hereto that this Agreement will be deemed amended by modifying such provision to
        the extent necessary to render it valid, legal, and enforceable, while preserving its intent
        or if such modification is not possible by substituting therefore another provision that is
        valid, legal, and enforceable and that achieves the same objective.
      </p>

      <p>
        Waiver; Remedies. No delay or failure by either Party to exercise or enforce at any time any
        right or provision of this Agreement shall be considered a waiver thereof or of such Party’s
        right thereafter to exercise or enforce each and every right and provision of this
        Agreement. A waiver to be valid shall be in writing and signed by the Party waiving a right
        or provision of this Agreement, but need not be supported by consideration. A valid waiver
        of any provision of this Agreement with respect to a particular situation or event shall not
        constitute a waiver of such provision with respect to other situations, events, or
        agreements. Except where expressly stated as an exclusive remedy, in addition to the
        remedies expressly available under this Agreement, the Parties shall have any and all
        additional remedies available to them, whether at law or in equity, and all remedies shall
        be cumulative. No action under this Agreement may be brought by either Party more than two
        (2) years after the cause of such action becomes known to such Party.
      </p>

      <p>
        Force Majeure. Except for payment of fees, neither Party shall be considered in default of
        this Agreement or liable for or held responsible, for any delays or failures in a Party’s
        performance of its obligations caused by fires, third party labor disputes or strikes,
        floods, embargoes, war, acts of terrorism or sabotage, quarantine restrictions,
        insurrection, riots, delays of carriers or suppliers, acts of God or by public enemy, or
        acts, omissions or other causes beyond a Party’s reasonable control, or without the fault or
        negligence of such Party, whether or not similar to the foregoing (each, a "Force Majeure
        Event"), provided that the non-performing Party notifies the other Party as soon as
        possible, but in no event more than five (5) calendar days following the occurrence of a
        Force Majeure Event, and the non-performing Party uses reasonable efforts to avoid or remove
        such causes of nonperformance and continues performance hereunder with reasonable promptness
        when such causes are removed.
      </p>

      <p>
        Export Restrictions. Each Party shall comply with all applicable export control Laws and
        regulations with respect to all information and the Services and Software provided under
        this Agreement. Each Party further provides its written assurance not to transfer, by
        electronic transmission or otherwise, any Confidential Information, Customer Data,
        information, software, or materials that are governed by or regulated under such laws to any
        person or a destination prohibited or restricted under such laws without first obtaining any
        required governmental documents, approvals, and authorization and taking any other actions
        required to comply with export control Laws.
      </p>

      <p>
        Survival. The terms in Sections 4-6, 8-10 will survive termination or expiration of this
        Agreement.
      </p>

      <p>
        <strong>Entire Agreement</strong>. This Agreement, including any Additional Third Party
        Terms contains the entire understanding of the Parties hereto with respect to the subject
        matter hereof and thereof. There are no agreements, understandings, arrangements, promises,
        covenants, or undertakings (oral or written) other than those expressly set forth herein and
        therein. This Agreement executed by the Parties supersede and replace in their entirety any
        and all other prior and contemporaneous agreements, arrangements, promises, covenants, or
        undertakings, whether oral, written, or implied, if any, between the Parties hereto with
        respect to the subject matter hereof or thereof.
      </p>
    </div>
  );
};

export default MembershipPage;
