import React, { useState, useEffect } from 'react';
import { OptionItemProps } from '../../../../components';
import { SelectableList } from '../../../../components/SelectableList';
import { ListFilter } from '../../components/ListFilter';
import { ShiftStatusList } from '../../../../constants';
import { useShiftsFilter } from '../../context/shiftContext';

export interface StatusFilterProps {
  open: boolean;
  anchorEl?: null | Element | ((element: Element) => Element);
  onClose: () => void;
}

export const StatusFilter = ({ open, anchorEl, onClose }: StatusFilterProps) => {
  const { filters, dispatch } = useShiftsFilter();
  const [value, setValue] = useState(filters.status);

  const handleApplyClick = () => {
    dispatch({ type: 'status', payload: value });
    onClose();
  };

  const handleReset = () => {
    dispatch({ type: 'status', payload: '' });
  };

  const handleItemClick = (item: Pick<OptionItemProps, 'label' | 'value'>) => {
    setValue(item.value);
  };

  useEffect(() => {
    setValue(filters.status);
  }, [filters.status]);

  return (
    <ListFilter
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      onApplyFilter={handleApplyClick}
      onResetClick={handleReset}
    >
      <SelectableList onItemClick={handleItemClick} options={ShiftStatusList} value={value} />
    </ListFilter>
  );
};
