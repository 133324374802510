import React, { useState } from 'react';
import { Container, Typography } from '@mui/material';
import { Alert, Loader } from '../../components';
import { useDepartments } from '../../services/useShifts';
import { NursingDepartment } from '../../types/schema';
import ApplicationLayout from '../../layout/ApplicationLayout';
import CreateShiftModal from './components/CreateShiftModal';
import DepartmentItem from './components/DepartmentItem';

const CreateShiftPage = () => {
  const [selectedDepartment, setSelectedDepartment] = useState<NursingDepartment | null>(null);

  const { isLoading, data, error } = useDepartments();
  const { data: departments } = data || { data: [] };

  const onDepartmentSelect = (department: NursingDepartment) => {
    setSelectedDepartment(department);
  };

  const handleCloseModal = () => {
    setSelectedDepartment(null);
  };

  return (
    <ApplicationLayout>
      <Container maxWidth="md" sx={{ p: 4 }}>
        <Typography variant="h4" color="primary">
          Create a Shift
        </Typography>
        <Typography sx={{ mb: 4 }} variant="h6">
          Choose a Department
        </Typography>
        {error && <Alert type="error">{error.message}</Alert>}
        {isLoading ? (
          <Loader />
        ) : (
          departments &&
          departments.map((department, index: number) => {
            return (
              <DepartmentItem
                key={index}
                department={department}
                onClick={() => onDepartmentSelect(department)}
              />
            );
          })
        )}

        {selectedDepartment && (
          <CreateShiftModal department={selectedDepartment} onClose={handleCloseModal} />
        )}
      </Container>
    </ApplicationLayout>
  );
};

export default CreateShiftPage;
