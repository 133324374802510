export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const SIGNUP_VERIFICATION = '/signup-verify';
export const SET_PASSWORD = '/set-password';
export const FORGOT_PASSWORD = '/forgot-password';
export const CHANGE_PASSWORD = '/change-password';
export const RESET_PASSWORD = '/reset-password';

export const CREATE_SHIFT = '/create-shift';
export const SHIFTS = '/shifts';
export const FACILITIES = '/facilities';
export const PROFILE = '/profile';

export const AGREEMENTS = '/onboarding/agreements';
export const COMPANY = '/onboarding/company';
