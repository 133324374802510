import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { SnackbarCloseReason } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import cn from 'classnames';
import { omit } from 'lodash-es';
import { colors } from '../../constants';
import { remCalc } from '../../utils';

const PREFIX = 'Toast';

const classes = {
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
  info: `${PREFIX}-info`,
  warning: `${PREFIX}-warning`,
  icon: `${PREFIX}-icon`,
  iconVariant: `${PREFIX}-iconVariant`,
  messageBox: `${PREFIX}-messageBox`,
  action: `${PREFIX}-action`,
  message: `${PREFIX}-message`,
};

const StyledSnackbar = styled(Snackbar)({
  [`& .${classes.success}`]: {
    backgroundColor: colors.success,
  },
  [`& .${classes.error}`]: {
    backgroundColor: colors.alert,
  },
  [`& .${classes.info}`]: {
    backgroundColor: colors.primary,
  },
  [`& .${classes.warning}`]: {
    backgroundColor: colors.warning,
  },
  [`& .${classes.icon}`]: {
    fontSize: 18,
  },
  [`& .${classes.iconVariant}`]: {
    opacity: 0.9,
    marginRight: remCalc(10),
  },
  [`& .${classes.messageBox}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.action}`]: {
    paddingLeft: 0,
  },
  [`& .${classes.message}`]: {
    width: `calc(100% - ${remCalc(70)})`,
  },
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

interface ToastProps {
  type: 'success' | 'warning' | 'error' | 'info';
  delay: number;
  open: boolean;
  message: ReactNode;
  onHide: () => void;
}

const Toast = (props: ToastProps) => {
  const { type, open, delay, message, onHide } = props;

  const Icon = variantIcon[type];

  const handleClose = (event: React.SyntheticEvent | Event, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    onHide();
  };

  return (
    <StyledSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={delay}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes[type]}
        classes={omit(classes, [
          'success',
          'info',
          'error',
          'warning',
          'icon',
          'messageBox',
          'iconVariant',
        ])}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.messageBox}>
            <Icon className={cn(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" size="large">
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </StyledSnackbar>
  );
};

export default Toast;
