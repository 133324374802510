import React from 'react';
import { NavLink } from 'react-router-dom';
import ProfileLayout from '../../../layout/ProfileLayout';
import { PROFILE } from '../../../constants/routes';

const PAGES = [
  {
    url: 'terms',
    label: 'Terms and Conditions',
  },
  {
    url: 'policy',
    label: 'Privacy Policy',
  },
  {
    url: 'membership',
    label: 'Membership Agreement',
  },
];

const ProfileLegalPage = () => {
  return (
    <ProfileLayout activeMenu="legal">
      {PAGES.map((page) => (
        <NavLink key={page.url} to={`${PROFILE}/legal/${page.url}`}>
          {page.label}
        </NavLink>
      ))}
    </ProfileLayout>
  );
};

export default ProfileLegalPage;
