import React from 'react';

const PolicyPage = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1 className="text-center mb-30">Para’s Privacy Policy</h1>

      <h4>PLEASE READ THIS PRIVACY POLICY CAREFULLY.</h4>
      <p>
        This Privacy Policy (<strong>"Privacy Policy"</strong>) sets forth our policies and
        practices regarding the collection, use, transfer, and disclosure of certain information
        about you ("you," "user" or "users") arising out of your use of or access to the Para
        Services, Inc. ("<strong>Para,</strong>" "<strong>we,</strong>" "<strong>us,</strong>" and "
        <strong>our</strong>") website located at www.joinpara.com ("<strong>Website</strong>"), the
        Para mobile application ("Para App"), and other Para provided services, products, websites,
        and applications that link to or display this Privacy Policy (collectively, the "
        <strong>Para Platform</strong>").
      </p>

      <p>
        <strong>
          The terms and definitions of Para’s Terms of Service ("Terms of Service") found here are
          incorporated into this Agreement. By accessing and using the Para Platform, you agree to
          the terms of this Privacy Policy and our Terms of Service. If you do not agree to the
          Privacy Policy and Terms of Service, please immediately stop accessing the Para Platform.
        </strong>
      </p>

      <h4>INFORMATION PARA MAY COLLECT</h4>

      <p>
        Para has the ability to collect personally identifiable information ("<strong>PII</strong>")
        and non-personally identifiable information that does not identify you ("
        <strong>Aggregated Information</strong>"). PII that Para may collect includes information
        that you may provide to us that personally identifies you such as your name, address, phone
        number, date of birth, social security number, or driver’s license number, government
        identification numbers, banking information, and any other information that is linked or
        linkable to you, such as health, educational, or employment information including current
        and past employment information, references, professional licenses, accreditations, and
        certifications. Para may also collect Aggregated Information such as device-specific
        information, the media access control address, browser type, unique device identifiers,
        operating system, internet protocol (IP) address, internet service provider, geolocation,
        number of Users on the Para Platform, and information related to traffic patterns on the
        Para Platform.
      </p>

      <h4>HOW PARA MAY COLLECT AND USE INFORMATION</h4>

      <h5>Information You Provide</h5>

      <p>
        Para collects information provided when you: create, use, or update your Para account and
        this may include PII; consent to a background check and/or drug screening; request services
        through the Para Platform; complete surveys sent by Para; allow Para to access your calendar
        or address book; and contact Para, including for customer support purposes.
      </p>

      <h5>Device, Log, and Location Information</h5>

      <p>
        Para may collect information about the device you use to access our services including
        hardware models/names, operating systems and versions, software, mobile network information,
        acceleration/motion data, and unique device identifiers.
      </p>

      <p>
        Para collects server logs when you access our services and this may include access dates and
        times, your device IP address, browser used, Para Platform features or pages accessed, crash
        reports/bug information, general system activity, and services used prior to accessing the
        Para Platform.
      </p>

      <p>
        Para may collect, depending on your Para App settings or device permissions, your location
        information determined through data generated by GPS, WiFi, IP address, and/or similar
        technologies. This information is collected when the Para App is running, open and either on
        your screen or in the background of your device. Users may use the Para App without allowing
        Para to collect this information although the functionality of the Para App will be
        affected. For example, as a Medical Professional who disables location gathering
        permissions, the Para App will not inform you of available Shifts in your immediate area.
        This information may be used for dispute resolute and/or customer support.
      </p>

      <h5>Transaction, Use, and Preference Information</h5>

      <p>
        Para collects transaction information related to your use of our services including Shift
        characteristics, the dates and times that services were requested and rendered. We collect
        information related to your use of the Para Platform including your selection of settings
        and preferences and how you use the Para Platform.
      </p>

      <h5>Calls and Text Messages</h5>

      <p>
        Through the Para App, Para allows users to call or text other users without disclosing
        telephone numbers. Para collects information related to these communications including the
        content of text messages and dates and times of text messages and calls. This information
        may be used for dispute resolution and/or customer support.
      </p>

      <h5>Calendar and Address</h5>

      <p>
        If you grant the Para App access to your address book and calendar, Para may collect names,
        contact information, events and event descriptions, dates, times, locations, and attendees
        to promote social events and possible scheduling of our services.
      </p>

      <h5>Other Sources</h5>

      <p>
        Para may collect information from other sources including users providing ratings or
        comments, payment providers, social media services, insurance providers, financial service
        providers, marketing services providers, and publicly available sources.
      </p>

      <p>Para collects information for the following purposes.</p>

      <h5>The Para PlatformThe Para Platform</h5>

      <p>
        Para uses information it collects to provide, maintain, and improve the Para Platform. This
        includes the ability to create, revise/update, accept, complete, and report Shifts; provide
        feedback for completed Shifts, facilitate payments for Shifts, share profile information,
        personalize your account and to provide and maintain operations including performing data
        analytics/research, fix software bugs and features, monitor usage of the Para Platform. Para
        may use information it collects for product improvement or development.
      </p>

      <h5>Communications and Customer Support</h5>

      <p>
        Para may use the information it collects to provide a means of communication between users.
        For example, when a Medical Professional is trying to determine logistical related
        information, such as where to park or check-in, by contacting a Healthcare Facility user.
        Para may use information it collects to inform you about products, services, news, events,
        promotions, and contests such as raffles or giveaways. Para may further use information it
        collects to inform you about relevant content, services, and/or products of our business
        partners, and elections, ballots, agendas, and political processes that relate to our
        services.
      </p>

      <p>
        Para may use information it collects to improve customer service and support and to address
        concerns and problems reported by you.
      </p>

      <h4>INFORMATION SHARING</h4>

      <h5>With Other Users</h5>

      <p>
        Para may share the information we collect with other uses. For example, if you are a
        Healthcare Provider, Medical Professionals will be able to see your name, facility
        information, and posted Shifts. As another example, if you are a Medical Professional, a
        Healthcare Provider may be able to see your name, profile picture, your ratings and reviews
        from other Healthcare Providers, driver’s license for identification, health, educational,
        or employment information including current and past employment information, references,
        professional licenses, accreditations, and certifications.
      </p>

      <h5>With Parent Accounts</h5>

      <p>
        If you are a Healthcare Provider, Para may share your account information and Para Platform
        activity with your organization’s parent account or your supervisor’s account.
      </p>

      <h5>With Your Consent</h5>

      <p>
        Para may share your information for other purposes described in this Privacy Policy provided
        we have notified you and have your consent.
      </p>

      <h5>Social Media and Public Communications</h5>

      <p>Communications on public forums, social media, and blogs may be viewable by the public.</p>

      <h5>Service Providers and Business Partners</h5>

      <p>
        Para may provide information to its service providers and business partners such as vendors,
        marketing partners, research and analytics firms, screening companies, and payment service
        providers.
      </p>

      <h5>Law Enforcement and Dispute Resolution</h5>

      <p>
        If Para believes it is required by law, regulation, agreement, legal process, or government
        request, Para may share your information.
      </p>

      <h4>COOKIES</h4>

      <p>
        Cookies are data that is stored in your web browser or device by applications and websites.
        Para uses cookies and related technologies to authenticate users, remember settings and
        preferences, and determine analytics of the Para Platform such as traffic, use, and content
        effectiveness. Third parties may provide Para analytic services using cookies and related
        technologies.
      </p>

      <h4>SECURITY OF YOUR INFORMATION</h4>

      <p>
        We use reasonable organizational, technical, and administrative measures and safeguards to
        protect your PII.
      </p>

      <h5>LINKS AND THIRD PARTY SITES</h5>

      <p>
        Checkr, Inc. https://checkr.com/privacy-policy/ <br />
        Stripe https://stripe.com/us/privacy/ <br />
        Google https://policies.google.com/privacy?hl=en
      </p>

      <h4>NOTICE ABOUT CHILDREN’S PRIVACY</h4>
      <p>
        The Para Platform is not intended for children under the 13 years of age, and we do not
        accept registration from anyone under the age of 18 years old. If you are under the age of
        13, please DO NOT USE the Para Platform. No personally identifiable information (as defined
        in the Children’s Online Privacy Protection Act of 1998 15 U.S.C. § 6501 ("
        <strong>COPPA</strong>")) is purposefully or knowingly collected from children under the age
        of 13 by the Para Platform. If you are a parent or guardian of a child under 13 years of
        age, and believe that he or she disclosed information to us, please contact us at the
        address below. If we learn that we have collected or received PII of persons under the age
        of 13 without verification of parental consent, we will exercise commercially reasonable
        efforts to delete such information.
      </p>

      <h4>CALIFORNIA RESIDENTS</h4>

      <p>
        If you are a California resident, California Civil Code Section 1798.83 permits you to
        request and obtain from Para once a year, free of charge, a list of all third parties to
        which the company has disclosed certain personally identifiable information as defined under
        California law for such third parties’ direct marketing purposes in the preceding calendar
        year. If you are a California resident and would like to make such request, please contact
        us at the address below.
      </p>

      <p>
        The California Online Privacy Protection Act ("<strong>CalOPPA</strong>") requires Para to
        disclose how it responds to "Do Not Track" signals. Para does not interpret or respond to
        "Do Not Track" signals. You may set your browser settings to reject or disable cookies or
        web beacons. Please not that without enabling these features, your experience of the Para
        Platform may be hindered and you will not be able to take full advantage of the Para
        Platform features. Please see the Help section of your browser for instructions on managing
        security preferences.
      </p>

      <h4>MODIFICATIONS</h4>

      <p>
        Para reserves the right to update or modify this Privacy Policy at any time. We encourage
        you to revisit this page frequently to stay aware of any changes. This Privacy Policy was
        last updated on the date listed below. If Para makes any material changes to this Privacy
        Policy, we will post a prominent notice on the Para Platform or send you an email at your
        last known email address. Your continued use of the Para Platform constitutes your agreement
        to this Privacy Policy.
      </p>

      <h4>CONTACT US</h4>

      <p>
        Should you have any questions or issues related to this Privacy Policy or the Para Platform,
        please contact us:
      </p>

      <address className="mb-20">
        Para Services, Inc. <br />
        admin@joinpara.com <br />
        (630) 605-7431
      </address>
    </div>
  );
};

export default PolicyPage;
