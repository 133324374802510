import React, { ReactNode } from 'react';
import { Box, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { PROFILE } from '../../constants/routes';
import ApplicationLayout from '../../layout/ApplicationLayout';

const PROFILE_MENUS = [
  // {
  //   id: 'user',
  //   title: 'User Profile',
  //   url: PROFILE + '/user',
  // },
  // {
  //   id: 'company',
  //   title: 'Company Info',
  //   url: PROFILE + '/company',
  // },
  // {
  //   id: 'requirements',
  //   title: 'Company Requirements',
  //   url: PROFILE + '/company/requirements',
  // },
  // {
  //   id: 'crib-sheet',
  //   title: 'Company Crib Sheet',
  //   url: PROFILE + '/company/crib-sheet',
  // },
  {
    id: 'users',
    title: 'Users and Access',
    url: PROFILE + '/users',
  },
  {
    id: 'legal',
    title: 'Legal',
    url: PROFILE + '/legal',
  },
];

interface ProfileLayoutProps {
  children?: ReactNode;
  activeMenu?: string;
}

const ProfileLayout = (props: ProfileLayoutProps) => {
  const { activeMenu, children } = props;
  return (
    <ApplicationLayout>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          width: '100%',
        }}
      >
        <List
          sx={{
            width: '240px',
            minWidth: '240px',
            backgroundColor: '#e5e5e5',
            mt: 2,
            mr: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {PROFILE_MENUS.map((menu) => (
            <ListItem disablePadding key={menu.id}>
              <ListItemButton component="a" href={menu.url} selected={activeMenu === menu.id}>
                <ListItemText primary={menu.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box sx={{ p: 2, display: 'flex', flexGrow: 1, flexDirection: 'column' }}>{children}</Box>
      </Box>
    </ApplicationLayout>
  );
};

export default ProfileLayout;
