import * as React from 'react';
import { Button, Popover, PopoverProps, Grid } from '@mui/material';
import { OptionItemProps } from '../../../../components';
import styles from './ListFilter.module.scss';

export interface ListFilterProps extends Pick<PopoverProps, 'open' | 'anchorEl' | 'onClose'> {
  onItemClick?: (item: Pick<OptionItemProps, 'label' | 'value'>) => void;
  onResetClick?: () => void;
  onApplyFilter?: () => void;
  children?: React.ReactNode;
}

export const ListFilter = ({
  open,
  anchorEl,
  onClose,
  onResetClick,
  onApplyFilter,
  children,
}: ListFilterProps) => {
  return (
    <Popover
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorEl={anchorEl}
      onClose={onClose}
      classes={{
        paper: styles.container,
      }}
    >
      {children}
      <Grid
        className={styles.buttonGroup}
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Button size="small" onClick={onResetClick} className={styles.btnReset} variant="text">
            Reset filter
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={onApplyFilter} size="small" variant="contained">
            Apply filter
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};
