export const phoneRequired = (val: string) => {
  return /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/g.test(val) //eslint-disable-line no-useless-escape
    ? undefined
    : 'Wrong phone number';
};

export const countryCodeRequired = (val: string) => {
  return /^(\+?\d{1,3}|\d{1,4})$/g.test(val) //eslint-disable-line no-useless-escape
    ? undefined
    : 'Invalid code';
};

export const validateEmail = (email: string) => {
  return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    email,
  );
};

export const validatePassword = (password: string) => {
  // Minimum 8 characters at least 1 Uppercase Alphabet, 1 Lowercase Alphabet and 1 Number
  return /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!@#$%^&*()]*$/.test(password);
};
