import * as React from 'react';
import cn from 'classnames';
import { ShiftStatusType, DetailModalStatusType } from '../../constants/shiftFilterValues';
import styles from './ShiftStatus.module.scss';

export interface ShiftStatus {
  value: ShiftStatusType;
  name?: string;
}

export const LabelStatus = ({ name, value }: { name?: string; value: DetailModalStatusType }) => {
  return (
    <div
      className={cn(
        styles.labelStatus,
        value === 'NEEDS_REVIEW' && styles.labelNeedReview,
        value === 'REVIEWED' && styles.labelReviewed,
        value === 'PENDING_MATCHING' && styles.labelCompleted,
        value === 'COMPLETED' && styles.labelCompleted,
      )}
    >
      {name}
    </div>
  );
};
