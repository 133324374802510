import React from 'react';
import { useFormik } from 'formik';
import { Button } from '@mui/material';
// import { useSnackbar } from 'notistack';
import { Input, Form, FormActions, Icon } from '../../../../components';

const ProfileUserForm = () => {
  // const onDrop = (files: any) => {
  //   console.log('Drop images', files);
  //   // this.props.changeImage(files[0]);
  // };

  // const handleAccountRequest = async (values, successCallback, verify) => {
  // return this.props
  // .checkPhoneExist(values.phone)
  // .then(() => this.props.showError('Phone already exist'))
  // .catch(() =>
  // return this.props.createUser(values).then(() => {
  //   verify();
  //   successCallback();
  // });
  // );
  // };
  // const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      title: '',
      phone: '',
      birthDate: new Date(),
      imageUrl: '',
    },
    validate: (values) => {
      // TODO validation
      const errors = {} as object;

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      // setSubmitting(true);
      // try {
      //   setSubmitting(false);
      //   enqueueSnackbar('Information saved successfully', {
      //     variant: 'success',
      //   });
      // } catch (e: unknown) {
      //   setSubmitting(false);
      //   enqueueSnackbar(e.message ? e.message : e.code ? e.code : e.status, {
      //     variant: 'error',
      //   });
      // }
    },
  });

  const { handleSubmit, values, isSubmitting } = formik;

  return (
    <Form onSubmit={handleSubmit}>
      {/* <Dropzone onDrop={this.onDrop}> */}
      <div className="">
        {values.imageUrl !== '' && (
          <div className="user-image" style={{ backgroundImage: `url(${values.imageUrl})` }} />
        )}
        {values.imageUrl === '' && <Icon size={48} name="user" />}
      </div>
      {/* </Dropzone> */}
      <Input required name="firstName" label="First Name" />
      <Input name="middleName" label="Middle Name" />
      <Input
        required
        name="lastName"
        label="Last Name"
        // validate={[required, validator.max255]}
        // component={FormInput}
      />
      <Input name="title" label="Title" />
      <Input required name="phone" label="Phone" />
      {/* <DatePicker
        label="Date of Birth"
        value={values.birthDate}
        onChange={handleDateChange}
        // validate={required}
      /> */}
      <FormActions>
        <Button type="submit" disabled={isSubmitting} variant="contained">
          Save
        </Button>
      </FormActions>
    </Form>
  );
};

export default ProfileUserForm;
