import React, { ReactNode } from 'react';

import styles from './AuthenticationLayout.module.scss';

interface AuthenticationLayoutProps {
  title: string;
  subtitle: string;
  form: ReactNode;
}

const AuthenticationLayout = (props: AuthenticationLayoutProps) => {
  const { title, subtitle, form } = props;
  return (
    <div className={styles.container}>
      <aside
        className={styles.leftSide}
        style={{ backgroundImage: 'url(/images/login/banner.png)' }}
      >
        <div>
          <img src="/images/logo/logo.svg" alt="logo" />
        </div>
      </aside>
      <aside className={styles.rightSide}>
        <div className={styles.formContainer}>
          <header className={styles.header}>
            <h1>{title}</h1>
            <h4>{subtitle}</h4>
          </header>
          <section>{form}</section>
        </div>
      </aside>
    </div>
  );
};

export default AuthenticationLayout;
