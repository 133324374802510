import React from 'react';

interface HeaderProps {
  className?: string;
}

const Header = (props: HeaderProps) => (
  <header className={props.className}>
    <div>
      <img src="/images/logo/logo-main.svg" alt="" />
    </div>
  </header>
);

export default Header;
