import React from 'react';

// import { required, comparePasswords, validateEmail } from '@/utils';

import AuthenticationLayout from '../../../layout/AuthenticationLayout';
import ChangePasswordForm from './components/ChangePasswordForm';

const ChangePasswordPage = () => {
  // const [isSending] = useState(false);

  // const urlParams = new URLSearchParams(window.location.search);
  // const email = urlParams.get('email');
  // const token = urlParams.get('token');

  // changePassword({
  //   data: {
  //     email_address: email,
  //     token,
  //     password,
  //   },
  // })
  //   .then(() => history.push(LOGIN))
  //   .catch((err) => {
  //     if (err.status === 410) showError(err.message);
  //     else showError(err.data[0].detail);
  //   });

  return (
    <AuthenticationLayout
      title="Welcome to Para!"
      subtitle="Please change your account password"
      form={<ChangePasswordForm />}
    />
  );
};

export default ChangePasswordPage;
