import React from 'react';
import AuthenticationLayout from '../../../layout/AuthenticationLayout';
import ResetPasswordForm from './components/ResetPasswordForm';

const ResetPasswordPage = () => {
  return (
    <AuthenticationLayout
      title="Reset password?"
      subtitle="Enter your new password."
      form={<ResetPasswordForm />}
    />
  );
};

export default ResetPasswordPage;
