import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { ListItem, ListItemText } from '@mui/material';
import styles from './OptionItem.module.scss';

export interface OptionItemProps {
  label?: string;
  value?: string;
  onItemClick?: (arg: Pick<OptionItemProps, 'label' | 'value'>) => void;
  selected?: boolean;
  disabled?: boolean;
}

const OptionItem = (props: OptionItemProps) => {
  const { label, value, selected = false, disabled = false, onItemClick } = props;
  const handleItemClick = () => {
    onItemClick && onItemClick({ label, value });
  };

  return (
    <ListItem selected={selected} button onClick={handleItemClick} disabled={disabled}>
      <ListItemText>{label}</ListItemText>
      {selected && <CheckIcon className={styles.optionIcon} />}
    </ListItem>
  );
};

export default OptionItem;
