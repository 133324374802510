import React, { useState } from 'react';
import { Button, Typography, Box, Card, CardContent } from '@mui/material';
import { useSnackbar } from 'notistack';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Modal, Input, Loader, Form } from '../../../components';
import { useOrganizationUsers } from '../../../services/useShifts';
import ProfileLayout from '../../../layout/ProfileLayout';
import { useInviteUser } from '../../../services/useOrganization';

const ProfileCompanyUsersPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showInviteModal, setShowInviteModal] = useState(false);

  const organizationId = localStorage.getItem('_o') || '';
  const { data: { data: users } = { data: [] }, isLoading } = useOrganizationUsers(organizationId);

  const [mutate] = useInviteUser({
    onSuccess: () => {
      enqueueSnackbar('Invite was successfully sent', {
        variant: 'success',
      });
    },
    onFailure({ error }) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const inviteUser = () => {
    setShowInviteModal(true);
  };

  const handleCloseModal = () => {
    setShowInviteModal(false);
  };

  const sendInviteUser = () => {
    const params = {
      account: {
        email_address: 'anton+12@joinpara.com',
      },
    };
    mutate(params);
    handleCloseModal();
  };

  return (
    <ProfileLayout activeMenu="users">
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          mb: 4,
        }}
      >
        <Typography variant="h6">Users</Typography>
        <Button onClick={inviteUser} variant="contained">
          Invite user
        </Button>
      </Box>

      <Box>
        {users && users.length === 0 && <Typography variant="body1">No users found...</Typography>}
        {users &&
          users.length > 0 &&
          users.map((user) => {
            const parsedPhoneNumber = parsePhoneNumber(
              `+${user.account.phone_number.country_code} ${user.account.phone_number.phone_number}`,
            );
            const phoneInternational = parsedPhoneNumber.formatInternational();
            return (
              <Card>
                <CardContent>
                  <Typography key={user.organization_personnel_id} variant="h6">
                    {user.last_name} {user.first_name}
                  </Typography>
                  <Typography key={user.organization_personnel_id} variant="subtitle2">
                    {user.account.email_address}
                  </Typography>
                  <Typography key={user.organization_personnel_id} variant="subtitle2">
                    {phoneInternational}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        {isLoading && <Loader />}
      </Box>
      <Modal
        title={'Invite user'}
        open={showInviteModal}
        onRequestClose={handleCloseModal}
        actions={
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button color="secondary" onClick={handleCloseModal} variant="text">
              Cancel
            </Button>
            <Button color="primary" onClick={sendInviteUser} variant="contained">
              Invite
            </Button>
          </Box>
        }
      >
        <Form>
          <Input
            required
            name="email"
            // value={state.email}
            // onChange={handleEmailChange}
            label="Email"
          />
        </Form>
      </Modal>
    </ProfileLayout>
  );
};

export default ProfileCompanyUsersPage;
