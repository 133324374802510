import React from 'react';
import AuthenticationLayout from '../../../layout/AuthenticationLayout';
import SetPasswordForm from './components/SetPasswordForm';

const SetPassword = () => {
  return (
    <AuthenticationLayout
      title="Welcome to Para!"
      subtitle="Please set your account password"
      form={<SetPasswordForm />}
    />
  );
};

export default SetPassword;
