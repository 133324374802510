export default [
  75050, 75060, 75051, 75061, 76155, 75062, 76011, 75211, 76006, 75212, 76010, 75038, 75247, 75236,
  76040, 76005, 75039, 76039, 76019, 75233, 76014, 75052, 75208, 75220, 75116, 75235, 76012, 75249,
  75260, 76022, 75207, 76018, 75224, 75063, 76021, 75209, 76015, 75219, 75237, 75342, 75242, 75398,
  76013, 75229, 75202, 76053, 75270, 75137, 75275, 75277, 75283, 75284, 75285, 75301, 75303, 75312,
  75320, 75326, 75368, 75373, 75389, 75390, 75391, 75392, 75393, 75394, 75395, 75397, 75201, 76120,
  75203, 75234, 75204, 76051, 76034, 75205, 75232, 75099, 76002, 76118, 75226, 76054, 75246, 75019,
  75225, 75104, 75054, 75216, 76017, 75215, 76016, 75358, 75206, 75006, 76112, 75244, 75115, 76001,
  75223, 75230, 75214, 76180, 75210, 76182, 76092, 75001, 75241, 75251, 76117, 75231, 75240, 76060,
  75254, 76103, 76148, 76105, 75007, 76063, 76119, 75067, 75134, 75248, 75218, 75227, 75243, 75287,
  75028, 75238, 76111, 76137, 75228, 75010, 75217, 76248, 76190, 75057, 76244, 75022, 75252, 76104,
  76140, 76166, 76130, 76150, 76191, 76192, 76193, 76195, 76196, 76197, 76198, 76199, 75080, 75081,
  76102, 75146, 75042, 75141, 75093, 76110, 75154, 75150, 76262, 76115, 75041, 75056, 75149, 76164,
  75077, 76177, 76106, 76122, 76134, 75075, 76129, 75180, 75172, 76131, 76065, 76107, 75253, 75065,
  75024, 75044, 76109, 76114, 75040, 75082, 75043, 76084, 75023, 76133, 75181, 76028, 75182, 76226,
  76123, 76127, 75074, 76132, 76210, 75034, 75025, 75088, 76009, 76179, 75094, 76052, 75048, 75068,
  75125, 76116, 75089, 75159, 75167, 76135, 76036, 76247, 75013, 75033, 75035, 76205, 75152, 76208,
  75002, 76108, 75098, 75126, 76201, 76207, 75070, 76064, 76209, 76058, 76071, 76059, 75087, 76126,
  75165, 75032, 76227, 76259, 75114, 76031, 75078, 76050, 76020, 75166, 76078, 75069, 76041, 76008,
  75407, 75119, 75173, 76249, 75071, 76044, 75160, 76023, 75158, 75009, 76085, 76035, 75189, 76258,
  76651, 76670, 76266, 75454, 76055, 76082, 75142, 76093, 76636, 76086, 75442, 76234, 75409, 76033,
  75161, 76049, 76271, 76073, 76087, 76272, 75058, 75135, 75143, 75105, 75424, 75155, 76626, 76627,
  75495, 76645, 76233, 76238, 76666, 75102, 76070, 76641, 75474, 76088, 76048, 76487, 76426, 76077,
  76631, 75402, 75110, 76263, 75147, 75459, 76225, 75401, 75490, 76652, 75423, 75169, 75153, 76660,
  76066, 75491, 76476, 76043, 76431, 75092, 76671, 76692, 76250, 76490, 75090, 76621, 76462, 75156,
  75452, 75453, 76679, 76239, 76676, 75472, 75109, 76639, 76270, 76240, 75117, 76648, 75144, 75496,
  76622, 75422, 76486, 75103, 75414, 76067, 76252, 76690, 75124, 75163, 76264, 76273, 75479, 76691,
  76681, 75439, 76665, 76673, 75428, 75418, 76472, 75127, 73459, 75440, 76230, 75148, 75021, 75020,
  75449, 76245, 76635, 75076, 76265, 76251, 76634, 76433, 75433, 75140, 75859, 76640, 76484, 76693,
  76624, 76458, 75420, 75438, 76649, 76678, 75751, 75447, 76449, 75790, 75448, 76457, 74733, 76633,
  75492, 75752, 76453, 74731, 76705, 75476, 75410, 74741, 75778, 76401, 76708, 73439, 73440, 75754,
  76667, 75469, 76402, 73448, 76427, 76637, 76704, 76664, 73430, 75840, 73441, 75415, 74730, 76707,
  76701, 75497, 76797, 76799, 76798, 75446, 76689, 76255, 73446, 76710, 76475, 76463, 76712, 76711,
  73453, 73449, 76459, 75861, 75756, 75773, 76642, 75482, 75477, 75860, 76638, 76706, 76261, 75470,
  75770, 75783, 75488, 75471, 75758, 75853, 76682, 74701, 76643, 76228, 75450, 76460, 75704, 75880,
  75884, 75886, 75432, 75431, 75437, 73569, 75771, 73561, 74726, 75421, 76655, 76657, 76436, 76445,
  73443, 75763, 76685, 76538, 75478, 75709, 73456, 76446, 76687, 73447, 76531, 76561, 76470, 73565,
  75706, 73463, 76630, 75838, 76365,
].map((code) => code.toString()); // should be string as we have it as a string in redux-form
