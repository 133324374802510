import React from 'react';
import Paper from '@mui/material/Paper';
import { Address } from '../../../types/schema';
import styles from './FacilityListItem.module.scss';
import { Stack } from '@mui/material';

interface FacilityListItemProps {
  facility_name?: string;
  address?: Address;
  onClick?: () => void;
}

const FacilityListItem = (props: FacilityListItemProps) => {
  const { facility_name, address, onClick } = props;
  const handleClick = () => {
    onClick?.();
  };
  return (
    <Paper className={styles.container} onClick={handleClick}>
      <Stack>
        <div className={styles.name}>{facility_name}</div>
        <div className={styles.secondary}>
          {address?.street_number} {address?.route}, {address?.locality},{' '}
          {address?.administrative_area_level_1} {address?.country}, {address?.postal_code}
        </div>
      </Stack>
    </Paper>
  );
};

export default FacilityListItem;
