import React, { useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { Dropdown } from '../../../../components';
import { useDepartments, useLicenses } from '../../../../services/useShifts';
import { useFacilities } from '../../../../services/useOrganization';
import { Facility, License, NursingDepartment } from '../../../../types/schema';
import InputField from '../InputField';

const BasicForm = () => {
  const [region, setRegion] = useState<string | undefined>();
  const { data: { data: departments } = { data: [] } } = useDepartments();
  const organizationId = localStorage.getItem('_o') || '';
  const { data: { data: facilities } = { data: [] } } = useFacilities(organizationId);
  const { data: { data: licenses } = { data: [] } } = useLicenses(region);

  const departmentOptions = useMemo(() => departmentSelector(departments), [departments]);
  const facilityOptions = useMemo(() => facilitySelector(facilities), [facilities]);
  const licenseOptions = useMemo(() => licenseSelector(licenses), [licenses]);

  const handleFacilityChange = useCallback(
    (value: string | number | readonly string[]) => {
      if (facilities) {
        const facility = facilities.find((f) => value === f.facility_id);
        console.log('Facility', facility);
        setRegion(facility?.address.region_id);
      }
    },
    [facilities],
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <h1 className="text-normal">1. Basics</h1>
      <Dropdown name="department" label="Department" options={departmentOptions} />

      <Dropdown
        name="facility"
        label="Location"
        options={facilityOptions}
        onValueChange={handleFacilityChange}
      />

      <Dropdown name="license" label="License" options={licenseOptions} />
      <InputField name="jobTitle" label="Job title" />
    </Box>
  );
};

const licenseSelector = (licenses: License[] = []) => {
  return licenses
    .sort((a, b) => a.us_nursing_license_type.localeCompare(b.us_nursing_license_type))
    .map((license) => {
      const Label = (
        <>
          {license.us_nursing_license_type}{' '}
          {/* {license.us_nursing_license_type_region?.iso_3166_2_code} */}
        </>
      );
      return {
        label: Label,
        value: license.us_nursing_license_type_id,
      };
    });
};

const departmentSelector = (departments: NursingDepartment[] = []) => {
  return departments.map((department) => {
    return {
      label: department.nursing_department,
      value: department.nursing_department_id,
    };
  });
};

const facilitySelector = (facilities: Facility[] = []) => {
  return facilities.map((facility) => {
    return {
      label: facility.facility_name,
      value: facility.facility_id,
    };
  });
};

export default BasicForm;
