import React from 'react';
import { Paper, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import cn from 'classnames';
import { NursingDepartment } from '../../../../types/schema';
import DepartmentIcon from '../DepartmentIcon';
import styles from './DepartmentItem.module.scss';

interface DepartmentItemProps {
  department: NursingDepartment;
  onClick?: () => void;
  size?: 'md' | 'sm';
  selected?: boolean;
  selectable?: boolean;
}

const DepartmentItem = (props: DepartmentItemProps) => {
  const { department, onClick, size = 'md', selected = false, selectable = false } = props;
  return (
    <Paper className={cn(styles.item, styles[size])} onClick={onClick}>
      <div className={styles.iconLeft}>
        <div className={styles.iconLeftContainer}>
          <DepartmentIcon department={department} />
        </div>

        <Typography variant={size === 'md' ? 'h5' : 'h6'} color="primary">
          {department.nursing_department}
        </Typography>
      </div>
      {!selectable && <ChevronRightIcon />}
      {selectable && selected && <DoneIcon />}
    </Paper>
  );
};

export default DepartmentItem;
