export const CERTIFICATES_LIST = [
  { id: 'us_bls_certificate', label: 'Basic Life Support (BLS)', route: 'BLS' },
  {
    id: 'us_acls_certificate',
    label: 'Advanced Cardiovascular Life Support (ACLS)',
    route: 'ACLS',
  },
  {
    id: 'us_pals_certificate',
    label: 'Pediatric Advanced Life Support (PALS)',
    route: 'PALS',
  },
  { id: 'us_nrp_certificate', label: 'Neonatal Resuscitation Program (NRP)', route: 'NRP' },
  {
    id: 'us_awhonn_certificate',
    label: "Associaction of Women's Health, Obstetric and Neonatal Nurses (AWHONN)",
    route: 'AWHONN',
  },
  { id: 'us_tncc_certificate', label: 'Trauma Nursing Core Course (TNCC)', route: 'TNCC' },
  {
    id: 'us_nihss_certificate',
    label: 'NIH Stroke Scale International (NIHSSI)',
    route: 'NIHSS',
  },
  {
    id: 'us_enpc_certificate',
    label: 'Emergency Nursing Pediatric Course (ENPC)',
    route: 'ENPC',
  },
];
