import axios from 'axios';
import useMutation, { Options as MutationOptions } from 'use-mutation';
import { useQuickSwr } from './useQuickSwr';
import { GET, buildEndpointURL, POST } from '../services/services';
import {
  BaseResponse,
  Document,
  DemandDocument,
  IpAddress,
  AcceptAgreementPayload,
} from '../types/schema';

export const useDocuments = (accountId?: string) => {
  const url = '/legal/documents/pending-for-user';
  const key = buildEndpointURL('aftale', url);
  return useQuickSwr<BaseResponse<Document[]>>([key, accountId], (url, id) =>
    GET(url, { user_id: id }),
  );
};

export const useDemandAgreements = () => {
  const url = '/legal/documents/latest';
  const key = buildEndpointURL('aftale', url);
  return useQuickSwr<BaseResponse<DemandDocument[]>>(key, GET);
};

export const useIpAddress = () => {
  const key = 'https://api.ipify.org/?format=json';
  const fetcher = (url: string) => axios.get(url).then((respone) => respone.data);
  return useQuickSwr<IpAddress>(key, fetcher);
};

export const useAcceptAgreements = (
  options: MutationOptions<AcceptAgreementPayload, BaseResponse<AcceptAgreementPayload>, Error>,
) => {
  const url = '/legal/documents/accept';
  const key = buildEndpointURL('aftale', url);
  const acceptAgreements = (params: AcceptAgreementPayload) => POST(key, { data: { ...params } });
  return useMutation(acceptAgreements, options);
};
