import * as React from 'react';
import { SWRConfig } from 'swr';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { theme } from './constants';
import Routes from './routes';

const appTheme = createTheme(theme);

const swrConfig = {
  revalidateOnFocus: false,
};

const App = () => (
  <StyledEngineProvider injectFirst={true}>
    <ThemeProvider theme={appTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SWRConfig value={swrConfig}>
          <CssBaseline>
            <SnackbarProvider maxSnack={3}>
              <Routes />
            </SnackbarProvider>
          </CssBaseline>
        </SWRConfig>
      </LocalizationProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;
