import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { FieldArray } from 'formik';
import { Dropdown } from '../../../../../components';
import { useOrganizationUsers } from '../../../../../services/useShifts';
import {
  Facility,
  FacilityDeskNumber,
  FacilityPolicy,
  OrganizationUser,
} from '../../../../../types/schema';
import { useFacilities } from '../../../../../services/useOrganization';
import InputField from '../../InputField';
// import { OrganizationUser } from '@/types/schema';
// import { useOrganizationUsers } from '@/services/useShifts';

// import styles from './CribSheetForm.module.scss';

// const cribSheetFormInitial = {
//   primary_contact_person: {
//     demand_user_id: null,
//   },
//   facility_desk_numbers: [],
//   facility_policies: [],
//   logistic_information: { parking: '', entrance: '' },
// };

export interface CribSheetFormProps {
  facility?: string;
  setFieldValue: (field: string, value?: string | FacilityDeskNumber[] | FacilityPolicy[]) => void;
}

export const CribSheetForm = (props: CribSheetFormProps) => {
  const { facility, setFieldValue } = props;

  const [selectedFacility, setSelectedFacility] = useState<Facility | null>(null);
  const organizationId = localStorage.getItem('_o') || '';
  const { data: { data: facilities } = { data: [] } } = useFacilities(organizationId);
  const { data: { data: organizationUsers } = { data: [] } } = useOrganizationUsers(organizationId);
  const users = useMemo(() => organizationUsersSelector(organizationUsers), [organizationUsers]);

  useEffect(() => {
    if (facility && facilities && facilities.length > 0) {
      const selectedFacility = facilities.find((f) => f.facility_id === facility);
      if (selectedFacility) {
        setSelectedFacility(selectedFacility);
        setFieldValue('facility_desk_numbers', selectedFacility.crib_sheet.facility_desk_numbers);
        setFieldValue('facility_policies', selectedFacility.crib_sheet.facility_policies);
        setFieldValue('parking', selectedFacility.crib_sheet.logistic_information?.parking);
        setFieldValue('entrance', selectedFacility.crib_sheet.logistic_information?.entrance);
      }
    }
  }, [facility, facilities, setFieldValue]);

  const renderFacilityDeskNumbersForm = () => {
    return (
      selectedFacility && (
        <div>
          <h4>Facility Desk Numbers</h4>
          <FieldArray
            name="facility_desk_numbers"
            render={(arrayHelpers) => (
              <Box>
                {selectedFacility?.crib_sheet.facility_desk_numbers.map(
                  (facilityDeskNumber: FacilityDeskNumber, index: number) => (
                    <Box key={index}>
                      <InputField
                        required
                        name={`facility_desk_numbers.${index}.description`}
                        label="Description"
                        // validate={required}
                      />
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <InputField
                            required
                            name={`facility_desk_numbers.${index}.contact_info.phone_number.country_code`}
                            label="Code"
                            // validate={countryCodeRequired}
                            // maxLength={4}
                          />
                        </Grid>
                        <Grid item xs>
                          <InputField
                            required
                            name={`facility_desk_numbers.${index}.contact_info.phone_number.phone_number`}
                            label="Phone number"
                            // validate={phoneRequired}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  ),
                )}
              </Box>
            )}
          />
        </div>
      )
    );
  };

  const renderFacilityPoliciesForm = () => {
    return (
      selectedFacility && (
        <div>
          <h4>Facility Policies</h4>
          <FieldArray
            name="facility_policies"
            render={(arrayHelpers) => (
              <Box>
                {selectedFacility.crib_sheet.facility_policies.map(
                  (facilityPolicy: FacilityPolicy, index: number) => (
                    <Box sx={{ textAlign: 'right' }} key={index}>
                      <InputField
                        required
                        name={`facility_policies.${index}.policy_type`}
                        label="Policy Type"
                        // validate={required}
                      />
                      <InputField
                        required
                        name={`facility_policies.${index}.policy_description`}
                        label="Policy Description"
                        // validate={required}
                      />
                    </Box>
                  ),
                )}
              </Box>
            )}
          />
        </div>
      )
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <h1 className="text-normal">2. Crib-Sheet</h1>
      <Dropdown name="primaryContactPerson" label="Point of contact" options={users} />
      {renderFacilityDeskNumbersForm()}
      {renderFacilityPoliciesForm()}
      <InputField name="parking" label="Parking" />
      <InputField name="entrance" label="Entrance" />
    </Box>
  );
};

const organizationUsersSelector = (organizationUsers: OrganizationUser[] = []) => {
  return organizationUsers
    .filter((u) => u.organization_personnel_id)
    .map((user) => {
      return {
        label: `${user.first_name} ${user.last_name}`,
        value: user.organization_personnel_id,
      };
    });
};

export default CribSheetForm;
