import { useQuickSwr } from './useQuickSwr';
import useMutation, { Options as MutationOptions } from 'use-mutation';
import { GET, buildEndpointURL, POST } from './services';
import {
  BaseResponse,
  Facility,
  OrganizationDetails,
  Region,
  UserInvitePayload,
} from '../types/schema';
import { OrganizationDetailsPayload } from '../types';

export const useOrganizationDetails = (accountId: string) => {
  const url = '/demand/organization/for-account';
  const key = buildEndpointURL('podnik', url);
  return useQuickSwr<BaseResponse<OrganizationDetails>>([key, accountId], (url, id) =>
    GET(url, { account_id: id }),
  );
};

export const useEditOrganizationDetails = (
  options: MutationOptions<
    OrganizationDetailsPayload,
    BaseResponse<OrganizationDetailsPayload>,
    Error
  >,
) => {
  const url = '/signup/add-info';
  const key = buildEndpointURL('podnik', url);
  const editOrganizationApi = (info: OrganizationDetailsPayload) =>
    POST(key, { data: { ...info } });
  return useMutation(editOrganizationApi, options);
};

export const useFacilities = (organizationId?: string) => {
  const url = '/demand/facilities';
  const key = buildEndpointURL('podnik', url);
  return useQuickSwr<BaseResponse<Facility[]>, Error>([key, organizationId], (url, id) => {
    return new Promise<BaseResponse<Facility[]>>((resolve, reject) => {
      return GET<BaseResponse<Facility[]>>(url, { organization_id: id })
        .then((response) => {
          if (response?.data) {
            response.data = response.data.sort((a, b) => {
              if (a.facility_name > b.facility_name) {
                return 1;
              }
              return -1;
            });
            resolve(response);
          }
        })
        .catch((e) => reject(e));
    });
  });
};

export const useFacility = (organizationId: string, facilityId: string) => {
  const url = '/demand/facility';
  const key = facilityId !== 'new' ? buildEndpointURL('podnik', url) : null;
  return useQuickSwr<BaseResponse<Facility>, Error>(
    [key, organizationId, facilityId],
    (url, id, facilityId) => {
      if (facilityId) {
        return GET(url, { organization_id: id, facility_id: facilityId });
      } else
        return new Promise((resolve) => {
          return resolve({});
        });
    },
  );
};

export const useRegions = () => {
  const url = '/world-regions/country-regions?country_iso_3166_1_alpha_2_code=US';
  const key = buildEndpointURL('tapatybe', url);
  return useQuickSwr<BaseResponse<Region[]>>(key, GET);
};

export const useCreateFacility = (
  options: MutationOptions<Partial<Facility>, BaseResponse<Facility>, Error>,
) => {
  const url = '/demand/facility';
  const key = buildEndpointURL('podnik', url);
  const createFacilityApi = (facility: Partial<Facility>) => POST(key, { data: { ...facility } });
  return useMutation(createFacilityApi, options);
};

export const useInviteUser = (
  options: MutationOptions<UserInvitePayload, BaseResponse<UserInvitePayload>, Error>,
) => {
  const url = '/demand/organization/account/invitation/send-invite';
  const key = buildEndpointURL('podnik', url);
  const inviteUserApi = (user: UserInvitePayload) => POST(key, { data: { ...user } });
  return useMutation(inviteUserApi, options);
};
