import axios from 'axios';

const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (typeof error.response === 'undefined') {
      console.log('Network issue', error);
      alert(
        'A network error occurred. ' +
          'This could be a CORS issue or a dropped internet connection. ' +
          'It is not possible for us to know.',
      );

      // localStorage.removeItem('_t');
      // localStorage.removeItem('_i');
      // localStorage.removeItem('_o');
      // localStorage.removeItem('_p');
      // window.location.pathname = '/';
    }

    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      localStorage.removeItem('_t');
      localStorage.removeItem('_i');
      localStorage.removeItem('_a');
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

const defaultHeaders = () => {
  const token = localStorage.getItem('_t');
  const header = {
    'Content-Type': 'application/json',
    Authorization: '',
  };

  if (token) {
    header['Authorization'] = `Bearer ${token}`;
  }
  return header;
};

export const GET = <T>(url = '', params = {}): Promise<T> => {
  return axios
    .get<T>(url, {
      params,
      headers: defaultHeaders(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      // if (err.response && err.response.status === 404) {
      //   return [];
      // } else {
      if (err.response) {
        const e = {
          ...err.response.data,
          status: err.response.status,
        };
        throw e;
      } else {
        const e = {
          ...err,
          message: err.message,
        };
        throw e;
      }
      // }
    });
};

export const POST = (url = '', data: object = {}) => {
  return axios
    .post(url, data, {
      headers: defaultHeaders(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err && err.response) {
        const e = {
          ...err.response.data,
          status: err.response.status,
        };
        throw e;
      } else {
        throw err;
      }
    });
};

export const PATCH = (url = '', data = {}) => {
  return axios
    .patch(url, data, {
      headers: defaultHeaders(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const e = {
        ...err.response.data,
        status: err.response.status,
      };
      throw e;
    });
};

export const PUT = (url = '', data = {}) => {
  return axios
    .put(url, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const e = {
        ...err.response.data,
        status: err.response.status,
      };
      throw e;
    });
};

export type BackendError = {
  code: string;
  message: string;
  status: number;
};

export const buildEndpointURL = (service: string, url: string) =>
  `${process.env.REACT_APP_API_URL}/${service}/api/v1${url}`;
