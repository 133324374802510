import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { Form, FormActions, Input } from '../../../../../components';
import {
  FORGOT_PASSWORD,
  // LOGIN
} from '../../../../../constants/routes';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { getErrorMessage, validateEmail } from '../../../../../utils';
import { resetPassword } from '../../../../../services/authentication.service';

const ResetPasswordForm = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const search = window.location.search;
  const urlParams = new URLSearchParams(search);

  const formik = useFormik({
    initialValues: {
      email: urlParams.get('email'),
      token: urlParams.get('token'),
      password: '',
      confirmPassword: '',
    },
    validate: (values) => {
      const errors = {} as {
        email: string;
        token: string;
        password: string;
        confirmPassword: string;
      };
      if (!values.email) {
        errors.email = 'Required';
      } else if (!validateEmail(values.email)) {
        errors.email = 'Invalid email address';
      } else if (!values.password) {
        errors.password = 'Please fill new password';
      } else if (!values.confirmPassword) {
        errors.confirmPassword = 'Please confirm new password';
      } else if (values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Please check password confirmation';
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (values.email && values.token && values.password) {
        setSubmitting(true);
        try {
          await resetPassword(values.email, values.token, values.password);
          setSubmitting(false);
          enqueueSnackbar('Password reset successfully', {
            variant: 'success',
          });
          history.push('/');
        } catch (error) {
          setSubmitting(false);
          enqueueSnackbar(getErrorMessage(error) || 'Authentication error', {
            variant: 'error',
          });
        }
      }
    },
  });

  const { handleSubmit, values, handleChange, touched, errors, isSubmitting } = formik;
  return (
    <Form onSubmit={handleSubmit}>
      <Input
        id="email"
        name="email"
        type="email"
        label="Email"
        value={values.email}
        onChange={handleChange}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
        disabled
      />
      <Input
        id="password"
        name="password"
        type="password"
        label="New Password"
        value={values.password}
        onChange={handleChange}
        error={touched.password && Boolean(errors.password)}
        helperText={touched.password && errors.password}
      />
      <Input
        id="confirmPassword"
        name="confirmPassword"
        type="password"
        label="Confirm Password"
        value={values.confirmPassword}
        onChange={handleChange}
        error={touched.confirmPassword && Boolean(errors.confirmPassword)}
        helperText={touched.confirmPassword && errors.confirmPassword}
      />
      <FormActions>
        <Button type="submit" variant="contained" disabled={isSubmitting}>
          Reset my password
        </Button>
        <Button onClick={() => history.push(FORGOT_PASSWORD)} variant="text">
          Back
        </Button>
      </FormActions>
    </Form>
  );
};

export default ResetPasswordForm;
