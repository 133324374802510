import React from 'react';
import { Box } from '@mui/material';
import { orderBy } from 'lodash-es';
import { Checkbox, Loader, RadioButton } from '../../../../components';
import { useVaccines, useEmrs, useScreenings } from '../../../../services/useShifts';
import { experienceYears } from '../../constants';
import { CERTIFICATES_LIST } from '../../../../constants/certifications';

export const AdditionalForm = () => {
  const { data: { data: vaccines } = { data: [] }, isLoading: isLoadingVaccines } = useVaccines();
  const { data: { data: screenings } = { data: [] }, isLoading: isLoadingScreenings } =
    useScreenings();
  const { data: { data: erms } = { data: [] }, isLoading: isLoadingErms } = useEmrs();

  if (isLoadingVaccines || isLoadingErms || isLoadingScreenings) return <Loader />;

  return (
    <Box>
      <h1 className="text-normal">4. Details</h1>

      <div className="mb-10">
        <h4>Additional Certifications Required</h4>
        {CERTIFICATES_LIST.map((cert, index) => (
          <Checkbox key={index} name={'certifications.' + cert.id} label={cert.label} />
        ))}
      </div>

      <div className="devider mb-15" />

      <div className="mb-10">
        <h4>Vaccinations Required</h4>
        {(orderBy(vaccines, 'vaccine') ?? []).map((vaccine) => (
          <Checkbox
            key={vaccine.vaccine_id}
            name={`vaccinations.${vaccine.vaccine_id}`}
            label={vaccine.vaccine}
          />
        ))}
      </div>

      <div className="devider mb-15" />

      <div className="mb-10">
        <h4>Minimum Years of Experience</h4>
        <RadioButton name="experience" options={experienceYears} />
      </div>

      <div className="mb-10">
        <h4>Medical screenings</h4>
        {(orderBy(screenings, 'medical_screening_type') ?? []).map((screening) => (
          <Checkbox
            key={screening.medical_screening_type_id}
            name={`screenings.${screening.medical_screening_type_id}`}
            label={screening.medical_screening_type}
          />
        ))}
      </div>

      <div className="mb-10">
        <h4>EMRs</h4>
        {(orderBy(erms, 'emr') ?? []).map((emr) => (
          <Checkbox key={emr.emr_id} name={`emrs.${emr.emr_id}`} label={emr.emr} />
        ))}
      </div>
    </Box>
  );
};

export default AdditionalForm;
