import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow as MuiTableRow,
  TableContainer,
  // TablePagination,
} from '@mui/material';
import { Loader } from '../../../../components';
// import { TableHead } from './TableHead';
import { TableRow } from './TableRow';
import styles from './ShiftsTable.module.scss';
import { useShifts } from '../../../../services/useShifts';
import { DepartmentFilterPopup } from '../DepartmentFilter';
import { LocationFilterPopup } from '../LocationFilter';
import { StatusFilter } from '../StatusFilter';
import { DateFilter } from '../DateFilter';
import ShiftDetailModal from '../../components/ShiftDetailModal';
import { MatchedProfessionalModal } from '../../components/MatchedProfessionalModal';
import { ReviewTimesheetModal } from '../../components/ReviewTimesheetModal';
import { useShiftsFilter } from '../../context/shiftContext';
import { JobShift } from '../../../../types/schema';
import { TableHeaderCell } from './TableHead/TableHeaderCell';

export interface Column {
  id:
    | 'shift'
    | 'location'
    | 'department'
    | 'medicalProfessional'
    | 'matched'
    | 'timesheet'
    | 'status';
  hasFilter: boolean;
  label: string;
  FilterComponent?: React.ElementType<{
    open: boolean;
    anchorEl: Element | null;
    onClose: () => void;
  }>;
}

const generateColumns = (isCompleted: boolean): Column[] => {
  return [
    { id: 'shift', label: 'Shift (CST)', hasFilter: true, FilterComponent: DateFilter },
    {
      id: 'location',
      label: 'Location',
      hasFilter: true,
      FilterComponent: LocationFilterPopup,
    },
    isCompleted
      ? { id: 'medicalProfessional', label: 'MEDICAL PROFESSIONAL', hasFilter: false }
      : {
          id: 'department',
          label: 'Department',
          hasFilter: true,
          FilterComponent: DepartmentFilterPopup,
        },
    isCompleted
      ? { id: 'timesheet', label: 'TIMESHEET', hasFilter: false }
      : { id: 'matched', label: 'Matched', hasFilter: false },
    { id: 'status', label: 'Status', hasFilter: true, FilterComponent: StatusFilter },
  ];
};

export function ShiftsTable() {
  // const [page, setPage] = React.useState(0);
  const [detailVisible, setDetailVisible] = React.useState(false);
  const [professionalModalVisible, setProfessionalModalVisible] = React.useState(false);
  const [timesheetModalVisible, setTimesheetModalVisible] = React.useState(false);
  const [selectedShift, setSelectedShift] = React.useState<JobShift>();
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { filters } = useShiftsFilter();
  const isCompleted = filters.viewType === 'COMPLETED_SHIFTS';
  const { data, isLoading } = useShifts(filters);

  // const handleChangePage = (_: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const handleClick = (shift: JobShift) => {
    setSelectedShift(shift);
    setDetailVisible(true);
  };

  const [selectedShiftDetails, setSelectedShiftDetails] = useState<{
    jobId?: string;
    usNursingJobId?: string;
    shiftId?: string;
    supplyId?: string;
  }>({});
  const handleOpenProfessionalModal = (
    jobId: string,
    usNursingJobId: string,
    shiftId: string,
    supplyId: string,
  ) => {
    // setSelectedShift(shift);
    setSelectedShiftDetails({ jobId, usNursingJobId, shiftId, supplyId });
    setProfessionalModalVisible(true);
  };

  const handleOpenReviewTimesheetModal = (shift: JobShift) => {
    setSelectedShift(shift);
    setTimesheetModalVisible(true);
  };

  const handleClose = () => {
    setSelectedShift(undefined);
    setDetailVisible(false);
    setTimesheetModalVisible(false);
    setProfessionalModalVisible(false);
  };

  const handleCloseMedicalProfessional = () => {
    setSelectedShiftDetails({});
    setProfessionalModalVisible(false);
  };

  const shifts = data?.data ?? [];
  return (
    <div className={styles.container}>
      <TableContainer className={styles.tableContainer}>
        <Table aria-label="sticky table" className={styles.table}>
          <TableHead>
            <MuiTableRow>
              {generateColumns(isCompleted).map((column) => (
                <TableHeaderCell key={column.id} {...column} />
              ))}
            </MuiTableRow>
          </TableHead>
          {isLoading ? (
            <tbody>
              <tr>
                <td colSpan={5}>
                  <Loader />
                </td>
              </tr>
            </tbody>
          ) : shifts && shifts.length > 0 ? (
            <TableBody>
              {(shifts ?? []).map((shift, index) => {
                return (
                  <TableRow
                    key={`${shift.job_id}-${index}`}
                    shift={shift}
                    onItemClick={handleClick}
                    onOpenProfessionalModal={handleOpenProfessionalModal}
                    onOpenReviewTimesheetModal={handleOpenReviewTimesheetModal}
                    isCompleted={isCompleted}
                    columns={generateColumns(isCompleted)}
                  />
                );
              })}
            </TableBody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={5}>
                  <div className={styles.empty}>No Data</div>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        count={shifts.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        component="div"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      {selectedShift && detailVisible && (
        <ShiftDetailModal
          jobId={selectedShift.job_id}
          usNursingJobId={selectedShift.us_nursing_job_id}
          shiftId={selectedShift.jobs_shift_info.jobs_shift?.shift_id}
          open={detailVisible}
          onClose={handleClose}
          onOpenProfessionalModal={handleOpenProfessionalModal}
        />
      )}
      {selectedShiftDetails &&
        selectedShiftDetails.jobId &&
        selectedShiftDetails.usNursingJobId &&
        selectedShiftDetails.shiftId &&
        selectedShiftDetails.supplyId &&
        professionalModalVisible && (
          <MatchedProfessionalModal
            jobId={selectedShiftDetails.jobId}
            usNursingJobId={selectedShiftDetails.usNursingJobId}
            shiftId={selectedShiftDetails.shiftId}
            supplyId={selectedShiftDetails.supplyId}
            open={professionalModalVisible}
            onClose={handleCloseMedicalProfessional}
          />
        )}
      {selectedShift && timesheetModalVisible && (
        <ReviewTimesheetModal
          shift={selectedShift}
          open={timesheetModalVisible}
          onClose={handleClose}
        />
      )}
    </div>
  );
}
