import React from 'react';
import cn from 'classnames';
import styles from './RadioButton.module.scss';
import { FieldHookConfig, useField } from 'formik';

type RadioButtonsProps = {
  options: { value: string; label: string; disabled?: boolean }[];
  onChange?: (value: string) => void;
  name: string;
  value?: string;
};

const RadioButtons = (props: RadioButtonsProps & FieldHookConfig<string>) => {
  const { name, options } = props;
  const [field] = useField(props);

  // const isError = meta.touched && meta.error && true;
  // const handleSelect = (value: string) => {
  //   onChange?.(value);
  // };

  return (
    <div>
      {options.map((element, index) => {
        return (
          <label
            key={index}
            className={cn(styles['dp-radio-button'], {
              [styles['dp-radio-button-disabled']]: element.disabled,
            })}
          >
            <div>
              <input
                type="radio"
                className={styles['dp-radio-button-input']}
                // onChange={() => handleSelect(element.value)}
                // checked={element.value === value}
                {...field}
                value={element.value}
                name={name}
              />
            </div>
            <div>
              <span className={styles['dp-radio-button-label']}>{element.label}</span>
            </div>
          </label>
        );
      })}
    </div>
  );
};

export default RadioButtons;
