import { useMemo } from 'react';
import dayjs from 'dayjs';
import { ShiftStatusState } from '../types/schema';
import { ShiftsFilterOptions } from '../pages/Shifts/context/shiftContext';
import { GetShiftsApiParams } from '../services/useShifts';
import { colors, isValidPeriod, isValidShiftStatus, isValidViewType } from '../constants';

export const getQueryParamsFromState = (state: ShiftsFilterOptions): string => {
  const params = new URLSearchParams();
  if (state.period) {
    params.append('period', state.period);
  }
  if (state.startDate && state.endDate) {
    params.append('startDate', dayjs(state.startDate).format());
    params.append('endDate', dayjs(state.endDate).format());
  }
  if (state.facilityId) {
    params.append('facilityId', state.facilityId);
  }
  if (state.departmentId) {
    params.append('departmentId', state.departmentId);
  }
  if (state.status) {
    params.append('status', state.status);
  }
  if (state.viewType) {
    params.append('viewType', state.viewType);
  }
  return params.toString();
};

export const getApiParamsFromState = (state?: ShiftsFilterOptions): GetShiftsApiParams => {
  const params: GetShiftsApiParams = {};
  if (state?.viewType === 'UPCOMING_SHIFTS') {
    // for upcoming
    params.startDate = new Date();
    params.endDate = dayjs().add(1, 'month').toDate();
  } else {
    // for completed
    params.startDate = dayjs().subtract(2, 'week').toDate();
    params.endDate = dayjs().subtract(1, 'minute').toDate();
  }

  if (state?.period) {
    const dateRange = getDateRangeByPeriod(state.period);
    params.startDate = dateRange.startDate;
    params.endDate = dateRange.endDate;
  }

  if (state?.startDate && state.endDate) {
    params.startDate = state.startDate;
    params.endDate = state.endDate;
  }

  if (state?.departmentId) {
    params.departmentId = state.departmentId;
  }

  if (state?.facilityId) {
    params.facilityId = state.facilityId;
  }

  if (state?.status) {
    params.status = state.status;
  }

  return params;
};

export const getDateRangeByPeriod = (period: string): { startDate?: Date; endDate?: Date } => {
  let startDate, endDate;
  switch (period) {
    case 'TODAY':
      startDate = dayjs().startOf('day').toDate();
      endDate = dayjs().endOf('day').toDate();
      break;
    case 'THIS_WEEK':
      startDate = dayjs().startOf('week').toDate();
      endDate = dayjs().endOf('week').toDate();
      break;
    case 'THIS_MONTH':
      startDate = dayjs().startOf('month').toDate();
      endDate = dayjs().endOf('month').toDate();
      break;
    case 'NEXT_MONTH':
      startDate = dayjs().add(1, 'months').startOf('month').toDate();
      endDate = dayjs().add(1, 'months').endOf('month').toDate();
      break;
    default:
      return {};
  }

  return { startDate, endDate };
};

export const getShiftStateStatusDetail = (
  status: ShiftStatusState,
): { name: string; color: string } => {
  const result = { name: '', color: '' };
  switch (status) {
    case 'shift.shift_state_posted':
      result.name = 'POSTED';
      result.color = '#A9B3BE';
      break;
    case 'shift.shift_state_upcoming':
      result.name = 'UPCOMING';
      result.color = '#A9B3BE';
      break;
    case 'shift.shift_state_ongoing':
      result.name = 'ONGOING';
      result.color = '#4a4a4a';
      break;
    case 'shift.shift_state_cancelled':
      result.name = 'CANCELLED';
      result.color = colors.alert;
      break;
    case 'shift.shift_state_not_matched':
      result.name = 'NOT MATCHED';
      result.color = '#A9B3BE';
      break;
    case 'shift.shift_state_accepted':
      result.name = 'ACCEPTED';
      result.color = '#17C122';
      break;
    case 'shift.shift_state_pending_review':
      result.name = 'PENDING REVIEW';
      result.color = '#f98231';
      break;
    case 'shift.shift_state_pending_time_in':
      result.name = 'PENDING TIME IN';
      result.color = '#f98231';
      break;
    case 'shift.shift_state_pending_time_out':
      result.name = 'PENDING TIME OUT';
      result.color = '#f98231';
      break;
    case 'shift.shift_state_time_sheet_confirmed':
      result.name = 'CONFIRMED';
      result.color = '#17C122';
      break;
    case 'shift.shift_state_ended':
      result.name = 'ENDED';
      result.color = '#17C122';
      break;
    default:
      result.name = status;
      result.color = '#17C122';
  }
  return result;
};

export const useShiftsFilterFromQuery = (queryParams: URLSearchParams): ShiftsFilterOptions => {
  const filters = useMemo(() => {
    if (!queryParams) return {};

    const result: ShiftsFilterOptions = {};
    const period = queryParams.get('period');
    if (period && isValidPeriod(period)) {
      result.period = period;
    }
    const startDate = queryParams.get('startDate');
    const endDate = queryParams.get('endDate');
    if (startDate && endDate && dayjs(startDate).isValid() && dayjs(endDate).isValid()) {
      result.startDate = dayjs(startDate).toDate();
      result.endDate = dayjs(endDate).toDate();
    }

    const departmentId = queryParams.get('departmentId');
    if (departmentId) {
      result.departmentId = departmentId;
    }

    const facilityId = queryParams.get('facilityId');
    if (facilityId) {
      result.facilityId = facilityId;
    }

    const status = queryParams.get('status');
    if (status && isValidShiftStatus(status)) {
      result.status = status;
    }

    const viewType = queryParams.get('viewType');
    if (viewType && isValidViewType(viewType)) {
      result.viewType = viewType;
    }

    return result;
  }, [queryParams]);

  return filters;
};
