import React from 'react';
import cn from 'classnames';
import { remove } from 'lodash-es';
import { FormHelperText, Grid } from '@mui/material';
import { DAYS_OF_THE_WEEK } from '../../../constants/datetime';
import Label from '../../Label';
import { useField } from 'formik';

interface DayPickerProps {
  label: string;
  name?: string;
}

const DayPicker = (props: DayPickerProps) => {
  const { name, label } = props;

  // eslint-disable-next-line
  const [field, meta, helpers] = useField(name || 'days');

  const { value, error } = meta;
  const { setValue } = helpers;

  const handleSelect = (day: number) => {
    const val = value ? [...value] : [];
    value.includes(day) ? remove(val, (d) => d === day) : val.push(day);
    setValue(val);
  };

  const hasError = error && error !== '';

  return (
    <div>
      {label && (
        <div className="mb-10">
          <Label error={!!error}>{label}</Label>
        </div>
      )}
      <Grid container>
        {DAYS_OF_THE_WEEK.map((day) => (
          <Grid
            item
            md
            xs
            key={day.index}
            onClick={() => handleSelect(day.index)}
            className={cn(
              'pointer rounded inline-block bordered color-gray days text-center dp-day-circle',
            )}
            sx={{
              borderRadius: '20px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px 0',
              backgroundColor: value?.includes(day.index) ? '#1963b0' : 'inherit',
              color: value?.includes(day.index) ? '#fff' : 'inherit',
            }}
          >
            {day.label}
          </Grid>
        ))}
      </Grid>

      {hasError && <FormHelperText error>{error || 'Select some option'}</FormHelperText>}
    </div>
  );
};

export default DayPicker;
