import React, { ReactNode } from 'react';
import cn from 'classnames';
import styles from './Label.module.scss';

type LabelProps = {
  error: boolean;
  children: ReactNode | string;
};

const Label = (props: LabelProps) => {
  const { children, error } = props;
  return <div className={cn(styles.label, { [styles.labelError]: !!error })}>{children}</div>;
};

export default Label;
