import React from 'react';
import cn from 'classnames';
import styles from './Loader.module.scss';

type LoaderProps = {
  fullPage?: boolean;
  size?: number;
  type?: 'circle' | 'linear';
  style?: object;
};

const Loader = (props: LoaderProps) => {
  const { type = 'circle', fullPage = false, size = 40, style = {} } = props;

  switch (type) {
    case 'circle':
      return (
        <div
          className={cn(styles['dp-spinner'], 'fade-in', { [styles['full-page']]: fullPage })}
          style={style}
        >
          <div className={styles['circle']}>
            <svg
              className="spinner"
              width={size + 'px'}
              height={size + 'px'}
              viewBox="0 0 66 66"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className={styles['path']}
                fill="none"
                strokeWidth="5"
                strokeLinecap="round"
                cx="33"
                cy="33"
                r="30"
              />
            </svg>
          </div>
        </div>
      );
    case 'linear':
      return (
        <div className={styles['dp-linear-progress']}>
          <div className={cn(styles.bar, styles.bar1)} />
          <div className={cn(styles.bar, styles.bar2)} />
        </div>
      );
    default:
      return (
        <div className={styles['dp-linear-progress']}>
          <div className={cn(styles.bar, styles.bar1)} />
          <div className={cn(styles.bar, styles.bar2)} />
        </div>
      );
  }
};

export default Loader;
