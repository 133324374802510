import React, { useState, useEffect, ChangeEvent } from 'react';
import dayjs from 'dayjs';
import { Button, Grid, Divider, Popover, PopoverProps, Typography } from '@mui/material';
import styles from './DateFilter.module.scss';
import { Input } from '../../../../components';
import { FilterPeriods } from '../../../../constants';
import { useShiftsFilter } from '../../context/shiftContext';
import { SelectableList } from '../../../../components/SelectableList';

export type DateFilterProps = Pick<PopoverProps, 'open' | 'anchorEl' | 'onClose'>;

export const DateFilter = (props: DateFilterProps) => {
  const { open, anchorEl, onClose } = props;
  const { filters, dispatch } = useShiftsFilter();

  const [fromDate, setFromeDate] = useState<Date | undefined>(filters?.startDate);
  const [toDate, setToDate] = useState<Date | undefined>(filters?.endDate);
  const [period, setPeriod] = useState<string | undefined>(filters?.period);
  const [error, setError] = useState<string | null>();

  const handlePeriodClick = (args: { value?: string; label?: string }) => {
    setFromeDate(undefined);
    setToDate(undefined);
    setPeriod(args?.value);
    dispatch({ type: 'shift', payload: args?.value });
    handleClose();
  };

  const handleResetFilter = () => {
    setPeriod(undefined);
    setFromeDate(undefined);
    setToDate(undefined);
    dispatch({ type: 'shift', payload: undefined });
  };

  const handleApplyFilter = () => {
    setPeriod(undefined);
    dispatch({ type: 'shift', payload: { startDate: fromDate, endDate: toDate } });
    handleClose();
  };

  const handleFromDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const date = dayjs(e.target.value, 'YYYY-MM-DD').toDate();
    setFromeDate(date);
  };

  const handleToDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const date = dayjs(e.target.value, 'YYYY-MM-DD').toDate();
    setToDate(date);
  };

  const handleClose = () => {
    onClose?.({}, 'escapeKeyDown');
  };

  useEffect(() => {
    if (fromDate && toDate && dayjs(fromDate).isAfter(dayjs(toDate))) {
      setError('Invalid date range');
    } else {
      setError(null);
    }
  }, [fromDate, toDate]);

  return (
    <Popover
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorEl={anchorEl}
      onClose={onClose}
      classes={{
        paper: styles.container,
      }}
    >
      <div className={styles.datePicker}>
        <div className={styles.dateRangePicker}>
          <Grid
            classes={{
              root: styles.datePickerWrap,
            }}
            container
            alignItems="center"
          >
            <Grid xs={3} item>
              <Typography variant="body1">From</Typography>
            </Grid>
            <Grid item xs>
              <Input
                type="date"
                name="fromDate"
                onChange={handleFromDateChange}
                value={dayjs(fromDate).format('YYYY-MM-DD')}
              />
            </Grid>
          </Grid>
          <Grid
            classes={{
              root: styles.datePickerWrap,
            }}
            container
            alignItems="center"
          >
            <Grid xs={3} item>
              <Typography variant="body1">To</Typography>
            </Grid>
            <Grid item xs>
              <Input
                type="date"
                name="toDate"
                onChange={handleToDateChange}
                value={dayjs(toDate).format('YYYY-MM-DD')}
              />
            </Grid>
          </Grid>
          {error && (
            <Typography color="error" variant="caption">
              {error}
            </Typography>
          )}
          <div className={styles.filterActions}>
            <Button
              onClick={handleResetFilter}
              size="small"
              variant="text"
              sx={{ fontFamily: 'CircularStd-Medium', fontWeight: 500 }}
            >
              Reset filter
            </Button>
            <Button variant="contained" disabled={!!error} onClick={handleApplyFilter} size="small">
              Apply filter
            </Button>
          </div>
          <Divider />
        </div>
        <div className={styles.datePeriods}>
          <SelectableList onItemClick={handlePeriodClick} options={FilterPeriods} value={period} />
        </div>
      </div>
    </Popover>
  );
};
