import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './app';
import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import isBetween from 'dayjs/plugin/isBetween';
import objectSupport from 'dayjs/plugin/objectSupport';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as serviceWorker from './serviceWorker';
import './index.css';

// if (process.env.NODE_ENV === 'production') {
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  environment: process.env.REACT_APP_PROJECT_ENV,
  integrations: [new BrowserTracing()],
  release: 'demand-portal@' + process.env.REACT_APP_VERSION,
  autoSessionTracking: true,
  initialScope: {
    user: { id: localStorage.getItem('_i') ?? undefined },
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
// }

dayjs.locale({
  ...en,
  weekStart: 1,
});
dayjs.extend(isBetween);
dayjs.extend(objectSupport);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
