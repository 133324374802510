import React from 'react';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { Subtitle, Text } from '../helper/Text';
import styles from './Content.module.scss';
import { ShiftDetail, ShiftDetailPreferences } from '../../../types/schema';
import { formatAddress } from '../../../utils/formatAddress';
import { SHIFT_DATE_FORMAT, SHIFT_TIME_FORMAT } from '../../../constants/datetime';
import { CERTIFICATES_LIST } from '../../../constants/certifications';
import { useOrganizationUsers } from '../../../services/useShifts';

export interface ContentProps {
  shift: ShiftDetail;
}

export default function Content({ shift }: ContentProps) {
  const { facility, start_time, end_time, preferences } = shift;
  const { certifications, health_records, licenses, emr_systems, nursing_department_experiences } =
    preferences ?? ({} as ShiftDetailPreferences);

  const organizationId = localStorage.getItem('_o') || '';
  const { data: { data: organizationUsers } = { data: [] } } = useOrganizationUsers(organizationId);
  console.log('Organization users', organizationUsers);
  const facilityPrimaryContact = organizationUsers?.find(
    (u) =>
      u.organization_personnel_id === facility.crib_sheet.primary_contact_person?.demand_user_id,
  );
  return (
    <div className={styles.container}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <div className={styles.mb}>
            <Subtitle>LOCATION</Subtitle>
            <Text>{formatAddress(facility)}</Text>
          </div>

          <div className={styles.mb}>
            <Subtitle>TIME AND DURATION</Subtitle>
            <Text>{dayjs(start_time).format(SHIFT_DATE_FORMAT)}</Text>
          </div>

          <Grid className={styles.mb} container spacing={2}>
            <Grid item xs={6}>
              <Subtitle>START TIME</Subtitle>
              <Text>{dayjs(start_time).format(SHIFT_TIME_FORMAT)}</Text>
            </Grid>
            <Grid item xs={6}>
              <Subtitle>END TIME</Subtitle>
              <Text>{dayjs(end_time).format(SHIFT_TIME_FORMAT)}</Text>
            </Grid>
          </Grid>

          <div className={styles.mb}>
            <Subtitle>Vaccinations</Subtitle>
            <Text>
              {(health_records?.vaccinations ?? []).map((item) => item.vaccine.vaccine).join(', ')}
            </Text>
          </div>

          <div className={styles.mb}>
            <Subtitle>LICENSE</Subtitle>
            <Text>
              {licenses?.us_nursing_license?.us_nursing_license_type?.us_nursing_license_type}
            </Text>
          </div>

          <div className={styles.mb}>
            <Subtitle>YEARS OF EXPERIENCE</Subtitle>
            {nursing_department_experiences?.map((experience) => {
              return (
                <Text>
                  {experience.nursing_department.nursing_department +
                    ': ' +
                    experience.work_experience_minimum_length +
                    '+ years'}
                </Text>
              );
            })}
          </div>

          <div className={styles.mb}>
            <Subtitle>EMR</Subtitle>
            <Text>{emr_systems?.map((item) => item?.emr?.emr).join(', ')}</Text>
          </div>

          <div className={styles.mb}>
            <Subtitle>CERTIFICATIONS</Subtitle>
            <Text>
              {certifications &&
                Object.keys(certifications)
                  .map((key) => CERTIFICATES_LIST.find((l) => l.id === key)?.label)
                  .join(', ')}
            </Text>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          {/* <div className={styles.mb}>
            <Subtitle>MATCHED PROFESSIONAL</Subtitle>
            <Text>Mon, Wed, Fri Jun 17 - 21</Text>
          </div> */}

          {facilityPrimaryContact && (
            <div className={styles.mb}>
              <Subtitle>PRIMARY CONTACT</Subtitle>
              <Text>
                {facilityPrimaryContact.first_name} {facilityPrimaryContact.last_name}
              </Text>
            </div>
          )}

          <div className={styles.mb}>
            <Subtitle>Faciltiy Desk Numbers</Subtitle>
            <Text>
              (+
              {
                facility.crib_sheet.facility_desk_numbers[0].contact_info?.phone_number
                  ?.country_code
              }
              ){` `}
              {
                facility.crib_sheet.facility_desk_numbers[0].contact_info?.phone_number
                  ?.phone_number
              }
            </Text>
          </div>

          <div className={styles.mb}>
            <Subtitle>Facility Policies:</Subtitle>
            {facility.crib_sheet.facility_policies.map((item) => (
              <Text key={item.policy_type}>{item.policy_description}</Text>
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
