import React from 'react';
import AuthenticationLayout from '../../../layout/AuthenticationLayout/AuthenticationLayout';
import LoginForm from './components/LoginForm';

const LoginPage = () => {
  return (
    <AuthenticationLayout
      title="Welcome to Para"
      subtitle="Sign in to your account."
      form={<LoginForm />}
    />
  );
};

export default LoginPage;
