import React from 'react';
import { Button } from '@mui/material';
import { Form, FormActions, Input } from '../../../../components';
// import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';

export interface CribSheetFormFields {
  contact_person?: string;
  contact_person_detail?: string;
  other_contact?: string;
  other_contact_detail?: string;
  report_to?: string;
  get_badge_from?: string;
  entrance?: string;
  parking?: string;
  break_policy?: string;
  lunch_policy?: string;
  clock_on_ready?: string;
  on_unit_time?: string;
  property_storage?: string;
  other?: string;
}

const ProfileCompanyCribSheetForm = () => {
  // const handleCribSheetRequest = (crib_sheet) => {
  //   const { createCribSheet, updateCribSheet, locationId } = this.props;

  //   const data = {
  //     location_id: locationId || ls.get('_loc_id'),
  //     crib_sheet,
  //   };

  //   return this.currentForm.verified ? updateCribSheet(data) : createCribSheet(data);
  // };

  // const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      name: '',
      location: '',
      phone: '',
      npiNumber: '',
      address: '',
    },
    validate: (values) => {
      const errors = {} as object;

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      // setSubmitting(true);
      // try {
      //   setSubmitting(false);
      //   enqueueSnackbar('Information saved successfully', {
      //     variant: 'success',
      //   });
      // } catch (e: any) {
      //   setSubmitting(false);
      //   enqueueSnackbar(e.message ? e.message : e.code ? e.code : e.status, {
      //     variant: 'error',
      //   });
      // }
    },
  });

  const { handleSubmit, isSubmitting } = formik;
  return (
    <Form onSubmit={handleSubmit}>
      <Input name="contact_person" label="Point of Contact" />
      <Input name="contact_person_detail" label="POC phone number" />
      <Input name="other_contact" label="Backup Point of Contact" />
      <Input name="other_contact_detail" label="Backup POC phone number" />
      <Input name="report_to" label="Report to" />
      <Input name="get_badge_from" label="Obtain Badge directions" />
      <Input name="entrance" label="Entrance Directions" />
      <Input name="parking" label="Parking Directions" />
      <Input name="break_policy" label="Break Policy" />
      <Input name="lunch_policy" label="Lunch policy" />
      <Input name="clock_on_ready" label="Clock On" />
      <Input name="on_unit_time" label="Unit Time" />
      <Input name="property_storage" label="Property Storage" />
      <Input name="other" label="Other Notes" />
      <FormActions>
        <Button type="submit" disabled={isSubmitting} variant="contained">
          Save
        </Button>
      </FormActions>
    </Form>
  );
};

export default ProfileCompanyCribSheetForm;
