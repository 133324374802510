import { Certificates, FacilityDeskNumber, FacilityPolicy } from '../../../../../types/schema';

export interface CreateShiftFormValues {
  department: string;
  facility: string;
  license: string;
  jobTitle: string;
  primaryContactPerson: string;
  facility_desk_numbers: FacilityDeskNumber[];
  facility_policies: FacilityPolicy[];
  parking: string;
  entrance: string;
  departments: string[];
  certifications: Certificates;
  emrs: string;
  experience: number;
  vaccinations: string;
  screenings: string;
  start_date: string;
  end_date: string;
  start_time: string;
  end_time: string;
  days: number[];
  payrate: string;
}

export const createShiftFormInitialValues: CreateShiftFormValues = {
  // basic form
  department: '',
  facility: '',
  license: '',
  jobTitle: '',
  // crib sheet form
  primaryContactPerson: '',
  facility_desk_numbers: [],
  facility_policies: [],
  parking: '',
  entrance: '',
  departments: [],
  certifications: {},
  emrs: '',
  experience: 0,
  vaccinations: '',
  screenings: '',
  start_date: '',
  end_date: '',
  start_time: '',
  end_time: '',
  days: [],
  payrate: '',
};
