import * as React from 'react';
import { Card } from '@mui/material';
import { Loader } from '../../components';
import { useParams } from 'react-router-dom';
import styles from './ShiftDetail.module.scss';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import { ShiftDetail as ShiftDetailType } from '../../types/schema';
import { useShiftDetail } from '../../services/useShifts';

export default function ShiftDetail() {
  const { id } = useParams<{ id: string }>();
  const urlParams = new URLSearchParams(window.location.search);
  const jobId = urlParams.get('jobId') || '';
  const organizationId = urlParams.get('organizationId') || '';

  const { isLoading, data: shiftDetail } = useShiftDetail(organizationId, jobId, id);

  if (!id || !jobId || !organizationId) {
    return null;
  }

  const { start_time, end_time, facility } = shiftDetail?.data || {};

  return (
    <Card className={styles.container}>
      {isLoading && (
        <div className={styles.centerBlock}>
          <Loader />
        </div>
      )}
      {shiftDetail && (
        <>
          <Header startTime={start_time} endTime={end_time} address={facility?.facility_name} />
          <Content shift={shiftDetail?.data || ({} as ShiftDetailType)} />
          <Footer shift={shiftDetail?.data || ({} as ShiftDetailType)} />
        </>
      )}
    </Card>
  );
}
