import React from 'react';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Form, FormActions, Input } from '../../../../../components';
import { FORGOT_PASSWORD, SIGNUP } from '../../../../../constants/routes';
import { login } from '../../../../../services/authentication.service';
import { getErrorMessage, validateEmail } from '../../../../../utils';

const LoginForm = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate: (values) => {
      const errors = {} as {
        email: string;
        password: string;
      };
      if (!values.email) {
        errors.email = 'Required';
      } else if (!validateEmail(values.email)) {
        errors.email = 'Invalid email address';
      }
      if (!values.password) {
        errors.password = 'Required';
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const result = await login(values.email, values.password);
        console.log('Login', result);
        setSubmitting(false);
        history.push('/');
      } catch (error) {
        setSubmitting(false);
        enqueueSnackbar(getErrorMessage(error) || 'Authentication error', {
          variant: 'error',
        });
      }
    },
  });

  const { handleSubmit, values, handleChange, touched, errors, isSubmitting } = formik;

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        id="email"
        name="email"
        type="email"
        label="Email"
        value={values.email}
        onChange={handleChange}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
      />
      <Input
        id="password"
        name="password"
        label="Password"
        type="password"
        value={values.password}
        onChange={handleChange}
        error={touched.password && Boolean(errors.password)}
        helperText={touched.password && errors.password}
      />
      <FormActions>
        <Button type="submit" variant="contained" disabled={isSubmitting}>
          Login
        </Button>
        <Button variant="outlined" disabled={isSubmitting} onClick={() => history.push(SIGNUP)}>
          Sign up
        </Button>
        <Button
          variant="text"
          disabled={isSubmitting}
          onClick={() => {
            history.push(FORGOT_PASSWORD);
          }}
        >
          Forgot password
        </Button>
      </FormActions>
    </Form>
  );
};

export default LoginForm;
