import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Input, Form, FormActions, Alert } from '../../../../../components';
import { LOGIN } from '../../../../../constants/routes';
import { getErrorMessage, validateEmail, validatePassword } from '../../../../../utils';
import {
  requestPhoneVerificationCode,
  signup,
  verifyPhoneNumber,
} from '../../../../../services/authentication.service';
import { HTTPError } from '../../../../../types/schema';

const SetPasswordForm = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const history = useHistory();

  const [verifyPhoneStep, setVerifyPhoneStep] = useState(false);
  const [error, setError] = useState('');

  const formik = useFormik({
    initialValues: {
      email_address: urlParams.get('email') || '',
      first_name: urlParams.get('first_name') || '',
      last_name: urlParams.get('last_name') || '',
      organization_name: urlParams.get('org_name') || '',
      token: urlParams.get('token') || '',
      password: '',
      confirm_password: '',
      phone_number: '',
      verification_code: '',
    },
    validate: (values) => {
      const errors = {} as {
        email_address: string;
        password: string;
        confirm_password: string;
        phone_number: string;
        verification_code: string;
      };
      if (!values.email_address) {
        errors.email_address = 'Required';
      } else if (!validateEmail(values.email_address)) {
        errors.email_address = 'Invalid email address';
      }
      if (!values.password) {
        errors.password = 'Required';
      } else if (!validatePassword(values.password)) {
        errors.password =
          'Minimum 8 characters; at least 1 Uppercase Alphabet, 1 Lowercase Alphabet and 1 Number';
      }
      if (!values.confirm_password) {
        errors.confirm_password = 'Required';
      } else if (values.confirm_password !== values.password) {
        errors.password = "Passwords don't match";
      }
      if (!values.phone_number) {
        errors.phone_number = 'Required';
      }
      if (verifyPhoneStep === true) {
        console.log('Validate phone', values.verification_code);
        if (!values.verification_code) {
          errors.verification_code = 'Required';
        } else if (values.verification_code.length < 6) {
          errors.verification_code = 'Verification code must be at least 6 characters';
        }
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      setError('');
      if (!verifyPhoneStep) {
        setSubmitting(true);
        await handleRequestPhoneVerificationCode();
        setSubmitting(false);
      } else {
        setSubmitting(true);
        await handleVerifyPhoneNumber();
        setSubmitting(false);
      }
    },
  });

  const { handleSubmit, values, handleChange, touched, errors, isSubmitting, setFieldValue } =
    formik;

  const handleSignup = async (
    email: string,
    password: string,
    countryCode: string,
    parsedPhoneNumber: string,
  ) => {
    await signup(
      email,
      values.token,
      password,
      countryCode,
      parsedPhoneNumber,
      values.first_name,
      values.last_name,
      values.organization_name,
    );
  };

  const handleVerifyPhoneNumber = async () => {
    try {
      const { verification_code, phone_number, email_address, password } = values;
      const parsedPhone = parsePhoneNumberFromString(phone_number ?? '');
      const countryCode = parsedPhone ? parsedPhone.countryCallingCode.toString() : '1';
      const parsedPhoneNumber = parsedPhone ? parsedPhone.nationalNumber.toString() : phone_number;

      await verifyPhoneNumber(countryCode, parsedPhoneNumber, verification_code);

      await handleSignup(email_address, password, countryCode, parsedPhoneNumber);
      history.push('/');
    } catch (error) {
      setError(getErrorMessage(error));
    }
  };

  const handleRequestPhoneVerificationCode = async () => {
    const parsedPhone = parsePhoneNumberFromString(values.phone_number);
    const countryCode = parsedPhone ? parsedPhone.countryCallingCode.toString() : '1';
    const parsedPhoneNumber = parsedPhone
      ? parsedPhone.nationalNumber.toString()
      : values.phone_number;
    setError('');
    try {
      await requestPhoneVerificationCode(countryCode, parsedPhoneNumber);
      setVerifyPhoneStep(true);
      setFieldValue('verification_code', '');
    } catch (error) {
      if (
        (error as HTTPError).status === 403 &&
        (error as HTTPError).code === 'verification.phone_number_verified'
      ) {
        await handleSignup(values.email_address, values.password, countryCode, parsedPhoneNumber);
      } else {
        setError(getErrorMessage(error));
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        id="email_address"
        name="email_address"
        type="email"
        label="Email"
        value={values.email_address}
        onChange={handleChange}
        error={touched.email_address && Boolean(errors.email_address)}
        helperText={touched.email_address && errors.email_address}
        disabled
      />
      <Input
        id="password"
        name="password"
        label="New password"
        type="password"
        value={values.password}
        onChange={handleChange}
        error={touched.password && Boolean(errors.password)}
        helperText={touched.password && errors.password}
      />
      <Input
        id="confirm_password"
        name="confirm_password"
        label="Confirm password"
        type="password"
        value={values.confirm_password}
        onChange={handleChange}
        error={touched.confirm_password && Boolean(errors.confirm_password)}
        helperText={touched.confirm_password && errors.confirm_password}
      />

      <Input
        id="phone_number"
        name="phone_number"
        label="Phone number"
        type="text"
        value={values.phone_number}
        onChange={handleChange}
        error={touched.phone_number && Boolean(errors.phone_number)}
        helperText={touched.phone_number && errors.phone_number}
      />

      {verifyPhoneStep && (
        <>
          <Input
            id="verification_code"
            name="verification_code"
            label="Verification code"
            type="text"
            value={values.verification_code}
            onChange={handleChange}
            error={touched.verification_code && Boolean(errors.verification_code)}
            helperText={touched.verification_code && errors.verification_code}
          />
          <div>
            <Typography variant="body2">Did not receive the code?</Typography>
            <Button
              type="button"
              variant="text"
              onClick={() => {
                handleRequestPhoneVerificationCode();
              }}
            >
              Resend verification code
            </Button>
          </div>
        </>
      )}
      <FormActions>
        <Button type="submit" disabled={isSubmitting} variant="contained">
          {!verifyPhoneStep ? 'Verify phone number' : 'Set Password'}
        </Button>
        <Button variant="outlined" disabled={isSubmitting} onClick={() => history.push(LOGIN)}>
          Back
        </Button>
      </FormActions>
      {error && <Alert type="error">{error}</Alert>}
    </Form>
  );
};

export default SetPasswordForm;
