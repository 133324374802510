import React from 'react';
import styles from './ProgressLine.module.scss';

interface ProgressLineProps {
  progress: number;
  steps: number;
}

const ProgressLine = (props: ProgressLineProps) => {
  const { progress, steps } = props;
  return (
    <div className={styles.Progress}>
      <div className={styles.ProgressLine} style={{ width: (100 / steps) * progress + '%' }} />
    </div>
  );
};

export default ProgressLine;
