import React from 'react';
import { NavLink } from 'react-router-dom';
import { AGREEMENTS } from '../../../constants/routes';

const TermsAndConditions = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1 className="text-center mb-30">Para Service Terms and Condition</h1>

      <p>
        Welcome to the Para Platform! These Terms and Conditions of Service (the "
        <strong>Terms</strong>") govern your access and use of the Para Services, Inc. d/b/a Para ("
        <strong>Para,</strong>" "<strong>we,</strong>" "<strong>us,</strong>" and "
        <strong>our</strong>") website located at www.joinpara.com (the "Website"), the Para mobile
        application(s) (the "<strong>App</strong>"), the services provided by the Website and App ("
        <strong>Services</strong>"), and any other products or services offered or provided on the
        Website or App (collectively, the "<strong>Para Platform</strong>"). By using, accessing, or
        downloading the Para Platform, you ("<strong>User</strong>," "<strong>you</strong>" or "
        <strong>your</strong>") acknowledge that you have read, understand, and agree to these
        Terms. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT USE OR ACCESS THE PARA PLATFORM.
      </p>

      <h4>General Terms of Use and Restrictions on Use</h4>

      <p>
        In order to use the Para Platform, users will be required to enter into the applicable Para
        Services Membership Agreement (defined below) and create an Account (defined below). The
        Para Platform will have two different types of user Accounts: Healthcare Providers (defined
        below) and Medical Professionals (defined below). Healthcare Providers post Shifts (defined
        below) for independent contractors to provide healthcare professional services and Medical
        Professionals, as independent contractors, sign up for posted Shifts to provide healthcare
        professional services. If you are a hospital, healthcare facility or entity that otherwise
        provides healthcare services and employs healthcare professionals ("
        <strong>Healthcare Providers</strong>"), you must enter into the Demand Membership Agreement
        and create an Account. Once you enter into the Demand Membership Agreement and create an
        Account, Healthcare Providers may use the Para Platform for the purpose of creating,
        defining and posting a Shift.
      </p>

      <p>
        If you are an individual seeking to fulfill Shifts on an independent contractor basis ("
        <strong>Medical Professionals</strong>"), you must enter into the Supply{' '}
        <NavLink to={`${AGREEMENTS}/membership`}>Membership Agreement</NavLink> and create an
        Account. Medical Professionals may use the Para Platform for the purpose of intent to
        search, be notified and sign up for work posted Shifts.
      </p>

      <p>
        A Shift means any posted work opening intended to be performed by a Medical Professional,
        the duration of which will be for as many days as the Healthcare Provider selects on the
        Para Platform; provided, however, the hours to be completed by a Medical Professional shall
        not exceed 13 hours per 24 hour period.
      </p>

      <p>
        To register and create an account, you will be required to first create a username and
        password and to provide certain information as requested in the User registration
        ("Account"). By creating an Account, you agree to provide current, accurate and complete
        information. You also agree that your Account username and password are personal to you, and
        you are responsible for maintaining the confidentiality and security of your username and
        password. You agree to notify us immediately if you become aware of or suspect any breach of
        security or unauthorized use of your Account.
      </p>

      <p>
        Subject to these Terms, Para grants you a limited, revocable, nonexclusive, nontransferable
        personal right to access and make use of the Para Platform solely for the benefit of User
        and no other commercial purpose. You may not copy, modify, distribute, sell, or lease any
        part of the Services. You agree to use the Para Platform and related services only for
        lawful purposes and to follow all applicable laws and regulations of the United States and
        any state in which you reside when using the Para Platform. Unless such restriction is
        prohibited by law or you have Para’s written permission, you may not reverse engineer or
        attempt to extract the source code of the Services. You may only access the Services through
        the interfaces that Para provides for that purpose (for example, you may not "scrape" the
        Services through automated means or "frame" any part of the Services), and you may not
        interfere or attempt to disrupt the Services.
      </p>

      <h4>Use of the Services</h4>

      <p>
        The Para Platform may allow you to upload or submit content such as text, images, links, and
        other materials. You retain all rights to any content that you upload or submit, and are
        solely responsible for that content. You grant Para a non-exclusive, royalty-free,
        worldwide, transferable, sub-licensable license to use, store, publicly display, publicly
        perform, reproduce, modify, create derivative works from, and distribute any such content
        for the purposes of operating, providing, and improving the Para Platform and its services.
        Para may, at its sole discretion, and without notice, remove or take down any content that
        you upload or submit to the Services for any reason, including violation of these Terms or
        any other policies.
      </p>

      <p>
        You may have the option of accessing the Services through downloadable software and this
        software may update itself automatically on your device. Some software, or portions of
        software, in the Services, may be governed by open source licenses. In that case, Para will
        make such licenses available to you and, in the case of conflict between such a license and
        these Terms, the open source license will control but only with respect to the software, or
        portion of the software, to which it applies.
      </p>

      <p>
        If you are using Para on behalf of a business or other entity such as a Healthcare Provider,
        you represent and warrant that you have the authority to bind that business or entity to
        these Terms and that you are agreeing to these Terms on behalf of that business or entity.
      </p>

      <p>
        You also agree that you will comply with all applicable laws when accessing or using the
        Services. Para reserves the right to terminate Accounts, decline Shifts posted, or cancel
        Shifts at any time at its sole discretion.
      </p>

      <p>
        Para may introduce new features, change existing features, or remove features from the
        Services at any time and without notice. If you provide Para with any feedback on or
        comments regarding the Services, you grant Para the right to use such feedback or comments
        for any purpose without restriction or payment to you.
      </p>

      <h4>Communications</h4>

      <p>
        By creating a Para user Account, you agree and accept to receive communications from Para or
        other users, including via email, text message, calls, and push notifications to the
        cellular telephone number you provided to Para. You understand and agree that you may
        receive communications generated by automatic telephone dialing systems and/or which will
        deliver prerecorded messages sent by or on behalf of Para, its affiliated companies and/or
        other users, including but not limited to communications concerning Shifts posted, Shifts
        you signed up for through your account or matched Shifts through the Services. Message and
        data rates may apply.
      </p>

      <h4>Links and Third Party Content</h4>

      <p>
        The Para Platform may include features, functions, and other means to create, support,
        facilitate, and maintain services offered through the Para Platform. This may further
        include the use of third parties services ("Third Party Services") subject to additional or
        separate terms that may apply to your use of or access of such Third Party Services.
      </p>

      <p>
        You agree that Para does not assume responsibility for any products, content, services,
        websites, advertisements, offers, or information that are provided by third parties and made
        available through the Services. If you purchase, use, or access any such products, content,
        services, advertisements, offers, or information through the Services, you agree that you do
        so at your own risk and that Para will have no liability based on such purchase, use,
        interaction or access.
      </p>

      <p>
        https://checkr.com/terms-of-service/ <br />
        https://stripe.com/us/legal <br />
        https://www.twilio.com/legal/tos
      </p>

      <h4>Privacy</h4>

      <p>
        Your privacy is important to us. Please carefully review our{' '}
        <NavLink to={`${AGREEMENTS}/policy`}>Privacy Policy</NavLink>
        located at Privacy Policy for information about how Para may collect, use, store, or share
        your information. The terms of the Privacy Policy are hereby expressly incorporated into
        these Terms.
      </p>

      <h4>Notice of Copyright Infringement</h4>

      <p>
        If you believe that the Para Platform contains any content or elements that infringe your
        copyrights, please provide Para’s Designated Copyright Agent with the following information
        in writing:
      </p>

      <p>
        1. A physical or electronic signature of a person authorized to act on behalf of the owner
        of an exclusive right that is allegedly infringed;
      </p>

      <p>
        2. Identification of the copyrighted work claim to have been infringed, or if multiple
        copyrighted works at a single online site are covered by a single notification, a
        representative list of such works at that site;
      </p>

      <p>
        3. Identification of the material that is claimed to be infringing or to be the subject of
        infringing activity and that is to be removed or access to which is to be disabled, and
        information reasonably sufficient to permit the service provider to locate the material;
      </p>

      <p>
        4. Information reasonably sufficient to permit the service provider to contact the
        complaining party, such as an address, telephone number, and email address;
      </p>

      <p>
        5. A statement that the complaining party has a good faith belief that the disputed use is
        not authorized by the copyright owner, its agent, or the law; and
      </p>

      <p>
        6. A statement that the information in the notification is accurate, and under penalty of
        perjury, that the complaining party is authorized to act on behalf of the owner of an
        exclusive right that is allegedly infringed.
      </p>

      <address className="mb-20">
        Para Services, Inc. <br />
        Attn: Para Legal, Para Designated Copyright Agent <br />
        1038 W Monroe St. Chicago, Illinois <br />
        admin@joinpara.com
      </address>

      <p>
        For more information regarding the above, please reference the Digital Millennium Copyright
        Act, 17 U.S.C. § 512.
      </p>

      <h4>Disclaimer; Limitation of Liability</h4>

      <h5>DISCLAIMER.</h5>

      <p>
        THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." PARA DISCLAIMS ALL REPRESENTATIONS AND
        WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING
        THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
        NON-INFRINGEMENT. IN ADDITION, PARA MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE
        REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, OR AVAILABILITY OF THE SERVICES
        OR ANY SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE SERVICES
        WILL BE UNINTERRUPTED OR ERROR-FREE. PARA DOES NOT GUARANTEE THE QUALITY, SUITABILITY,
        SAFETY OR ABILITY OF THIRD PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF
        YOUR USE OF THE SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS
        SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
      </p>

      <h5>LIMITATION OF LIABILITY.</h5>

      <p>
        PARA SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR
        CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY
        DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES,
        REGARDLESS OF THE NEGLIGENCE (EITHER ACTIVE, AFFIRMATIVE, SOLE, OR CONCURRENT) OF PARA, EVEN
        IF PARA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </p>

      <p>
        PARA SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE
        OF OR RELIANCE ON THE SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY
        TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY PROVIDER, EVEN IF PARA HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. PARA SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN
        PERFORMANCE RESULTING FROM CAUSES BEYOND PARA’S REASONABLE CONTROL.
      </p>

      <p>
        THE SERVICES MAY BE USED BY YOU TO POST SHIFTS TO RECEIVE HEALTHCARE PROFESSIONAL SERVICES
        FROM INDEPENDENT CONTRACTORS SUCH AS MEDICAL PROFESSIONALS, BUT YOU AGREE THAT PARA HAS NO
        RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY HEALTHCARE PROFESSIONAL SERVICE, ACT,
        ACTION, OR SERVICES PROVIDED TO YOU BY INDEPENDENT CONTRACTORS SUCH AS A MEDICAL
        PROFESSIONAL OTHER THAN AS EXPRESSLY SET FORTH IN THESE TERMS.
      </p>

      <p>
        THE SERVICES MAY BE USED BY YOU TO SIGN UP FOR POSTED SHIFTS TO PROVIDE HEALTHCARE
        PROFESSIONAL SERVICES AS AN INDEPENDENT CONTRACTOR, BUT YOU AGREE THAT PARA HAS NO
        RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY ACT OF A HEALTHCARE PROVIDER OTHER THAN AS
        EXPRESSLY SET FORTH IN THESE TERMS.
      </p>

      <p>
        THE LIMITATIONS AND DISCLAIMER IN THIS SECTION DO NOT PURPORT TO LIMIT LIABILITY OR ALTER
        YOUR RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW. BECAUSE SOME STATES
        OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR THE LIMITATION OF LIABILITY FOR
        CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS, PARA’S LIABILITY SHALL
        BE LIMITED TO THE EXTENT PERMITTED BY LAW. THIS PROVISION SHALL HAVE NO EFFECT ON PARA’S
        CHOICE OF LAW PROVISION SET FORTH BELOW.
      </p>

      <h4>Indemnity.</h4>

      <p>
        You agree to indemnify and hold Para and its affiliates and their officers, directors,
        employees, and agents harmless from any and all claims, demands, losses, liabilities, and
        expenses (including attorneys' fees), arising out of or in connection with: (i) your use of
        the Services or services or goods obtained through your use of the Services; (ii) your
        breach or violation of any of these Terms; (iii) Para’s use of your User Content; or (iv)
        your violation of the rights of any third party, including Third Party Providers.
      </p>

      <h4>Termination</h4>

      <p>
        You can stop using the Services at any time and without notice to us. Similarly, Para may
        terminate access to the Services to you or any other users or stop offering all or part of
        the Services at any time without notice. In the event of termination, Sections Disclaimer;
        Limitation of Liability, Indemnity, Dispute Resolution, and Choice of Law including these
        Sections’ subsections survive and continue to apply to you.
      </p>

      <h4>Dispute Resolution</h4>

      <p>
        Mandatory Arbitration: Any dispute, claim or controversy arising out of your use of the
        Services or these Terms, including the determination of the scope or applicability of this
        agreement to arbitrate (a "Dispute"), will be determined by arbitration.
      </p>

      <p>
        <strong>CLASS ACTION WAIVER</strong>: TO THE EXTENT PERMISSIBLE BY LAW, ALL CLAIMS MUST BE
        BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
        PURPORTED CLASS, OR COLLECTIVE ACTION PROCEEDING (COLLECTIVELY "CLASS ACTION WAIVER"). THE
        ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS OR ENGAGE IN ANY CLASS
        ARBITRATION. YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND PARA ARE EACH WAIVING THE
        RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION. In any lawsuit in which (1)
        the complaint is filed as a class action, or collective action; and (2) the civil court of
        competent jurisdiction in which the complaint was filed finds the Class Action Waiver is
        unenforceable (and such finding is confirmed by appellate review if review is sought), the
        Class Action Waiver shall be severable from this Agreement and in such instances, the class
        action, and/or collective action must be litigated in a civil court of competent
        jurisdiction and not as a class, or collective arbitration.
      </p>

      <p>
        Notwithstanding any other clause contained in this Agreement, any claim that all or part of
        the Class Action Waiver is invalid, unenforceable, unconscionable, void or voidable may be
        determined only by a court of competent jurisdiction and not by an arbitrator. The Class
        Action Waiver shall be severable when a dispute is filed as an individual action and
        severance is necessary to ensure that the individual action proceeds in arbitration.
      </p>

      <p>
        Arbitration Procedures and Location: The arbitration will be administered by the American
        Arbitration Association ("AAA") in accordance with AAA Consumer Arbitration Rules. Judgment
        on the award may be entered in any court having jurisdiction. This provision will not
        preclude parties from seeking provisional remedies in aid of arbitration from a court of
        appropriate jurisdiction. This provision will survive termination of this Agreement.
      </p>

      <p>
        The arbitration will be held in the United States county where you live or work, Chicago,
        Illinois or any other location you and Para mutually agree upon in writing.
      </p>

      <p>
        Arbitration Fees: The arbitrator will determine how the costs and expenses of the
        arbitration will be allocated between the Parties, and may award attorneys’ fees.
      </p>

      <h4>Choice of Law</h4>

      <p>
        These Terms are governed by and construed in accordance with the laws of the State of Texas,
        U.S.A., without giving effect to any conflict of law principles, except as may be otherwise
        provided in the Dispute Resolution section above or in supplemental terms applicable to your
        region. However, the choice of law provision regarding the interpretation of these Terms is
        not intended to create any other substantive right to non-Texans to assert claims under
        Texas law whether that be by statute, common law, or otherwise. This provision is only
        intended to specify the use of Texas law to interpret these Terms and the forum for disputes
        asserting a breach of these Terms, and this provisions shall not be interpreted as generally
        extending Texas law to you if you do not otherwise reside in Texas. The foregoing choice of
        law and forum selection provisions do not apply to the Dispute Resolution section or to any
        arbitrable disputes as defined therein. Instead, as described in the Dispute Resolution
        section, the Federal Arbitration Act (9 U.S.C., Secs. 1-16) shall apply to any such
        disputes.
      </p>

      <h4>Miscellaneous</h4>

      <h5>Entire Agreement and Severability</h5>

      <p>
        These Terms, subject to any amendments, modifications, or additional agreements you enter
        into with Para, shall constitute the entire agreement between you and Para with respect to
        the Services and any use of the Services. If any provision of these Terms is found to be
        invalid by a court competent jurisdiction, that provision only will be limited to the
        minimum extent necessary and the remaining provisions will remain in full force and effect.
      </p>

      <h4>No Waiver</h4>

      <p>
        Para’s failure to monitor or enforce a provision of these Terms does not constitute a waiver
        of its right to do so in the future with respect to that provision, any other provision, or
        these Terms as a whole.
      </p>

      <h4>Assignment</h4>

      <p>
        You may not assign any of your rights, licenses, or obligations under these Terms. Any such
        attempt at assignment by you shall be void. Para may assign its rights, licenses, and
        obligations under these Terms without limitation.
      </p>

      <h4>Modifications to the Terms</h4>

      <p>
        Para may make changes to these Terms from time to time. Para will post the most current
        version of the Terms on the Para Platform and, if a revision to the Terms is material, Para
        will notify you of the new Terms by email or through the Para Platform. Changes to these
        terms will not apply retroactively. If you do not agree to the modified terms, you should
        discontinue your use of the Services.
      </p>

      <address className="mb-20">
        <strong>Contact Us</strong> <br />
        Para Services, Inc. <br />
        1038 W Monroe St <br />
        Chicago, Illinois 60607 <br />
        admin@joinpara.com <br />
        630.605.7431
      </address>
    </div>
  );
};

export default TermsAndConditions;
