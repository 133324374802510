import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// import { confirm } from './components';
import PrivateRoute from './components/PrivateRoute';
import {
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  LOGIN,
  RESET_PASSWORD,
  SET_PASSWORD,
  SIGNUP,
  CREATE_SHIFT,
  FACILITIES,
  AGREEMENTS,
  SHIFTS,
  SIGNUP_VERIFICATION,
} from './constants/routes';

import LoginPage from './pages/Authentication/Login';
import ForgotPassword from './pages/Authentication/ForgotPassword';
import ResetPassword from './pages/Authentication/ResetPassword';
import ChangePassword from './pages/Authentication/ChangePassword';
import SignUpPage from './pages/Authentication/Signup';
import SetPasswordPage from './pages/Authentication/SetPassword';
import SignupVerification from './pages/Authentication/SignupVerification';

import ProfilePage from './pages/Profile';
import ProfileUserPage from './pages/Profile/ProfileUser';
import ProfileCompanyPage from './pages/Profile/ProfileCompany';
import ProfileCompanyUsersPage from './pages/Profile/ProfileCompanyUsers';
import ProfileCompanyRequirementsPage from './pages/Profile/ProfileCompanyRequirements';
import ProfileCompanyCribSheetPage from './pages/Profile/ProfileCompanyCribSheet';

import ProfileLegal from './pages/Profile/ProfileLegal';
import Terms from './pages/Profile/ProfileLegal/Terms';
import Policy from './pages/Profile/ProfileLegal/Policy';
import Membership from './pages/Profile/ProfileLegal/Membership';

import Facilities from './pages/Facilities';
import Facility from './pages/Facilities/Facility';

import ShiftsPage from './pages/Shifts';
import CreateShiftPage from './pages/CreateShift';
import ShiftDetail from './pages/ShiftDetails';

import Agreements from './pages/Onboarding/Agreements';
import { AgreementDetail } from './pages/Onboarding/AgreementDetail';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path={LOGIN}>
          <LoginPage />
        </Route>
        <Route path={SIGNUP}>
          <SignUpPage />
        </Route>
        <Route path={SIGNUP_VERIFICATION}>
          <SignupVerification />
        </Route>
        <Route path={SET_PASSWORD}>
          <SetPasswordPage />
        </Route>
        <Route path={FORGOT_PASSWORD}>
          <ForgotPassword />
        </Route>
        <Route path={CHANGE_PASSWORD}>
          <ChangePassword />
        </Route>
        <Route path={RESET_PASSWORD}>
          <ResetPassword />
        </Route>

        {/* Initial user onboarding */}
        <PrivateRoute path={AGREEMENTS}>
          <Agreements />
        </PrivateRoute>
        <PrivateRoute path={AGREEMENTS + '/:id'}>
          <AgreementDetail />
        </PrivateRoute>
        {/* End of initial user onboarding */}

        <PrivateRoute path={CREATE_SHIFT}>
          <CreateShiftPage />
        </PrivateRoute>
        <PrivateRoute exact path="/profile">
          <ProfilePage />
        </PrivateRoute>
        <PrivateRoute path="/profile/user">
          <ProfileUserPage />
        </PrivateRoute>
        <PrivateRoute path="/profile/company">
          <ProfileCompanyPage />
        </PrivateRoute>
        <PrivateRoute path="/profile/users">
          <ProfileCompanyUsersPage />
        </PrivateRoute>
        <PrivateRoute path="/profile/company/requirements">
          <ProfileCompanyRequirementsPage />
        </PrivateRoute>
        <PrivateRoute path="/profile/company/crib-sheet">
          <ProfileCompanyCribSheetPage />
        </PrivateRoute>

        <PrivateRoute exact path="/profile/legal">
          <ProfileLegal />
        </PrivateRoute>
        <PrivateRoute path="/profile/legal/terms">
          <Terms />
        </PrivateRoute>
        <PrivateRoute path="/profile/legal/policy">
          <Policy />
        </PrivateRoute>
        <PrivateRoute path="/profile/legal/membership">
          <Membership />
        </PrivateRoute>

        <PrivateRoute exact path={FACILITIES}>
          <Facilities />
        </PrivateRoute>
        <PrivateRoute path={FACILITIES + '/:facilityId'}>
          <Facility />
        </PrivateRoute>

        <PrivateRoute exact path={SHIFTS}>
          <ShiftsPage />
        </PrivateRoute>
        <PrivateRoute path={SHIFTS + '/:id'}>
          <ShiftDetail />
        </PrivateRoute>
        <Redirect to={SHIFTS} />
      </Switch>
    </Router>
  );
};

export default Routes;
