import React from 'react';
import { OptionItem, OptionItemProps } from '../../components';
import styles from './SelectableList.module.scss';

export interface SelectableListProps {
  options?: OptionItemProps[];
  value?: string | string[];
  multiple?: boolean;
  onItemClick?: (item: Pick<OptionItemProps, 'label' | 'value'>) => void;
}

export const SelectableList = ({
  options = [],
  multiple = false,
  onItemClick,
  value,
}: SelectableListProps) => {
  return (
    <ul className={styles.list}>
      {options.map((option) => (
        <OptionItem
          key={option.value}
          selected={multiple ? value?.includes(option.value ?? '') : value === option.value}
          {...option}
          onItemClick={onItemClick}
        />
      ))}
    </ul>
  );
};
