import React, { useCallback, useState } from 'react';
import { Formik, Form } from 'formik';
import { CircularProgress, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Modal, ProgressLine } from '../../../../components';
import { useCreateShift } from '../../../../services/useShifts';
import { DemandJobPayload, NursingDepartment } from '../../../../types/schema';
import { SHIFTS } from '../../../../constants/routes';

import { getNursingDepartments, getShiftTimeSlots } from '../utils';
import BasicForm from '../forms/Basic';
import CribSheetForm from '../forms/CribSheetForm';
import DateManageForm from '../forms/DateManage';
import AdditionalForm from '../forms/Additional';
import DepartmentsForm from '../forms/Departments';
import PreviewForm from '../forms/Preview';
import {
  createShiftFormInitialValues,
  CreateShiftFormValues,
} from '../forms/FormModel/formInitialValues';
import validationSchema from '../forms/FormModel/validationSchema';
import styles from './CreateShiftModal.module.scss';

interface CreateShiftModalProps {
  department: NursingDepartment;
  onClose: () => void;
}

const CreateShiftModal = (props: CreateShiftModalProps) => {
  const { onClose, department } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [step, setStep] = useState(1);
  const currentValidationSchema = validationSchema[step - 1];

  const handleSubmit = (values: CreateShiftFormValues) => {
    const {
      department,
      facility,
      departments,
      jobTitle,
      primaryContactPerson,
      facility_desk_numbers,
      facility_policies,
      parking,
      entrance,
      certifications,
      emrs,
      experience,
      vaccinations,
      screenings,
      license,
      start_date,
      end_date,
      start_time,
      end_time,
      days,
      payrate,
    } = values;

    const shiftTimes = getShiftTimeSlots(start_date, end_date, start_time, end_time, days);

    const shiftTimesWithPayrate = shiftTimes.map((slot) => ({
      start_time: slot.start_time,
      end_time: slot.end_time,
      shift_rate: {
        shift_rate_price: {
          string_format: Number(payrate).toFixed(2),
        },
      },
    }));
    const organizationId = localStorage.getItem('_o') || '';
    const accountId = localStorage.getItem('_i') || '';
    const jobData: DemandJobPayload = {
      demand_id: organizationId,
      drafted_by_id: accountId,
      job_title: jobTitle,
      demand_facility: {
        demand_facility_id: facility,
        crib_sheet: {
          primary_contact_person: {
            demand_user_id: primaryContactPerson,
          },
          facility_desk_numbers,
          facility_policies,
          logistic_information: {
            parking,
            entrance,
          },
        },
      },
      preferences: {
        certifications,
        emr_systems: Object.keys(emrs).map((key) => ({ emr_id: key })),
        health_records: {
          vaccinations: Object.keys(vaccinations).map((key) => ({ vaccine_id: key })),
          medical_screenings: Object.keys(screenings).map((key) => ({
            medical_screening_type_id: key,
          })),
        },
        licenses: {
          us_drivers_license: true,
          us_nursing_license: {
            us_nursing_license_type_id: license,
          },
        },
        nursing_departments: getNursingDepartments(department, departments, experience),
      },
      shifts: shiftTimesWithPayrate,
    };

    mutate(jobData);
  };

  const [mutate, { status }] = useCreateShift({
    onSuccess() {
      onClose();
      enqueueSnackbar(
        <div>
          Success, Your Shift has been Posted! You can check and edit your upcoming shift under the{' '}
          <a href={SHIFTS}>
            <span className="line-breake-inline" />
            My Jobs
          </a>{' '}
          section
        </div>,
        {
          variant: 'success',
        },
      );
    },
    onFailure({ error }) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const handleCloseForm = () => {
    // confirm(
    //   () => {
    //     setSelectedDepartment(null);
    //     setStep(1);
    //     resetForm();
    //   },
    //   {
    //     title: 'Are you sure you want to cancel the job?',
    //     secondaryText: 'You will lose your progress.',
    //     maxWidth: 'xs',
    //   },
    // );
    onClose();
  };

  const handleNext = () => {
    setStep((current) => current + 1);
  };

  const handleBack = () => {
    setStep((current) => current - 1);
  };

  const renderActions = useCallback(
    (isValid: boolean, handleSubmit: () => void) => {
      return (
        <div className={styles.modalActions}>
          <div>{step !== 1 && <Button onClick={handleBack}>Back</Button>}</div>

          <div className={styles.modalActionsRight}>
            <Button color="secondary" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={step < 6 ? handleNext : () => handleSubmit()}
              disabled={!isValid}
              sx={{ ml: '10px' }}
            >
              {status === 'running' ? (
                <CircularProgress color="secondary" size={24} />
              ) : (
                `${step === 6 ? 'Post' : 'Next'}`
              )}
            </Button>
          </div>
        </div>
      );
    },
    [onClose, status, step],
  );

  return (
    <Formik
      initialValues={{
        ...createShiftFormInitialValues,
        department: department.nursing_department_id,
      }}
      validationSchema={currentValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, touched, isValid, values, setFieldValue, handleSubmit }) => {
        return (
          <Form id="createShiftForm">
            <Modal
              title={step !== 6 ? 'Create a Shift' : 'Review Shift'}
              open={true}
              onRequestClose={handleCloseForm}
              actions={renderActions(Object.keys(touched).length > 0 && isValid, handleSubmit)}
            >
              <ProgressLine steps={6} progress={step} />

              {step === 1 && <BasicForm />}
              {step === 2 && (
                <CribSheetForm setFieldValue={setFieldValue} facility={values.facility} />
              )}
              {step === 3 && (
                <DateManageForm
                  startDate={values.start_date}
                  endDate={values.end_date}
                  startTime={values.start_time}
                />
              )}
              {step === 4 && <AdditionalForm />}
              {step === 5 && <DepartmentsForm departmentId={values.department} />}
              {step === 6 && (
                <PreviewForm
                  facilityId={values.facility}
                  startDate={values.start_date}
                  endDate={values.end_date}
                  startTime={values.start_time}
                  endTime={values.end_time}
                  days={values.days}
                  payrate={values.payrate}
                />
              )}
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateShiftModal;
