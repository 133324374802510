import React, { ReactNode } from 'react';
import styles from './Text.module.scss';

interface TitleProps {
  title?: string;
  children: ReactNode;
}

export const Title: React.FunctionComponent<TitleProps> = (props) => {
  return <div className={styles.title}>{props.children}</div>;
};

interface SubtitleProps {
  subtitle?: string;
  children: ReactNode;
}

export const Subtitle: React.FunctionComponent<SubtitleProps> = (props) => {
  return <div className={styles.subtitle}>{props.children}</div>;
};

interface TextProps {
  text?: string;
  children: ReactNode;
}

export const Text: React.FunctionComponent<TextProps> = (props) => {
  return <div className={styles.text}>{props.children}</div>;
};
