import React from 'react';
import { TableCell, Avatar } from '@mui/material';
import styles from '../TableRow/TableRow.module.scss';
import { JobShift } from '../../../../../types/schema';

export interface MedicalProfessionalCellProps {
  id: string;
  shift: JobShift;
  onClick: () => void;
}

export const MedicalProfessionalCell: React.FunctionComponent<MedicalProfessionalCellProps> = (
  props,
) => {
  const { id, shift, onClick } = props;
  const {
    jobs_shift_info: { shift_assignment, supply_profile },
  } = shift;
  const fullName = `${supply_profile?.first_name ?? ''} ${supply_profile?.last_name ?? ''}`;

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClick();
  };
  return (
    <TableCell width={'20%'} key={id} align="left">
      {shift_assignment?.supply_id ? (
        <div onClick={handleClick} className={styles.matchedWrap}>
          <Avatar
            className={styles.matchedAvatar}
            alt={fullName}
            src={supply_profile?.profile_image_url}
          ></Avatar>
          {fullName}
        </div>
      ) : (
        <div style={{ marginLeft: '1rem' }}>--</div>
      )}
    </TableCell>
  );
};
