import React from 'react';
import ProfileLayout from '../../../layout/ProfileLayout';
import ProfileCompanyForm from './components/ProfileCompanyForm';

const ProfileCompanyPage = () => {
  return (
    <ProfileLayout activeMenu="company">
      <ProfileCompanyForm />
    </ProfileLayout>
  );
};

export default ProfileCompanyPage;
