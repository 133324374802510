import React from 'react';
import AuthenticationLayout from '../../../layout/AuthenticationLayout';
import SignUpForm from './components/SignUpForm';

const SignUp = () => {
  return (
    <AuthenticationLayout
      title="Welcome to Para"
      subtitle="Create a new account."
      form={<SignUpForm />}
    />
  );
};

export default SignUp;
