import React from 'react';
import { NursingDepartment } from '../../../../types/schema';
import styles from './DepartmentIcon.module.scss';

interface DepartmentIconProps {
  department: NursingDepartment;
}

const DepartmentIcon = (props: DepartmentIconProps) => {
  const { department } = props;

  const icon = department.nursing_department
    .toLowerCase()
    .split(/[ ._/]/)
    .join('-');

  return (
    <img
      className={styles.icon}
      src={`/images/icons/secondary/${icon}.svg`}
      alt={department.nursing_department}
    />
  );
};

export default DepartmentIcon;
