import React from 'react';
import { Box } from '@mui/material';
import { useField } from 'formik';
import { useDepartments } from '../../../../services/useShifts';
import { NursingDepartment } from '../../../../types/schema';
import DepartmentItem from '../DepartmentItem';

export interface DepartmentFormProps {
  departmentId?: string;
}

export const DepartmentForm = ({ departmentId }: DepartmentFormProps) => {
  const { data: { data: departments } = { data: [] } } = useDepartments();

  const toggleMultiSelection = (state: string[] = [], value: string) => {
    return state.includes(value) ? state.filter((val) => val !== value) : [...state, ...[value]];
  };

  // eslint-disable-next-line
  const [field, meta, helpers] = useField('departments');

  const { value } = meta;
  const { setValue } = helpers;

  return (
    <Box>
      <h1 className="text-normal">
        4. What other department experiences are you willing to accept?
      </h1>
      <div>
        {departments &&
          departments.map((department: NursingDepartment, index) => {
            return (
              <DepartmentItem
                department={department}
                size="sm"
                key={index}
                {...department}
                selectable
                selected={
                  departmentId === department.nursing_department_id ||
                  value.includes(department.nursing_department_id)
                }
                onClick={() => {
                  setValue(toggleMultiSelection(value ?? [], department.nursing_department_id));
                }}
              />
            );
          })}
      </div>
    </Box>
  );
};

export default DepartmentForm;
