import { DemandDocument, Document } from '../types/schema';

export interface FormattedDemandDocument {
  label: string;
  documentId: string;
  url: string;
}

export const formatDemandAgreements = (agreements: DemandDocument[]): FormattedDemandDocument[] => {
  if (agreements.length === 0) return [];

  const formatedAgreements = agreements
    .filter((doc: DemandDocument) => doc && doc.document)
    .map((doc: DemandDocument) => doc.document)
    .filter((doc: Document) => doc.document_type.document_type !== 'Supply Membership Agreement')
    .map((doc: Document) => ({
      label: doc.document_type.document_type,
      documentId: doc.document_id,
      url: doc.document_type.document_type.split(' ')[0].toLowerCase(),
    }));

  formatedAgreements.push({
    label: 'All',
    documentId: 'all',
    url: '',
  });

  return formatedAgreements;
};

export const getDemandAgreementIds = (agreements: FormattedDemandDocument[]) => {
  return agreements.filter((doc) => doc.documentId !== 'all').map((doc) => doc.documentId);
};
