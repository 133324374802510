import * as React from 'react';
import { Container, Typography } from '@mui/material';
import Tabs from '../../components/Tabs';
import ApplicationLayout from '../../layout/ApplicationLayout';
import styles from './ShiftsPage.module.scss';
import { ShiftsTable } from './schedule/ShiftsTable';
import { ViewTypeFilter } from './components/ViewTypeFilter';
import { ShiftsFilterProvider } from './context/shiftContext';

const tabs = [
  { label: 'Schedule', value: 'schedule' },
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const ShiftsPage = () => {
  const [tab, setCurrentTab] = React.useState('schedule');

  const handleTabChange = (newTab: string) => {
    setCurrentTab(newTab);
  };

  return (
    <ApplicationLayout>
      <ShiftsFilterProvider>
        <Container maxWidth="lg" sx={{ p: 4 }}>
          <div className={styles.header}>
            <Typography
              variant="h4"
              color="primary"
              sx={{ fontWeight: '500', fontSize: '25px', lineHeight: '32px' }}
            >
              My Shifts
            </Typography>
            <Tabs tabs={tabs} onTabChange={handleTabChange} current={tab} />
            <ViewTypeFilter />
          </div>
          <TabPanel value={tab} index="schedule">
            <ShiftsTable />
          </TabPanel>
          <TabPanel value={tab} index="day">
            Item Two
          </TabPanel>
          <TabPanel value={tab} index="week">
            Item Three
          </TabPanel>
          <TabPanel value={tab} index="month">
            Four Three
          </TabPanel>
        </Container>
      </ShiftsFilterProvider>
    </ApplicationLayout>
  );
};

export default ShiftsPage;
