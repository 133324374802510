import React, { useState, useEffect } from 'react';
import { OptionItemProps, Loader, Alert } from '../../../../components';
import { SelectableList } from '../../../../components/SelectableList';
import { ListFilter } from '../../components/ListFilter';
import { useFacilities } from '../../../../services/useOrganization';
import { useShiftsFilter } from '../../context/shiftContext';

export interface LocationFilterProps {
  value?: string;
  onItemClick?: (item: Pick<OptionItemProps, 'label' | 'value'>) => void;
}

const LocationFilter = ({ value, onItemClick }: LocationFilterProps) => {
  const organizationId = localStorage.getItem('_o') || '';
  const { data, isLoading, error } = useFacilities(organizationId);

  if (isLoading) return <Loader />;
  if (error)
    return (
      <Alert type="error">
        <p>{error}</p>
      </Alert>
    );

  const options = data?.data?.map((facility) => ({
    label: facility.facility_name,
    value: facility.facility_id,
  }));
  options?.sort((pre, next) => pre?.label?.localeCompare(next?.label ?? '') ?? 0);

  return <SelectableList onItemClick={onItemClick} options={options} multiple value={value} />;
};

export const ConnectedLocationFilter = LocationFilter;

export interface LocationFilterPopupProps {
  open: boolean;
  anchorEl?: null | Element | ((element: Element) => Element);
  onClose: () => void;
}

export const LocationFilterPopup = ({ open, anchorEl, onClose }: LocationFilterPopupProps) => {
  const { filters, dispatch } = useShiftsFilter();
  const [value, setValue] = useState(filters.facilityId);

  const handleApplyClick = () => {
    dispatch({ type: 'facility', payload: value });
    onClose();
  };

  const handleReset = () => {
    dispatch({ type: 'facility', payload: '' });
  };

  const handleItemClick = (item: Pick<OptionItemProps, 'label' | 'value'>) => {
    setValue(item.value);
  };

  useEffect(() => {
    setValue(filters.facilityId);
  }, [filters.facilityId]);

  return (
    <ListFilter
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      onApplyFilter={handleApplyClick}
      onResetClick={handleReset}
    >
      <ConnectedLocationFilter onItemClick={handleItemClick} value={value} />
    </ListFilter>
  );
};
