import React from 'react';
import ProfileLayout from '../../../layout/ProfileLayout';
import ProfileCompanyCribSheetForm from './components/ProfileCompanyCribSheetForm';

const ProfileCompanyCribSheetPage = () => {
  return (
    <ProfileLayout activeMenu="crib-sheet">
      <ProfileCompanyCribSheetForm />
    </ProfileLayout>
  );
};

export default ProfileCompanyCribSheetPage;
