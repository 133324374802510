import * as Yup from 'yup';
import dayjs from 'dayjs';

const validationSchema = [
  Yup.object().shape({
    department: Yup.string().required('Primary department is required'),
    facility: Yup.string().required('Facility is required'),
    license: Yup.string().required('License is required'),
    jobTitle: Yup.string().required('Job title is required'),
  }),
  Yup.object().shape({
    primaryContactPerson: Yup.string().required('Primary contact person is required'),
  }),
  Yup.object().shape({
    start_date: Yup.date()
      .required('Start date is required')
      .test({
        name: 'min-datetime',
        exclusive: false,
        params: {},
        message: 'Start date cannot be in the past',
        test: function (value) {
          return dayjs(value).isSameOrAfter(dayjs(), 'day');
        },
      }),
    end_date: Yup.date()
      .required('End date is required')
      .test({
        name: 'enddate-after-startdate',
        exclusive: false,
        params: {},
        message: 'End date cannot be in earlier than start date',
        test: function (value) {
          const startDate = this.parent.start_date;
          return dayjs(value).isSameOrAfter(startDate, 'day');
        },
      }),
    start_time: Yup.string()
      .required('Start time is required')
      .test({
        name: 'min-time',
        exclusive: false,
        params: {},
        message: 'Start time cannot be in after the end time',
        test: function (value) {
          if (
            this.parent.end_time &&
            value &&
            dayjs(this.parent.start_date).isSame(dayjs(this.parent.end_date))
          ) {
            console.log('is valid');
            const startTime = dayjs(value, 'HH:mm');
            const endTime = dayjs(this.parent.end_time, 'HH:mm');
            return startTime.isBefore(endTime);
          } else return true;
        },
      }),
    end_time: Yup.string().required('End time is required'),
    days: Yup.array().min(1, 'Select at least one day'),
    payrate: Yup.number()
      .required('Please set the pay rate')
      .max(100)
      .positive('Must be positive number'),
  }),
];

export default validationSchema;
