import React, { ReactNode } from 'react';
import styles from './Form.module.scss';

interface FormProps {
  children: ReactNode;
  onSubmit?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

interface FormActionsProps {
  children: ReactNode;
}

const Form = (props: FormProps) => {
  const { children, ...rest } = props;
  return (
    <form {...rest} noValidate>
      {children}
    </form>
  );
};

const FormActions = (props: FormActionsProps) => {
  const { children } = props;
  return <div className={styles.actions}>{children}</div>;
};

export { Form, FormActions };
