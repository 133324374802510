export type ShiftStatusType = 'REVIEWED' | 'NEEDS_REVIEW' | 'PENDING_MATCHING' | '';

export type DetailModalStatusType =
  | 'REVIEWED'
  | 'NEEDS_REVIEW'
  | 'PENDING_MATCHING'
  | 'COMPLETED'
  | '';

export const ShiftStatusList = [
  { label: 'Reviewed', value: 'REVIEWED' },
  { label: 'Needs Review', value: 'NEEDS_REVIEW' },
  { label: 'Pending Matching', value: 'PENDING_MATCHING' },
];

export const isValidShiftStatus = (status: string): boolean => {
  return !!ShiftStatusList.find((item) => item.value === status);
};

export const ViewTypeList = [
  { label: 'Upcoming Shifts', value: 'UPCOMING_SHIFTS' },
  { label: 'Completed Shifts', value: 'COMPLETED_SHIFTS' },
  { label: 'Canceled Shifts', value: 'CANCELED_SHIFTS' },
];

export const isValidViewType = (status: string): boolean => {
  return !!ViewTypeList.find((item) => item.value === status);
};

export const FilterPeriods = [
  { label: 'Today', value: 'TODAY' },
  { label: 'This week', value: 'THIS_WEEK' },
  { label: 'This month', value: 'THIS_MONTH' },
  { label: 'Next month', value: 'NEXT_MONTH' },
];

export const isValidPeriod = (period: string): boolean => {
  return !!FilterPeriods.find((item) => item.value === period);
};
