import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { colors } from '../../constants';

const PREFIX = 'AlertComponent';

const classes = {
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
  info: `${PREFIX}-info`,
  warning: `${PREFIX}-warning`,
};

const StyledAlert = styled(Alert)(({ theme: DeprecatedThemeOptions }) => ({
  [`& .${classes.success}`]: {
    backgroundColor: colors.successLight,
  },

  [`& .${classes.error}`]: {
    backgroundColor: colors.alertLight,
  },

  [`& .${classes.info}`]: {
    backgroundColor: colors.primaryLight,
  },

  [`& .${classes.warning}`]: {
    backgroundColor: colors.warningLight,
  },
}));

interface AlertProps {
  type: 'error' | 'success' | 'warning' | 'info';
  children: ReactNode | string;
}

const AlertComponent = ({ type, children }: AlertProps) => {
  return (
    <StyledAlert className={classes[type]} icon={false} severity={type}>
      {children}
    </StyledAlert>
  );
};

export default AlertComponent;
