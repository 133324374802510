import React, { useMemo } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { Button } from '@mui/material';
import { Loader } from '../../../components';
import Header from '../../../layout/Header';
import Footer from '../../../layout/Footer';
import { useDemandAgreements } from '../../../services/useAgreements';
import { AGREEMENTS } from '../../../constants/routes';
import styles from './AgreementDetail.module.scss';

export const AgreementDetail = () => {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const { data: { data: agreements } = { data: [] }, isLoading } = useDemandAgreements();

  const htmlContent = useMemo(() => {
    const agreement = (agreements ?? []).find(
      (agreement) => agreement.document?.document_id === id,
    );
    const content = agreement?.document?.content
      ? agreement?.document?.content[0].content || ''
      : '';
    return atob(content);
  }, [id, agreements]);

  if (!id) {
    return <Redirect to={AGREEMENTS} />;
  }

  if (isLoading) return <Loader />;
  // if documentId not found, back to agreement list page
  if (!isLoading && agreements && agreements.length > 0 && !htmlContent)
    return <Redirect to={AGREEMENTS} />;

  return (
    <div>
      <div className={styles.container}>
        <Header className={styles.header} />
        <div className={styles.centerBlock}>
          <h2 className={styles.welcomeText}>Welcome!</h2>
          <h2 className={styles.tacText}>Agree to the Terms and Conditions.</h2>
          <div
            className={styles.htmlContent}
            dangerouslySetInnerHTML={{
              __html: htmlContent,
            }}
          ></div>
        </div>
      </div>
      <Footer>
        <Button onClick={() => history.push(AGREEMENTS)}>Back</Button>
      </Footer>
    </div>
  );
};
