import React from 'react';
import { TableCell, IconButton } from '@mui/material';
import styles from './TableHeaderCell.module.scss';
import { Column } from '../ShiftsTable';
import { Icon } from '../../../../../components';
import { useShiftsFilter, isFiltering } from '../../../../../pages/Shifts/context/shiftContext';

export const TableHeaderCell = (props: Column) => {
  const { id, label, hasFilter = true, FilterComponent } = props;
  const { filters } = useShiftsFilter();
  const filtering = isFiltering(id, filters);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <TableCell width={'20%'} key={id} align="left" className={styles.container}>
      {label}
      {hasFilter && (
        <IconButton className={styles.filterButton} onClick={handleClick} size="large">
          <Icon size={10} name={filtering ? 'filterActive' : 'filter'} />
        </IconButton>
      )}
      {FilterComponent && <FilterComponent open={open} anchorEl={anchorEl} onClose={handleClose} />}
    </TableCell>
  );
};
