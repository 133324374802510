import React from 'react';
import Chip from '@mui/material/Chip';
import { Input } from '../../../../../components';

interface NPINumbersInputProps {
  value?: string[];
  onChange: (value: string) => void;
  error?: boolean;
  helperText?: string;
}

const NPINumbersInput = (props: NPINumbersInputProps) => {
  const { value, error, helperText, onChange } = props;

  const [npiNumbers] = React.useState<string[]>(value ?? []);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Input
      variant="standard"
      label="NPI"
      fullWidth
      placeholder="Enter NPI"
      error={error}
      helperText={helperText}
      InputProps={{
        type: 'number',
        startAdornment: npiNumbers.map((item) => (
          <Chip
            key={item}
            tabIndex={-1}
            label={item}
            // className={classes.chip}
            // onDelete={onDelete(item)}
          />
        )),
        onChange: (event) => {
          handleChange(event);
        },
      }}
    />
  );
};

export default NPINumbersInput;
