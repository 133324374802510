import * as React from 'react';
import dayjs from 'dayjs';
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  Avatar,
  Grid,
  IconButton,
  useMediaQuery,
  FormHelperText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Icon, Input, Loader } from '../../../../components';
import { useConfirmTimesheet, useTimesheet } from '../../../../services/useShifts';
import { JobShift, TimesheetConfirmationPayload } from '../../../../types/schema';
import styles from './ReviewTimesheetModal.module.scss';
import { SHIFT_DATE_FORMAT, SHIFT_TIME_FORMAT } from '../../../../constants/datetime';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { getErrorMessage } from '../../../../utils';

export interface ReviewTimesheetModalProps {
  shift?: JobShift;
  open: boolean;
  onClose: () => void;
}

export const ReviewTimesheetModal = ({ shift, open, onClose }: ReviewTimesheetModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { data, isLoading, error } = useTimesheet(
    shift?.job_id,
    shift?.jobs_shift_info.jobs_shift.shift_id,
    shift?.jobs_shift_info.shift_assignment?.supply_id,
  );

  const { data: timesheets } = data ?? { data: [] };
  const { enqueueSnackbar } = useSnackbar();

  const [mutate] = useConfirmTimesheet({
    onSuccess() {
      console.log('on success');
      onClose();
      enqueueSnackbar('Success, Your timesheet has been confirmed!', {
        variant: 'success',
      });
    },
    onFailure({ error }) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const formik = useFormik({
    initialValues: {},
    validate: (values) => {
      return {};
    },
    onSubmit: async (values, { setSubmitting }) => {
      console.log('Values are', values);
      setSubmitting(true);
      try {
        const organizationId = localStorage.getItem('_o') || '';
        const accountId = localStorage.getItem('_i') || '';
        timesheets?.forEach((timesheet) => {
          const updatedTimeIn = (values as Record<string, string>)[timesheet.time_card_entry_id + '_time_in'];
          const updatedTimeOut = (values as Record<string, string>)[timesheet.time_card_entry_id + '_time_out'];
          const timesheetData: TimesheetConfirmationPayload = {
            demand_id: organizationId,
            demand_user_id: accountId,
            time_card_entry_id: timesheet.time_card_entry_id,
            time_in: updatedTimeIn
              ? dayjs(timesheet.time_in)
                  .set('hours', dayjs(updatedTimeIn, 'HH:mm').hour())
                  .set('minutes', dayjs(updatedTimeIn, 'HH:mm').minute())
                  .toISOString()
                  .split('.')[0] + 'Z'
              : timesheet.time_in,
            time_out: updatedTimeOut
              ? dayjs(timesheet.time_out)
                  .set('hours', dayjs(updatedTimeOut, 'HH:mm').hour())
                  .set('minutes', dayjs(updatedTimeOut, 'HH:mm').minute())
                  .toISOString()
                  .split('.')[0] + 'Z'
              : timesheet.time_out,
          };
          mutate(timesheetData);
        });
        setSubmitting(false);
      } catch (error) {
        console.log(error);
        setSubmitting(false);
        enqueueSnackbar(getErrorMessage(error), {
          variant: 'error',
        });
      }
    },
  });

  const { handleSubmit, values, handleChange } = formik;
  console.log('Values!', values);
  return (
    <Dialog
      classes={{
        paper: styles.container,
      }}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div className={styles.header}>
        <Typography variant="h4" color="primary">
          Review Timesheet
        </Typography>
        <IconButton onClick={onClose} className={styles.icon} size="large">
          <Icon name="close" size={10} />
        </IconButton>
      </div>
      {isLoading && (
        <div className={styles.centerBlock}>
          <Loader />
        </div>
      )}
      {!isLoading && error && (
        <div className={styles.centerBlock}>
          <FormHelperText error>
            {error.message ??
              'Oops! An error occurred while processing your request. Please try again.'}
          </FormHelperText>
        </div>
      )}
      {!isLoading && !error && (
        <DialogContent className={styles.content}>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            SHIFT INFORMATION
          </Typography>
          <Grid container spacing={2}>
            {shift?.jobs_shift_info.supply_profile && (
              <Grid item xs={12} sm={6} className={styles.section}>
                <div className={styles.sectionSubtitle}>REGISTERED NURSE</div>
                <div className={styles.registeredNurse}>
                  <Avatar alt={''} src={shift.jobs_shift_info.supply_profile.profile_image_url} />
                  <div className={styles.registeredNurseDetail}>
                    <div className={styles.nurseName}>
                      {shift.jobs_shift_info.supply_profile.first_name}{' '}
                      {shift.jobs_shift_info.supply_profile.last_name}
                    </div>
                    {/* <a href="/">Review profile</a> */}
                  </div>
                </div>
              </Grid>
            )}
            <Grid item xs={12} sm={6} className={styles.section}>
              <div className={styles.sectionSubtitle}>TIME AND DURATION</div>
              <div className={styles.shiftDate}>
                {dayjs(shift?.jobs_shift_info.jobs_shift.start_time).format(SHIFT_DATE_FORMAT)}
              </div>
              <div className={styles.shiftTime}>
                <Icon name="clock" size={16} />
                <span>
                  {dayjs(shift?.jobs_shift_info.jobs_shift.start_time).format(SHIFT_TIME_FORMAT)} -{' '}
                  {dayjs(shift?.jobs_shift_info.jobs_shift.end_time).format(SHIFT_TIME_FORMAT)}
                </span>
              </div>
            </Grid>
          </Grid>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            TIMESHEET
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} className={styles.section}>
                <div className={styles.sectionSubtitle}>SHIFT</div>
                {timesheets?.map((timesheet) => {
                  return (
                    <div className={styles.shiftDate}>
                      {dayjs(timesheet.time_in).format(SHIFT_DATE_FORMAT)}
                    </div>
                  );
                })}
              </Grid>
              <Grid container item xs={12} sm={6} spacing={2}>
                <Grid item xs={6} className={styles.section}>
                  <div className={styles.sectionSubtitle}>Clocked on</div>
                  {timesheets?.map((timesheet) => {
                    return (
                      <div className={styles.shiftDate} style={{color: timesheet.confirmed ? 'green' : 'inherit'}}>
                        {timesheet.confirmed ? (
                          dayjs(timesheet.time_in).format(SHIFT_TIME_FORMAT)
                        ) : (
                          <Input
                            type="time"
                            name={timesheet.time_card_entry_id + '_time_in'}
                            value={
                              (values as Record<string, string>)[timesheet.time_card_entry_id + '_time_in'] ||
                              dayjs(timesheet.time_in).format(SHIFT_TIME_FORMAT)
                            }
                            onChange={handleChange}
                            // className="mb-20"
                            // placeholder="Select Start Time"
                            // validate={validateRequired}
                          />
                        )}
                      </div>
                    );
                  })}
                </Grid>
                <Grid item xs={6} className={styles.section}>
                  <div className={styles.sectionSubtitle}>Clocked off</div>
                  {timesheets?.map((timesheet) => {
                    return (
                      <div className={styles.shiftDate} style={{color: timesheet.confirmed ? 'green' : 'inherit'}}>
                        {timesheet.confirmed ? (
                          dayjs(timesheet.time_out).format(SHIFT_TIME_FORMAT)
                        ) : (
                          <Input
                            type="time"
                            name={timesheet.time_card_entry_id + '_time_out'}
                            value={
                              (values as Record<string, string>)[timesheet.time_card_entry_id + '_time_out'] ||
                              dayjs(timesheet.time_out).format(SHIFT_TIME_FORMAT)
                            }
                            onChange={handleChange}
                            // className="mb-20"
                            // validate={validateRequired}
                          />
                        )}
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            <div className={styles.actions}>
              <Button
                type="submit"
                disabled={(timesheets ?? []).filter((t) => t.confirmed).length > 0}
                variant="contained"
              >
                Save & Confirm
              </Button>
            </div>
          </form>
        </DialogContent>
      )}
    </Dialog>
  );
};
