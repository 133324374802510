import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { Input, Form, FormActions } from '../../../../../components';
import {
  FORGOT_PASSWORD,
  // LOGIN
} from '../../../../../constants/routes';

const ChangePasswordForm = () => {
  const history = useHistory();
  return (
    <Form>
      <Input
        // required
        type="email"
        name="email"
        label="Email"
        disabled
      />
      <Input
        // required
        type="password"
        name="password"
        label="New Password"
      />
      <Input
        // required
        type="password"
        name="confirmPassword"
        label="Confirm password"
      />

      <FormActions>
        <Button
          variant="contained"
          type="submit"
          // disabled={isSending}
        >
          Change Password
        </Button>
        <Button variant="outlined" onClick={() => history.push(FORGOT_PASSWORD)}>
          Back
        </Button>
      </FormActions>
    </Form>
  );
};

export default ChangePasswordForm;
