import useSWR, { keyInterface, ConfigInterface } from 'swr';
import { fetcherFn } from 'swr/dist/types';
import { useState, useEffect } from 'react';

export type QuickSwrData<D, E> = {
  data: D | undefined;
  isFirstLoading: boolean;
  isLoading: boolean;
  error: E | undefined;
};

export function useQuickSwr<Data = unknown, Error = unknown>(
  key: keyInterface,
  fn?: fetcherFn<Data>,
  options?: ConfigInterface<Data, Error>,
): QuickSwrData<Data, Error> {
  const { data, error, ...rest } = useSWR<Data, Error>(key, fn, options);
  const [internalData, setInternalData] = useState<Data>();
  const isFirstLoading = !internalData;
  const isLoading = !data && !error;

  useEffect(() => {
    if (data) {
      setInternalData(data);
    }
  }, [data]);

  return { data: internalData, isFirstLoading, isLoading, error, ...rest };
}
