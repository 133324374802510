import { useMemo } from 'react';
import useMutation, { Options as MutationOptions } from 'use-mutation';
import dayjs from 'dayjs';
import { useQuickSwr } from './useQuickSwr';
import { getApiParamsFromState } from '../utils/shiftsUtils';
import { GET, POST, buildEndpointURL } from '../services/services';
import {
  BaseResponse,
  NursingDepartment,
  JobShift,
  ShiftDetail,
  MatchedProfessional,
  License,
  OrganizationUser,
  Vaccine,
  Erm,
  Timesheet,
  DemandJobPayload,
  MedicalScreening,
  TimesheetConfirmationPayload,
} from '../types/schema';
import { ShiftsFilterOptions } from '../pages/Shifts/context/shiftContext';

export interface GetShiftsApiParams {
  startDate?: Date;
  endDate?: Date;
  facilityId?: string;
  departmentId?: string;
  status?: string;
}

export const useShifts = (filters?: ShiftsFilterOptions) => {
  const url = '/demand/us-nursing-jobs/shifts/list';
  const key = buildEndpointURL('praca', url);
  const apiParams = useMemo(() => getApiParamsFromState(filters), [filters]);
  const fetcher = (
    url: string,
    startDate: Date,
    endDate: Date,
    facilityId: string,
    departmentId: string,
  ) => {
    return new Promise<BaseResponse<JobShift[]>>((resolve, reject) => {
      return GET<BaseResponse<JobShift[]>>(url, {
        start_time: startDate ? dayjs(startDate).format('YYYY-MM-DDTHH:mm:ssZ') : '',
        end_time: endDate ? dayjs(endDate).format('YYYY-MM-DDTHH:mm:ssZ') : '',
        facility_id: facilityId,
        primary_us_nursing_department_id: departmentId,
      })
        .then((response) => {
          if (response?.data) {
            response.data = response.data.sort((a, b) => {
              if (
                a.jobs_shift_info.jobs_shift.start_time > b.jobs_shift_info.jobs_shift.start_time
              ) {
                return 1;
              }
              return -1;
            });
            resolve(response);
          }
        })
        .catch((e) => reject(e));
    });
  };

  return useQuickSwr<BaseResponse<JobShift[]>>(
    [key, apiParams.startDate, apiParams.endDate, apiParams.facilityId, apiParams.departmentId],
    fetcher,
  );
};

export const useDepartments = () => {
  const url = '/nursing/nursing-departments/all';
  const key = buildEndpointURL('praca', url);
  const result = useQuickSwr<BaseResponse<NursingDepartment[]>, Error>(key, GET);
  result.data?.data?.sort((a: NursingDepartment, b: NursingDepartment) => {
    const nameA = a.nursing_department.toUpperCase();
    const nameB = b.nursing_department.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
  return result;
};

export const useShiftDetail = (demandId?: string, jobId?: string, shiftId?: string) => {
  const url = '/demand/us-nursing-jobs/shifts/details';
  const key = buildEndpointURL('praca', url);
  return useQuickSwr<BaseResponse<ShiftDetail>, Error>(
    [key, demandId, jobId, shiftId],
    (url, demandId, jobId, shiftId) =>
      GET(url, { demand_id: demandId, job_id: jobId, shift_id: shiftId }),
  );
};

export const useLicenses = (regionId?: string) => {
  let url = '/credentials/licenses/us/nursing-license-types/all';
  if (regionId) {
    url += '?region_id=' + regionId;
  }
  const key = buildEndpointURL('tapatybe', url);
  return useQuickSwr<BaseResponse<License[]>>(key, GET);
};

export const useOrganizationUsers = (organizationId?: string) => {
  const url = '/demand/organization-personnel/accounts';
  const key = buildEndpointURL('podnik', url);
  return useQuickSwr<BaseResponse<OrganizationUser[]>>([key, organizationId], (url, id) =>
    GET(url, { organization_personnel_id: id }),
  );
};

export const useVaccines = () => {
  const url = '/health-records/vaccines/all';
  const key = buildEndpointURL('tapatybe', url);
  return useQuickSwr<BaseResponse<Vaccine[]>>(key, GET);
};

export const useScreenings = () => {
  const url = '/health-records/medical-screening-types/all';
  const key = buildEndpointURL('tapatybe', url);
  return useQuickSwr<BaseResponse<MedicalScreening[]>>(key, GET);
};

// Electronic health record
export const useEmrs = () => {
  const url = '/emrs/emrs/all';
  const key = buildEndpointURL('praca', url);
  return useQuickSwr<BaseResponse<Erm[]>>(key, GET);
};

export const useCreateShift = (
  options: MutationOptions<DemandJobPayload, BaseResponse<DemandJobPayload>, Error>,
) => {
  const url = '/jobs/us/nursing-jobs/draft';
  const key = buildEndpointURL('praca', url);
  const createShiftApi = async (shift: DemandJobPayload) => {
    const publishUrl = '/jobs/us/nursing-jobs/publish';
    const publishKey = buildEndpointURL('praca', publishUrl);
    return new Promise((resolve, reject) => {
      return POST(key, { data: { ...shift } })
        .then((result) => {
          if (result.data) {
            return POST(publishKey, {
              data: {
                us_nursing_job_id: result.data.us_nursing_job_id,
                job_id: result.data.job.job_id,
                demand_id: result.data.job.demand_id,
                published_by_id: shift.drafted_by_id,
              },
            })
              .then((publishResult) => resolve(publishResult))
              .catch((e) => reject(e));
          } else {
            reject('There was no data for drafted shifts');
          }
        })
        .catch((e) => reject(e));
    });
  };
  return useMutation(createShiftApi, options);
};

export const useConfirmTimesheet = (
  options: MutationOptions<TimesheetConfirmationPayload, BaseResponse<DemandJobPayload>, Error>,
) => {
  const url = '/timesheets/shifts/time-clock-entry/confirmation';
  const key = buildEndpointURL('praca', url);
  const confirmTimesheetAPI = async (timesheet: TimesheetConfirmationPayload) => {
    return new Promise((resolve, reject) => {
      return POST(key, { data: { ...timesheet } })
        .then((result) => {
          resolve(result);
        })
        .catch((e) => reject(e));
    });
  };
  return useMutation(confirmTimesheetAPI, options);
};

export const useCancelShift = (
  options: MutationOptions<
    { shift_id: string; job_id: string },
    BaseResponse<DemandJobPayload>,
    Error
  >,
) => {
  const url = '/jobs/us-nursing-jobs-shifts/cancellation';
  const key = buildEndpointURL('praca', url);
  const cancelShiftApi = async (payload: { shift_id: string; job_id: string }) => {
    return new Promise((resolve, reject) => {
      return POST(key, { data: { ...payload } })
        .then((result) => {
          resolve(result);
        })
        .catch((e) => reject(e));
    });
  };
  return useMutation(cancelShiftApi, options);
};

export const useMatchedProfessional = (
  jobId?: string,
  usNursingJobId?: string,
  shiftId?: string,
  supplyId?: string,
) => {
  const url = '/demand/us-nursing-jobs/shifts/matched-professional';
  const key = buildEndpointURL('praca', url);
  return useQuickSwr<BaseResponse<MatchedProfessional>, Error>(
    [key, jobId, usNursingJobId, shiftId, supplyId],
    (url, jobId, usNursingJobId, shiftId, supplyId) =>
      GET(url, {
        us_nursing_job_id: usNursingJobId,
        job_id: jobId,
        shift_id: shiftId,
        supply_id: supplyId,
      }),
  );
};

export const useConfirmMatchedProfessional = (
  options: MutationOptions<
    { shift_id: string; job_id: string; supply_id: string },
    BaseResponse<DemandJobPayload>,
    Error
  >,
) => {
  const url = '/demand/us-nursing-jobs/shifts/matched-professional/confirm';
  const key = buildEndpointURL('praca', url);
  const confirmProfessionalApi = async (payload: {
    shift_id: string;
    job_id: string;
    supply_id: string;
  }) => {
    return new Promise((resolve, reject) => {
      return POST(key, { data: { ...payload } })
        .then((result) => {
          resolve(result);
        })
        .catch((e) => reject(e));
    });
  };
  return useMutation(confirmProfessionalApi, options);
};

export const useTimesheet = (jobId?: string, shiftId?: string, supplyId?: string) => {
  const url = '/timesheets/time-card-entries';
  const key = buildEndpointURL('praca', url);
  // const result: BaseResponse<Timesheet> = {
  //   code: 'success',
  //   message: '',
  //   data: {
  //     time_card_entry_id: '32049234',
  //     job_id: '123',
  //     shift_id: '123',
  //     supply_id: '123',
  //     time_in: '2021-01-23T11:12:58Z',
  //     time_out: '2021-01-23T23:12:58Z',
  //   },
  // };
  return useQuickSwr<BaseResponse<Timesheet[]>, Error>(
    [key, jobId, shiftId, supplyId],
    (url, jobId, shiftId, supplyId) =>
      GET(url, {
        job_id: jobId,
        shift_id: shiftId,
        supply_id: supplyId,
      }),
  );
};
