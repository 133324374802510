export const BACKEND_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZZ';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const SHIFT_DATE_FORMAT = 'MMM DD, dddd';
export const SHIFT_TIME_FORMAT = 'HH:mm';

export const DAYS_OF_THE_WEEK = [
  { label: 'Sun', index: 0 },
  { label: 'Mon', index: 1 },
  { label: 'Tue', index: 2 },
  { label: 'Wed', index: 3 },
  { label: 'Thu', index: 4 },
  { label: 'Fri', index: 5 },
  { label: 'Sat', index: 6 },
];
