import React, { ReactNode, useState } from 'react';
import { styled } from '@mui/material/styles';
import cn from 'classnames';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import { remCalc } from '../../utils';
import styles from './Modal.module.scss';
import { Icon } from '../Icon';
import { IconButton } from '@mui/material';

const PREFIX = 'Modal';

// TODO: Remove this classes since maxWidth set to 'sm'
const classes = {
  xs: `${PREFIX}-xs`,
  sm: `${PREFIX}-sm`,
  md: `${PREFIX}-md`,
  lg: `${PREFIX}-lg`,
  full: `${PREFIX}-full`,
  root: `${PREFIX}-root`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.xs}`]: { maxWidth: remCalc(350) },
  [`& .${classes.sm}`]: { maxWidth: remCalc(400) },
  [`& .${classes.md}`]: { maxWidth: remCalc(600) },
  [`& .${classes.lg}`]: { maxWidth: remCalc(1000) },

  [`& .${classes.full}`]: {
    width: '100%',
    height: 'auto',
    minWidth: '200px',
    maxHeight: '97vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },

  [`& .${classes.root}`]: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
}));

interface ModalProps {
  open: boolean;
  actions?: ReactNode;
  title: ReactNode | string;
  onRequestClose: (successMark?: string) => void;
  children: ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg';
}

const Modal = (props: ModalProps) => {
  const { actions, title, children, open, onRequestClose, maxWidth = 'sm' } = props;

  const [exiting, setExiting] = useState(false);

  const CANCEL_BUTTON = (
    <Button variant="contained" color="secondary" onClick={() => onRequestClose()}>
      Cancel
    </Button>
  );

  const SAVE_BUTTON = (
    <Button variant="contained" onClick={() => onRequestClose('success')} color="primary">
      Save
    </Button>
  );

  const defaultActions = [CANCEL_BUTTON, SAVE_BUTTON];
  return (
    <StyledDialog
      classes={{ root: cn(classes.root), container: cn(classes.full, [maxWidth]) }}
      onClose={() => onRequestClose()}
      PaperProps={{
        className: cn(styles['dp-modal'], {
          [styles['exiting-modal']]: exiting,
        }),
      }}
      open={open}
      maxWidth="sm"
      aria-labelledby="dp-modal"
      scroll="paper"
      TransitionProps={{
        onEnter: () => {
          setExiting(false);
        },

        onExit: () => {
          setExiting(true);
        },
      }}
    >
      {title && (
        <DialogTitle
          sx={{
            fontWeight: '500',
            fontSize: '25px',
            lineHeight: '32px',
            color: '#1963B0',
            borderBottom: '1px solid #E8ECF1',
          }}
        >
          {title}
        </DialogTitle>
      )}
      <IconButton onClick={() => onRequestClose()} className={styles.icon} size="large">
        <Icon name="close" size={10} />
      </IconButton>
      <DialogContent sx={{ position: 'relative' }}>{children}</DialogContent>

      <footer className={cn(styles.footer, styles.footerRight)}>{actions || defaultActions}</footer>
    </StyledDialog>
  );
};

export default Modal;
