import React, { ReactNode } from 'react';
import cn from 'classnames';
import styles from './Checkbox.module.scss';
import { FieldHookConfig, useField } from 'formik';

type CheckboxProps = {
  label: ReactNode | string;
  size?: 'large' | 'normal' | 'small';
  disabled?: boolean;
  required?: boolean;
  name?: string;
};

const Checkbox = (props: CheckboxProps & FieldHookConfig<string>) => {
  const { label, disabled = false, size = 'normal' } = props;

  const [field, meta] = useField(props);
  // const isError = meta.touched && meta.error && true;

  return (
    <div className={cn(styles['dp-checkbox-wrapper'], 'flex flex-middle')}>
      <label className={cn(styles['dp-checkbox'], { [styles['dp-checkbox-disabled']]: disabled })}>
        <input
          type="checkbox"
          className={styles['dp-checkbox-input']}
          {...field}
          checked={!!meta.value}
        />
        <span className={cn(styles['dp-checkbox-label'], styles['dp-checkbox-' + size])}>
          {label}
        </span>
      </label>
    </div>
  );
};

export default Checkbox;
