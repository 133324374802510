import React from 'react';
import ProfileUserForm from './components/ProfileUserForm';
import ProfileLayout from '../../../layout/ProfileLayout';

const ProfileUserPage = () => {
  return (
    <ProfileLayout activeMenu="user">
      <ProfileUserForm />
    </ProfileLayout>
  );
};

export default ProfileUserPage;
