import React from 'react';
import { Button, Popover, Typography } from '@mui/material';
import { OptionItemProps, Icon } from '../../../../components';
import { SelectableList } from '../../../../components/SelectableList';
import { ViewTypeList } from '../../../../constants';
import { useShiftsFilter } from '../../context/shiftContext';
import styles from './ViewTypeFilter.module.scss';

export const ViewTypeFilter = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { filters, dispatch } = useShiftsFilter();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (item: Pick<OptionItemProps, 'label' | 'value'>) => {
    dispatch({ type: 'viewType', payload: item.value });
    handleClose();
  };

  const open = Boolean(anchorEl);

  const selectedViewType = ViewTypeList.find((item) => item.value === filters.viewType);

  return (
    <>
      <Button variant="outlined" className={styles.dropdown} onClick={handleClick}>
        <Typography variant="body2" color="textPrimary" className={styles.dropdownText}>
          {selectedViewType?.label}
        </Typography>
        <Icon name="arrowDown" size={14} />
      </Button>
      <Popover
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: styles.popupContainer,
        }}
      >
        <SelectableList
          onItemClick={handleItemClick}
          options={ViewTypeList}
          value={filters.viewType}
        />
      </Popover>
    </>
  );
};
