import React from 'react';
import { Box } from '@mui/material';
import { DayPicker } from '../../../../components';
import InputField from '../InputField';

interface DateManageFormProps {
  startTime: string;
  startDate: string;
  endDate: string;
}

const DateManageForm = (props: DateManageFormProps) => {
  const { startTime, startDate, endDate } = props;
  console.log('Start date', startDate);
  return (
    <Box>
      <h1 className="text-normal">3. When and how much?</h1>
      <InputField type="date" name="start_date" max={endDate} label="Start date" />
      <InputField type="date" name="end_date" min={startDate} label="End date" />
      <InputField type="time" name="start_time" label="Start time" />
      <InputField type="time" name="end_time" label="End time" disabled={!startTime} />
      <DayPicker name="days" label="Days" />
      <InputField required name="payrate" label="Payrate" placeholder="30" type="number" />
    </Box>
  );
};

export default DateManageForm;
