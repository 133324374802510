import React from 'react';

const ICONS: { [key: string]: string } = {
  profile: '/images/icons/icon-profile.svg',
  logout: '/images/icons/icon-logout.svg',
  calendar: '/images/icons/icon-calendar.svg',
  clock: '/images/icons/icon-clock.svg',
  arrowRight: '/images/icons/icon-arrow-right.svg',
  filter: '/images/icons/icon-filter.svg',
  filterActive: '/images/icons/icon-filter-active.svg',
  arrowDown: '/images/icons/icon-arrow-down.svg',
  close: '/images/icons/icon-close.svg',
  location: '/images/icons/icon-location.svg',
  timesheet: '/images/icons/icon-timesheet.svg',
  imageAttachment: '/images/icons/ic-image-attachment.svg',
  linkAttachment: '/images/icons/ic-link-attachment.svg',
  user: '/images/icons/icon-user.svg',
};

export interface IconProps {
  name: string;
  size?: number;
  className?: string;
}

export const Icon = ({ name, size = 24, className = '' }: IconProps) => {
  return <img alt={name} width={size} height={size} src={ICONS[name]} className={className} />;
};
