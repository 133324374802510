import React from 'react';
import { Tabs as MuiTabs } from '@mui/material';
import Tab from '../Tab';
import styles from './Tabs.module.scss';
import cn from 'classnames';

export interface TabModel {
  label?: string;
  value?: string;
}

export interface TabsProps {
  tabs: TabModel[];
  current?: string;
  onTabChange?: (value: string) => void;
}

const Tabs = ({ tabs, current, onTabChange }: TabsProps) => {
  const items: JSX.Element[] = [];
  tabs.forEach((tab: TabModel, index) => {
    items.push(
      <Tab
        key={tab.value}
        id={tab.value}
        label={tab.label}
        value={tab.value}
        aria-controls={tab.value}
        className={cn(
          index === 0 && styles.borderRight,
          index === tabs.length - 1 && styles.borderLeft,
          index > 0 && index < tabs.length - 1 && cn(styles.borderLeft, styles.borderRight),
        )}
      />,
    );
  });

  const handleChange = (_: React.SyntheticEvent, value: string) => {
    onTabChange && onTabChange(value);
  };

  return (
    <MuiTabs classes={styles} value={current} onChange={handleChange}>
      {items}
    </MuiTabs>
  );
};

export default Tabs;
