import React, { useEffect, useReducer, Dispatch } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createContext, getQueryParamsFromState, useShiftsFilterFromQuery } from '../../../utils';
import { ViewTypeList } from '../../../constants';

export interface ShiftsFilterOptions {
  startDate?: Date;
  endDate?: Date;
  period?: string;
  departmentId?: string;
  facilityId?: string;
  status?: string;
  viewType?: string;
}

export interface ShiftsFilterAction {
  type: string;
  payload: string | { startDate?: Date; endDate?: Date } | undefined;
}

export type ShiftsFilterState = {
  filters: ShiftsFilterOptions;
  dispatch: Dispatch<ShiftsFilterAction>;
};

const initialState: ShiftsFilterOptions = {
  viewType: ViewTypeList[0].value, // default is UPCOMING_SHIFT
};

export function reducer(
  state: ShiftsFilterOptions,
  action: ShiftsFilterAction,
): ShiftsFilterOptions {
  const { payload } = action;
  switch (action.type) {
    case 'shift':
      if (typeof payload === 'string') {
        return {
          ...state,
          period: payload,
        };
      }
      if (typeof payload === 'object') {
        return {
          ...state,
          startDate: payload.startDate,
          endDate: payload.endDate,
        };
      }
      return {
        ...state,
        period: '',
        startDate: undefined,
        endDate: undefined,
      };
    case 'department':
      if (typeof payload === 'string') {
        return {
          ...state,
          departmentId: payload,
        };
      }
      return state;
    case 'facility':
      if (typeof payload === 'string') {
        return {
          ...state,
          facilityId: payload,
        };
      }
      return state;
    case 'status':
      if (typeof payload === 'string') {
        return {
          ...state,
          status: payload,
        };
      }
      return state;
    case 'viewType':
      if (typeof payload === 'string') {
        return {
          ...state,
          viewType: payload,
        };
      }
      return state;
    default:
      return state;
  }
}

const [Provider, useShiftsFilter, Context] = createContext<ShiftsFilterState>({
  name: 'shiftsFilter',
});

const ShiftsFilterProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const searchKey = location.search;
  const queryParams = new URLSearchParams(window.location.search);

  const currentFilter = useShiftsFilterFromQuery(queryParams);
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...currentFilter });
  const history = useHistory();

  useEffect(() => {
    const newQueryString = getQueryParamsFromState(state);
    history.push(`${history.location.pathname}?${newQueryString}`);
  }, [history, state, searchKey]);

  return (
    <Provider value={React.useMemo(() => ({ filters: state, dispatch }), [state])}>
      {children}
    </Provider>
  );
};

export const isFiltering = (column: string, filters: ShiftsFilterOptions): boolean => {
  switch (column) {
    case 'shift':
      return !!filters?.startDate || !!filters?.period;
    case 'location':
      return !!filters.facilityId;
    case 'department':
      return !!filters.departmentId;
    case 'status':
      return !!filters.status;
    default:
      return false;
  }
};

export { ShiftsFilterProvider, useShiftsFilter, Context };
