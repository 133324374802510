export const experienceYears = [
  {
    label: 'Junior (0 to 3 years)',
    value: '0',
  },
  {
    label: 'Seasoned (3 to 5 years)',
    value: '3',
  },
  {
    label: 'Veteran (5+ years)',
    value: '5',
  },
];
