import React from 'react';
import AuthenticationLayout from '../../../layout/AuthenticationLayout';
import ForgotPasswordForm from './components/ForgotPasswordForm';

const ForgotPasswordPage = () => {
  return (
    <AuthenticationLayout
      title="Forgot your password?"
      subtitle="Enter your email to find your account."
      form={<ForgotPasswordForm />}
    />
  );
};

export default ForgotPasswordPage;
