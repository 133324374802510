import React from 'react';
import ProfileLayout from '../../../layout/ProfileLayout';
import ProfileCompanyRequirementsForm from './components/ProfileCompanyRequirementsForm';

const ProfileCompanyRequirementsPage = () => {
  return (
    <ProfileLayout activeMenu="requirements">
      <ProfileCompanyRequirementsForm />
    </ProfileLayout>
  );
};

export default ProfileCompanyRequirementsPage;
