import React from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material';
// import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { Form, FormActions } from '../../../../components';

export interface CribSheetFormFields {
  'certs.bls'?: string;
  'certs.acls'?: string;
  'certs.pals'?: string;
  'certs.npr'?: string;
  'vacc.mmr'?: string;
  'vacc.influenza'?: string;
  'vacc.measelsMumpsRubella'?: string;
  'vacc.tb'?: string;
  'vacc.hepb'?: string;
  'vaccinations.tdap'?: string;
}

const ProfileCompanyRequirementsForm = () => {
  // const handleRequirementsRequest = (values) => {
  //   const {
  //     createRequirements,
  //     locationId,
  //     allUserData: {
  //       required: { certs, vacc },
  //     },
  //     updateRequirements,
  //   } = this.props;

  //   const data = {
  //     location_id: locationId || ls.get('_loc_id'),
  //     requirements: {
  //       emr_systems: [values['emr-systems']],
  //       certifications: mapKeys(certs),
  //       vaccinations: mapKeys(vacc),
  //     },
  //   };

  //   return this.currentForm.verified ? updateRequirements(data) : createRequirements(data);
  // };

  // const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      certifications: {
        bls: false,
        acls: false,
        pals: false,
        npr: false,
      },
      vaccinations: {
        mmr: false,
        influenza: false,
        measelsMumpsRubella: false,
        tb: false,
        hepb: false,
        tdap: false,
      },
    },
    validate: (values) => {
      const errors = {} as object;

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      // console.log('Values', values);
      // setSubmitting(true);
      // try {
      //   setSubmitting(false);
      //   enqueueSnackbar('Information saved successfully', {
      //     variant: 'success',
      //   });
      // } catch (e: any) {
      //   setSubmitting(false);
      //   enqueueSnackbar(e.message ? e.message : e.code ? e.code : e.status, {
      //     variant: 'error',
      //   });
      // }
    },
  });

  const { handleSubmit, values, handleChange, isSubmitting } = formik;

  return (
    <Form onSubmit={handleSubmit}>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Standard Certifications Required</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.certifications.bls}
                onChange={handleChange}
                name="certifications.bls"
              />
            }
            label="Basic Life Support (BLS)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.certifications.acls}
                onChange={handleChange}
                name="certifications.acls"
              />
            }
            label="Advanced Cardiovascular Life Support (ACLS)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.certifications.pals}
                onChange={handleChange}
                name="certifications.pals"
              />
            }
            label="Pediatric Advanced Life Support (PALS)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.certifications.npr}
                onChange={handleChange}
                name="certifications.npr"
              />
            }
            label="Neonatal Resuscitation Program (NPR)"
          />
        </FormGroup>
      </FormControl>

      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Vaccinations Required</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.vaccinations.mmr}
                onChange={handleChange}
                name="vaccinations.mmr"
              />
            }
            label="MMR"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.vaccinations.influenza}
                onChange={handleChange}
                name="vaccinations.influenza"
              />
            }
            label="Influenza"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.vaccinations.measelsMumpsRubella}
                onChange={handleChange}
                name="vaccinations.measelsMumpsRubella"
              />
            }
            label="Measles/Mumps/Rubella"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.vaccinations.tb}
                onChange={handleChange}
                name="vaccinations.tb"
              />
            }
            label="TB Test"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.vaccinations.hepb}
                onChange={handleChange}
                name="vaccinations.hepb"
              />
            }
            label="Hep B"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.vaccinations.tdap}
                onChange={handleChange}
                name="vaccinations.tdap"
              />
            }
            label="TDAP"
          />
        </FormGroup>
      </FormControl>

      <FormActions>
        <Button type="submit" disabled={isSubmitting} variant="contained">
          Save
        </Button>
      </FormActions>
    </Form>
  );
};

export default ProfileCompanyRequirementsForm;
